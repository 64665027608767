import { useState } from "react";
import { Link } from "react-router-dom";
import {
  BookIcon,
  CirclesFourIcon,
  ClockIcon,
  CoinIcon,
  CurrencyCircleDollarIcon,
  RecieptIcon,
  ShoppingCartIcon,
  TagSimpleIcon,
  UserIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";

export default function Nav() {
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [activeIndex, setActiveIndex] = useState(null);
  const items = [
    {
      name: "New Customer",
      icon: <UserIcon />,
      // onClick: () => setShowCreateNewCustomerModal(true),
      to: "/sales-and-invoicing/customer-list?action=create",
    },
    {
      name: "Make Sales",
      icon: <ShoppingCartIcon />,
      to: "/sales-and-invoicing/create-invoice",
    },
    {
      name: "Sales History",
      icon: <ClockIcon />,
      to: "/reports/sales-analysis",
    },
    {
      name: "Other Incomes",
      icon: <CoinIcon />,
    },
    {
      name: "Post Payments",
      icon: <TagSimpleIcon />,
      to: "/post-payments",
    },
    {
      name: "Vendors Ledger",
      icon: <BookIcon />,
      to: "/vendor/vendors-ledger",
    },
    {
      name: "Purchase History",
      icon: <CurrencyCircleDollarIcon />,
      to: "/reports/purchase-history",
    },
    {
      name: "Cash Book",
      icon: <RecieptIcon />,
      to: "/reports/payment-report",
    },
    {
      name: "All Menus",
      icon: <CirclesFourIcon />,
    },
  ];

  return (
    <div className="dashboard-nav">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Link
              onClick={() => {
                setActiveIndex(index);
                item.onClick && item.onClick();
              }}
              className={`btn ${activeIndex === index && "active"}`}
              to={item?.to || ""}
            >
              <span className="icon">{item.icon}</span>
              <span> {item.name}</span>
            </Link>
          </li>
        ))}
      </ul>

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
        />
      )}
    </div>
  );
}
