import { Dropdown, Image, Modal, Spinner } from "react-bootstrap";

export default function ModalLoader({
  show,
  title = "Please wait, processing request...",
  ...modalProps
}) {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={() => {}}
      centered
      aria-labelledby="example-modal-sizes-title-sm"
      backdrop="static"
      {...modalProps}
    >
      {/* <Modal.Header closeButton>
    <Modal.Title id="example-modal-sizes-title-sm">...</Modal.Title>
  </Modal.Header> */}
      <Modal.Body className="text-center my-3 d-flex align-items-center justify-content-center gap-2">
        {/*  <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner> */}
        {title}
      </Modal.Body>
    </Modal>
  );
}
