import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format } from "date-fns";

import { CustomInput } from "../utils/CustomInput";
import { useAuth } from "../../hooks/useAuth";
import "../../assets/scss/scoped/issueItem.scss";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";

export const ViewMaintanceDetailsModal = (props) => {
  const [tableData, setTableData] = useState([]);
  const [maintaince, setMaintaince] = useState({});

  const { backendUrl, token, user } = useAuth();

  const fetchExpenses = async (main) => {
    const items = await fetchActionsUtil(
      `${backendUrl}/api/maintance/expenses/${main?.ExpenseID}`,
      "GET"
    );

    return items;
  };

  useEffect(() => {
    setMaintaince(props?.Maintenance);
    // console.log(props?.Maintenance);
    // console.log(user);
  }, [props?.Maintenance]);

  // const wareshouseLocal = JSON.parse(
  //   window.localStorage.getItem("warehouseName")
  // );

  const { data, refetch } = useQuery(
    ["WAREHOUSE_MAINTANCE_EXPENSES", maintaince],
    () => fetchExpenses(maintaince),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  useEffect(() => {
    setTableData(data?.expenses);
  }, [data?.expenses]);

  const onChange = (e) => {
    console.log(e.target.value);
  };

  //   console.log(data);

  const changeAllStatusMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/maintance/expenses`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message }) => {
        refetch();
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const changeSingleStatusMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/maintance/expenses/${payload.Maintain_ID}`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message }) => {
        refetch();
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const onChangeAllStatus = async (Status) => {
    const payload = {
      Status,
      ExpenseID: maintaince.ExpenseID,
      tableData,
    };
    if (
      await ConfirmDialog({
        title: "Approve All Maintance",
        description: "Are you sure you want to approve all these maintaince",
      })
    ) {
      changeAllStatusMutation.mutate(payload);
    }
  };

  const onChangeSingleStatus = async (Status, Maintain_ID) => {
    const payload = {
      Status,
      Maintain_ID,
      ExpenseID: maintaince.ExpenseID,
    };

    if (
      await ConfirmDialog({
        title: "Approve Maintance",
        description: "Are you sure you want to approve this maintaince",
      })
    ) {
      changeSingleStatusMutation.mutate(payload);
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h1>Approve Items</h1>
          </div>

          <p>Approve item by clicking on the button.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="container">
            <div>
              {/*  */}
              <div className="row">
                <div class="col-3 mb-3">
                  <CustomInput
                    name="Serial_Number"
                    placeholder="Enter Item Name"
                    typeOfInput="text"
                    label="Serial Number"
                    disabled
                    value={maintaince?.Serial_Number}
                    onChange={onChange}
                  />
                </div>
                <div className="col-3">
                  <CustomInput
                    name="Item_Name"
                    placeholder="Enter Item Name"
                    typeOfInput="text"
                    label="Item Name"
                    disabled
                    value={maintaince?.Item_Name}
                    onChange={onChange}
                  />
                </div>
                <div className="col-3 mb-3">
                  <CustomInput
                    name="Location"
                    placeholder="Enter Location"
                    typeOfInput="text"
                    disabled
                    label="Location"
                    value={maintaince?.Location}
                    onChange={onChange}
                  />
                </div>
                <div className="col-3">
                  <CustomInput
                    name="Type"
                    placeholder="Enter Location"
                    typeOfInput="text"
                    disabled
                    label="Type"
                    value={maintaince?.Type}
                    onChange={onChange}
                  />
                </div>
              </div>
              {/*  */}
              <div className="row">
                <div className="col-4">
                  <CustomInput
                    name="LastCalibration"
                    placeholder="Enter Item Name"
                    typeOfInput="text"
                    label="Last Maintance Date"
                    disabled
                    value={maintaince?.LastCalibration}
                    onChange={onChange}
                  />
                </div>
                <div className="col-4 mb-3">
                  <CustomInput
                    name="Maintain_By"
                    placeholder="Enter Item Name"
                    typeOfInput="text"
                    label="Last Maintained By"
                    disabled
                    value={maintaince?.Maintain_By}
                    onChange={onChange}
                  />
                </div>
                <div className="col-4">
                  <CustomInput
                    name="Certified_By"
                    placeholder="Enter Item Name"
                    typeOfInput="text"
                    label="Last Certified By"
                    disabled
                    value={maintaince?.Certified_By}
                    onChange={onChange}
                  />
                </div>
              </div>
              {/*  */}
              <div className="row mb-5">
                <div className="col-4">
                  <CustomInput
                    name="new_Maintain_Date"
                    placeholder="Enter Item Name"
                    typeOfInput="text"
                    label="New Maintaince Date"
                    disabled
                    value={
                      maintaince?.new_Maintain_Date &&
                      format(
                        new Date(maintaince.new_Maintain_Date),
                        "MMM dd, yyyy"
                      )
                    }
                    onChange={onChange}
                  />
                </div>

                <div className="col-4">
                  <CustomInput
                    name="Maintain By"
                    placeholder="Enter Item Name"
                    typeOfInput="text"
                    label="Maintain By"
                    disabled
                    value={maintaince?.New_Maintain_By}
                    onChange={onChange}
                  />
                </div>

                <div className="col-4">
                  <CustomInput
                    name="New_Certified_By"
                    placeholder="Enter Item Name"
                    typeOfInput="text"
                    label="Certified By"
                    disabled
                    value={maintaince?.WH_New_Certified_By?.Name}
                    onChange={onChange}
                  />
                </div>
              </div>
              {/*  */}

              {user?.Staff_ID === maintaince?.New_Certified_By && (
                <div className="mt-5 mb-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary mx-3"
                      onClick={() => onChangeAllStatus("Approve")}
                    >
                      Approve All
                    </button>
                    <button
                      onClick={() => onChangeAllStatus("Rejected")}
                      className="btn btn-danger text-white"
                    >
                      Reject All
                    </button>
                  </div>
                </div>
              )}

              {/*  */}
              <div>
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Description</th>
                      <th>Unit Cost</th>
                      <th>Quantity</th>
                      <th>Sub Total</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.expenses &&
                      data?.expenses?.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{el?.Description}</td>
                          <td>
                            {
                              currency(el?.UnitCost, {
                                precision: 2,
                              }).value
                            }
                          </td>
                          <td>
                            {
                              currency(
                                el?.Quantity,

                                {
                                  precision: 2,
                                }
                              ).value
                            }
                          </td>
                          <td>
                            {
                              currency(el?.UnitCost, {
                                precision: 2,
                              }).multiply(el?.Quantity).value
                            }
                          </td>
                          {user?.Staff_ID === maintaince?.New_Certified_By && (
                            <>
                              {el?.Status === "Pending" ? (
                                <td>
                                  <div>
                                    <button
                                      onClick={() =>
                                        onChangeSingleStatus(
                                          "Approve",
                                          el?.Maintain_ID
                                        )
                                      }
                                      className="btn btn-primary btn-sm mx-3"
                                    >
                                      Approve
                                    </button>
                                    <button
                                      onClick={() =>
                                        onChangeSingleStatus(
                                          "Rejected",
                                          el?.Maintain_ID
                                        )
                                      }
                                      className="btn btn-danger btn-sm text-white"
                                    >
                                      Reject
                                    </button>
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div
                                    className={`px-2 ${
                                      el.Status === "Rejected"
                                        ? "view_status_reject"
                                        : el.Status === "Approve"
                                        ? "view_status_all"
                                        : el.Status === null
                                    }`}
                                  >
                                    {el?.Status}
                                  </div>
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              {/*  */}

              <div className="d-flex justify-content-end">
                <div className="row g-3">
                  <div className="col-auto pt-2">
                    <h6>Total Cost: </h6>
                  </div>
                  <div className="col-auto">
                    <div class="input-group mb-3">
                      <span class="input-group-text">₦</span>
                      <input
                        type="text"
                        class="form-control"
                        value={currency(maintaince.Total_Cost, {
                          precision: 2,
                        })}
                        readOnly
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
