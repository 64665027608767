import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/customer-selector.scoped.scss";
import queryString from "query-string";
import CloseIcon from "mdi-react/CloseIcon";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";

export default function VendorSelector({
  setShowCustomerSelector,
  handleSelectedCustomer,
  selectedCustomer = {},
  withCredit = false,
  withPaymentDue = false,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    CompanyName: "",
    Phone: "",
    withCredit,
    withPaymentDue,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getCustomer = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/vendors?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { vendors: [] } } = useQuery(
    ["GET ALL VENDORS", debouncedqueryParams],
    () => getCustomer(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  return (
    <div className="customer-table-area rounded">
      <div className="search-area">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h2 className="mb-0">All Vendors From Database</h2>

          <Button
            onClick={() => setShowCustomerSelector(false)}
            variant="white"
            className="p-0 text-gray"
            size="sm"
          >
            <CloseIcon />
          </Button>
        </div>

        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Vendor Name</Form.Label>
            <Form.Control
              placeholder="Input Vendor Name..."
              name="CompanyName"
              className="h-42"
              onChange={(e) =>
                setQueryParams({
                  ...queryParams,
                  page: 1,
                  CompanyName: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone No.</Form.Label>
            <Form.Control
              placeholder="Input No."
              name="Phone"
              type="tel"
              className="h-42"
              onChange={(e) =>
                setQueryParams({
                  ...queryParams,
                  Phone: e.target.value,
                })
              }
            />
          </Form.Group>
        </Form>
      </div>

      <div className="content px-3 pe-2">
        <Table borderless striped responsive className="product-table">
          <thead className="sticky border-bottom">
            <tr>
              <th scope="col">Vendor ID</th>
              <th scope="col">Vendor Name</th>
            </tr>
          </thead>

          <tbody className="blue-hover">
            {data.vendors &&
              data.vendors.map((el, index) => (
                <tr
                  key={index}
                  onClick={() => handleSelectedCustomer(el)}
                  className={`p-cursor ${
                    selectedCustomer?.Vendor_ID === el.Vendor_ID
                      ? "active-row"
                      : ""
                  } `}
                >
                  <td>{el.Vendor_ID}</td>
                  <td>{el.CompanyName}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
