import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import { useEffectOnce } from "../../utils/hooks";
import ModalLoader from "../utils/ModalLoader";

export default function FormBuilderModal({
  showFormBuilderModal,
  setShowFormBuilderModal,
  saveForm,
}) {
  const iframeRef = useRef();
  const { backendUrl } = useAuth();

  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  const handleMessage = (message) => {
    //  console.log(message);
    if (message.data.type === "CLOSE") {
      setShowFormBuilderModal(false);
    } else if (message.data.type === "SAVE") {
      saveForm(message.data.value);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { departments: [] }, isFetching } = useQuery(
    ["GET_USERS_WITH_DEPARTMENTS"],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            departments: data.departments,
          },
        },
        "http://127.0.0.1:9009"
      );
    }
  };

  return (
    <>
      <Modal
        show={showFormBuilderModal}
        fullscreen={true}
        animation={false}
        onHide={() => setShowFormBuilderModal(false)}
      >
        <Modal.Body className="p-0">
          {!isFetching ? (
            <iframe
              ref={iframeRef}
              id="Frame"
              onLoad={(e) => {
                setIsLoadingIframe(false);
                sendSetupData();
              }}
              className="w-100 h-100"
              title="Form Builder"
              src="http://127.0.0.1:9009/form/build"
            />
          ) : null}
        </Modal.Body>

        {/*  <Button onClick={(e) => sendSetupData()}>...</Button> */}
      </Modal>

      <ModalLoader show={isLoadingIframe || isFetching} />
    </>
  );
}
