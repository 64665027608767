import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import currency from "currency.js";
import { format } from "date-fns";
import CurrencyInput from "react-currency-input-field";

import { SearchIcon } from "../Icons";
import { CustomInput } from "../utils/CustomInput";
import { useAuth } from "../../hooks/useAuth";
import { backendApis } from "../../config";
// import IssueItenModalTable from "../IssueItemModalTable";
import "../../assets/scss/scoped/issueItem.scss";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";
import WarehouseItemTable from "../warehouse/WarehouseItemTable";
import useDebounce from "../../utils/hooks";

const TypeMaintaince = [
  { label: "Scheduled Maintance", value: "Scheduled Maintance" },
  { label: "Repairs", value: "Repairs" },
  { label: "Crash/Collide", value: "Crash/Collide" },
];

const initialFundRequest = {
  description: "",
  unitCost: "",
  quantity: "",
};

export const AddWarehouseMaintanceModal = (props) => {
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const [fundRequest, setFundRequest] = useState(initialFundRequest);
  const [total, setTotal] = useState(0);

  const { backendUrl, token } = useAuth();

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const createNewFundRequest = (e) => {
    if (!e.target.value) return;
    setTableData([...tableData, fundRequest]);
    e.target.blur();

    setTimeout(() => {
      setFundRequest({ ...initialFundRequest });
    }, 0);
  };

  const handleFundRequestInput = (value, name) => {
    setFundRequest({
      ...fundRequest,
      [name]: value,
    });
  };

  const calculateTotal = (tableData) => {
    setTotal(
      tableData
        .map((el) =>
          currency(el.unitCost, {
            precision: 2,
          }).multiply(el.quantity)
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
    );
  };

  const debouncedTableData = useDebounce(tableData, 1000);
  useEffect(() => {
    if (debouncedTableData) calculateTotal(debouncedTableData);
  }, [debouncedTableData]);

  // const wareshouseLocal = JSON.parse(
  //   window.localStorage.getItem("warehouseName")
  // );

  const formik = useFormik({
    initialValues: {
      Serial_Number: "",
      Item_Name: "",
      Location: "",
      Type: "",
      LastCalibration: "",
      Maintain_By: "",
      Certified_By: "",
      new_Maintain_Date: "",
      New_Maintain_By: "",
      New_Certified_By: "",
      warehouse: wareshouseLocal?.whID,
    },
    validationSchema: yup.object().shape({
      Serial_Number: yup.string().required("Issue To is required"),
      Location: yup.string().required("Location is required"),
      Type: yup.string().required("Type is required"),
      new_Maintain_Date: yup.string().required("New maintain date is required"),
      New_Maintain_By: yup.string().required("New maintain by is required"),
      New_Certified_By: yup.string().required("New Certified By is required"),
    }),
    onSubmit: async (values) => {
      if (tableData.length < 1) {
        return toast.error("Add Items that are maintined");
      }
      const payload = { ...values, tableData, Total_Cost: total.value };
      console.log(payload);

      if (
        await ConfirmDialog({
          title: "Save Maintaince",
          description: "Are you sure you want to save this maintaince",
        })
      ) {
        sendMaintance.mutate(payload);
      }
    },
  });

  const handleSelectedItem = (el) => {
    formik.setValues({
      ...formik.values,
      Serial_Number: el.Serial_Number,
      Item_Name: el.Item_Name,
      LastCalibration: format(new Date(el.LastCalibration), "MMM dd, yyyy"),
      Maintain_By: el.Maintain_By,
      Certified_By: el.Certified_By,
    });

    setShowItemsPopover(false);
  };

  const sendMaintance = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/maintance`, "POST", token, payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);

        formik.resetForm({
          values: {
            Item_Name: "",
            Serial_Number: "",
            Location: "",
            Type: "",
            LastCalibration: "",
            Maintain_By: "",
            Certified_By: "",
            new_Maintain_Date: "",
            New_Maintain_By: "",
            New_Certified_By: "",
          },
        });

        setTableData([]);
        props.refetchMain();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h1>Add Item</h1>
          </div>

          <p>Add item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="container">
            <div>
              <form noValidate className="form-relative">
                {/*  */}
                <div className="row">
                  <div class="col-6 mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      class="form-label"
                    >
                      Serial No
                    </label>
                    <div className="input-group">
                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <WarehouseItemTable
                            handleSelectedItem={handleSelectedItem}
                          />
                        )}
                        position="bottom"
                      >
                        <>
                          <CustomInput
                            className="p-cursor"
                            name="Serial_Number"
                            placeholder="Enter Serial Number"
                            typeOfInput="text"
                            value={formik.values.Serial_Number}
                            disabled
                            onChange={formik.handleChange}
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          />

                          <span
                            className="input-group-text border p-cursor"
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <SearchIcon />
                          </span>
                        </>
                      </Popover>
                    </div>
                    {formik.touched.Serial_Number &&
                    !!formik.errors.Serial_Number ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Serial_Number}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-6">
                    <CustomInput
                      name="Item_Name"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Item Name"
                      disabled
                      value={formik.values.Item_Name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.Item_Name && !!formik.errors.Item_Name ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Item_Name}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/*  */}

                <div className="row">
                  <div className="col-6 mb-3">
                    <CustomInput
                      name="Location"
                      placeholder="Enter Location"
                      typeOfInput="text"
                      label="Location"
                      value={formik.values.Location}
                      onChange={formik.handleChange}
                    />

                    {formik.touched.Location && !!formik.errors.Location ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Location}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-6 mb-3">
                    <label htmlFor="date" className="form-label">
                      Type
                    </label>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={false}
                      value={TypeMaintaince.find(
                        (el) => el.value === formik.values.Type
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Type", value);
                        // getStaffs(value);
                      }}
                      options={TypeMaintaince}
                    />

                    {formik.touched.Type && !!formik.errors.Type ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Type}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-6 mb-3">
                    <CustomInput
                      name="LastCalibration"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Last Maintance Date"
                      disabled
                      value={formik.values.LastCalibration}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <CustomInput
                      name="Maintain_By"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Last Maintained By"
                      disabled
                      value={formik.values.Maintain_By}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-6 mb-3">
                    <CustomInput
                      name="Certified_By"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Last Certified By"
                      disabled
                      value={formik.values.Certified_By}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="date" className="form-label">
                      New Maintain Date
                    </label>
                    <Datetime
                      dateFormat="MMM DD, YYYY"
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      name="new_Maintain_Date"
                      inputProps={{
                        className: `date-input form-control ${
                          formik.touched.new_Maintain_Date &&
                          !!formik.errors.new_Maintain_Date
                            ? "is-invalid"
                            : ""
                        }`,

                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.new_Maintain_Date}
                      onChange={(date) => {
                        formik.setFieldValue("new_Maintain_Date", date, true);
                      }}
                      onBlur={() =>
                        formik.setFieldTouched("new_Maintain_Date", true)
                      }
                    />
                    {formik.touched.new_Maintain_Date &&
                    !!formik.errors.new_Maintain_Date ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.new_Maintain_Date}
                      </span>
                    ) : null}
                  </div>
                </div>

                {/*  */}

                {/*  */}
                <div className="row">
                  <div className=" col-6 mb-3">
                    <label htmlFor="date" className="form-label">
                      Maintain By
                    </label>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={false}
                      value={props?.vendors?.find(
                        (el) => el.value === formik.values.New_Maintain_By
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("New_Maintain_By", value);
                        // getStaffs(value);
                      }}
                      options={props?.vendors}
                    />

                    {formik.touched.New_Maintain_By &&
                    !!formik.errors.New_Maintain_By ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.New_Maintain_By}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="date" className="form-label">
                      Certified By
                    </label>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={true}
                      value={props.staffs.find(
                        (el) => el.value === formik.values.New_Certified_By
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("New_Certified_By", value)
                      }
                      options={props.staffs}
                    />

                    {formik.touched.New_Certified_By &&
                    !!formik.errors.New_Certified_By ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.New_Certified_By}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/*  */}

                <div>
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Description</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>
                              <input
                                className="form-control border-0 px-1"
                                value={el.description}
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.target.blur()
                                }
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: "description",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={false}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.target.blur()
                                }
                                onChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}

                      <tr
                        className={`bg-light p-cursor ${activeTableIndex ===
                          -1 && "active-row"}`}
                        onClick={() =>
                          activeTableIndex !== -1 && setActiveTableIndex(-1)
                        }
                      >
                        <td>
                          <label
                            className=""
                            htmlFor="fund-request-description"
                          >
                            {"..."}
                          </label>
                        </td>
                        <td>
                          <input
                            className="form-control border-0 px-1 bg-white"
                            name="description"
                            id="fund-request-description"
                            value={fundRequest.description}
                            onChange={(e) =>
                              handleFundRequestInput(
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-0 px-1 bg-white"
                            value={fundRequest.unitCost}
                            name="unitCost"
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            disableGroupSeparators={true}
                            decimalsLimit={2}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-0 px-1 bg-white"
                            value={fundRequest.quantity}
                            name="quantity"
                            onKeyDown={(e) =>
                              e.key === "Enter" && createNewFundRequest(e)
                            }
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            disableGroupSeparators
                            allowNegativeValue={false}
                            allowDecimals={false}
                          />
                        </td>
                        <td className="bg-white">
                          {fundRequest.unitCost && fundRequest.quantity ? (
                            <CurrencyInput
                              className="form-control border-0 px-1 bg-white"
                              name="subtotal"
                              disabled
                              value={
                                !isNaN(
                                  fundRequest.unitCost * fundRequest.quantity
                                )
                                  ? currency(fundRequest.unitCost, {
                                      precision: 2,
                                    }).multiply(fundRequest.quantity)
                                  : ""
                              }
                              //  disableGroupSeparators
                              decimalsLimit={2}
                            />
                          ) : null}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {/*  */}

                <div className="row g-3">
                  <div className="col-auto pt-2">
                    <h6>Total Cost: </h6>
                  </div>
                  <div className="col-auto">
                    <div class="input-group mb-3">
                      <span class="input-group-text">₦</span>
                      <input
                        type="text"
                        class="form-control"
                        value={total}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                {/*  */}
                <div className="add-to-cart-holder d-flex justify-content-end text-primary">
                  <Button
                    variant="outline-primary"
                    onClick={formik.handleSubmit}
                  >
                    Save Maintance
                  </Button>
                </div>
              </form>
            </div>

            {/*  */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
