import { Form, Table, Button, Pagination  } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import DetailedIcon, { ExportIcon, FilterIcon, PrintIcon, SummaryChartIconUp } from "../../Icons";
import DatePickerCustomInput from "../../utils/DatePickerCustomInput";
import DateRangePicker from "../../utils/DateRangePicker";
import  ChevronUpIcon  from "mdi-react/ChevronUpIcon";

function Payment() {
  return (
    <>
    <div className="absolute-title">
      <h1> Sales Reps Analysis</h1>
      <div className="actions">
        <div className="position-relative">
          {/* <button className="btn print">
            Filters <FilterIcon />
          </button> */}
          {/* <div className="filter-dropdown with-stats">
            <Form>
              <div className="body">
                <Form.Group className="mb-2-5">
                  <Form.Label>Date</Form.Label>
                  <div>
                    <ReactDatePicker
                      selected={new Date()}
                      onChange={(date) => {}}
                      customInput={<DatePickerCustomInput />}
                      dateFormat="MMMM dd, yyyy"
                      showPopperArrow={false}
                      placeholderText="Select date"
                    />
                  </div>
                </Form.Group>

                <div className="mb-2-5">
                  <Form.Check
                    inline
                    label="Search in Date"
                    name="group1"
                    type={"checkbox"}
                    id={`inline-radio-1`}
                  />
                </div>

                <Form.Group className="mb-2-5">
                  <Form.Label>Date Range</Form.Label>
                  <div>
                    <DateRangePicker
                      format="DD MMM, YYYY"
                      placement="topRight"
                    />
                  </div>
                </Form.Group>

                <hr />

                <Form.Group className="mb-2-5">
                  <Form.Label>Branch</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Choose method"
                    isSearchable={false}
                  />
                </Form.Group>

                <Form.Group className="">
                  <Form.Label>Sales Rep</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Choose method"
                    isSearchable={false}
                  />
                </Form.Group>
              </div>

              <div className="buttons rounded">
                <Button
                  variant="outline-light text-danger"
                  className="border-0"
                >
                  Reset
                </Button>
                <Button variant="primary">Apply</Button>
              </div>
            </Form>
          </div> */}
        </div>

        
        <button className="btn print">
          Print <PrintIcon />
        </button>
        <button className="btn print">
          Export <ExportIcon />
        </button>
      </div>
    </div>

    <Table responsive borderless striped className="product-table mt-4">
      <thead>
        <tr>
          <th>Model No</th>
          <th>Item Name</th>
          <th>QTY</th>
          <th>Price Sold</th>
          <th>Unit Cost</th>
          <th>Profit</th>
          <th>Sales Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>01</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>
        <tr>
          <td>02</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>
        <tr>
          <td>03</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>
        <tr>
          <td>04</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>

        <tr>
          <td>05</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>

        <tr>
          <td>06</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>

        <tr>
          <td>07</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>

        <tr>
          <td>08</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>

        <tr>
          <td>09</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>

        <tr>
          <td>10</td>
          {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
          ))}
        </tr>
      </tbody>
    </Table>

    <div className="pagination ">
          {/* Left */}
              <div className="pagination_left">
                      <p className="m-0 p-0">Show</p>
                      <select name="rows" className="form-select ">
                          <option value="10">10 rows</option>
                          <option value="20">20 rows</option>
                          <option value="30">30 rows</option>
                          <option value="40">40 rows</option>
                          
                      </select>

              </div>

          {/* right */}
              <div className="pagintion_right">
              <Pagination>
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>

                <Pagination.Ellipsis className="border-none" />

                <Pagination.Item>{6}</Pagination.Item>
                <Pagination.Next className="next" />
              </Pagination>
              </div>

    </div>

    {/* <div className="p-4 border-top">
      <h2>Summary</h2>
      <div className="summary">
        <table className="table table-borderless balance">
          <tbody>
            <tr>
              <td>Total Sales</td>
              <td>218,134,177.92</td>
            </tr>

            <tr>
              <td>Total Profit</td>
              <td>9,276,961.63</td>
            </tr>

            <tr>
              <td>Total Losses</td>
              <td>N/A</td>
            </tr>

            <tr>
              <td>Qty Sold</td>
              <td>19884</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> */}

<div className="summaryNew ">
  

  <div className="gridCont">

     <div className="gridChild gridChildBorderLeftBlue">

          <div className="gridChildLeft">
            <p className="gridChld1">5,800.00</p>    
            <p className="gridChld2">Total Cash Sales</p> 
          </div>

          <div className="gridChildRight">
              <SummaryChartIconUp/>
              <p>5%</p>
          </div>  
      </div>

  </div>
  
</div>

  </>
  )
}

export default Payment