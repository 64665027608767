import { useEffect, useState, useRef, Fragment } from "react";
import {
  Button,
  Modal,
  Table,
  InputGroup,
  Popover,
  Overlay,
  Form,
} from "react-bootstrap";
import "./../../assets/scss/requisition/index.scss";
import { Logo, PrintIcon, SignatureIcon } from "../Icons";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import CurrencyInput from "react-currency-input-field";
import { find, isEmpty, lowerCase } from "lodash";
import { isInventoryOrFundRequest, waitFor } from "../../utils/helpers";
import useDebounce from "../../utils/hooks";
import { toast } from "react-toastify";
import { queryActions } from "../../utils/reactQueryActions";
import currency from "currency.js";

import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
import { useFormik } from "formik";
import RequisitionService from "../../hooks/requisitionService";
import * as yup from "yup";
import eventBus from "../../utils/EventBus";

export default function NewFundAndInventoryModal({
  location,
  requisition,
  setShowNewRequisitionModal,
  previousFormValues,
}) {
  const initialFundRequest = {
    description: "",
    unitCost: "",
    quantity: "",
  };
  let { id } = useParams();
  const { createRequisitionMutation } = RequisitionService();
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [fundRequest, setFundRequest] = useState(initialFundRequest);
  const { user: authUser, backendUrl } = useAuth();
  const [file, setFile] = useState();

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const calculateTotal = (tableData) => {
    setTotal(
      tableData
        .map((el) =>
          currency(el.unitCost, {
            precision: 2,
          }).multiply(el.quantity)
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
    );
  };

  const debouncedTableData = useDebounce(tableData, 1000);
  useEffect(() => {
    if (debouncedTableData) calculateTotal(debouncedTableData);
  }, [debouncedTableData]);

  const save = (formValues) => {
    // if (!file) toast.error("Please attach your signature");
    let description = "";
    if (lowerCase(previousFormValues.type) === "fund request") {
      description = [
        ...tableData,
        // add if form has data
        ...(fundRequest.description ? [fundRequest] : []),
      ]
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(previousFormValues.type) === "inventory") {
      description = tableData
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    }

    description = description + "&&&";

    formValues.requestto = formValues.recipient;
    formValues.regdate = format(new Date(), "yyyy-MM-dd");
    formValues.status = "Pending";

    createRequisitionMutation.mutate(
      {
        requestid: id,
        description,
        file,
        amount: total,
        ...formValues,
      },
      {
        onSuccess: (data) => {
          formik.resetForm();
          eventBus.dispatch("REQUISITION_CREATED", data.requisition);
          setShowNewRequisitionModal(false);
        },
      }
    );
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const ref = useRef(null);

  const handleRowClick = (e, index) => {
    setShow(true);
    setTarget(e.target);
    //  setActiveTableIndex(index);
  };

  const createNew = (e) => {
    setShow(true);
    setTarget(e.target);
    setActiveTableIndex(-1);
  };

  const handleSelectedItem = (item) => {
    console.log(item);
    /* Bar_Code: "0003";
    Branch: "HQ";
    Cat_Name: "A2 Lubricant";
    Item_Name: "A2 Oil 1L";
    Quantity: 12;
    UnitCost: 1450;
    UnitPrice: 1650;
    imagepath: ""; */
    if (lowerCase(previousFormValues.type) === "inventory") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        unitCost: item.UnitCost,
        quantity: 1,
      };
    } else if (lowerCase(previousFormValues.type) === "fund request") {
    }

    let oldTableData = tableData;

    if (activeTableIndex === -1) {
      oldTableData = [...oldTableData, item];
    } else {
      oldTableData[activeTableIndex] = item;
    }
    setTableData([...oldTableData]);
    if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    setShow(false);
  };

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  const handleFundRequestInput = (value, name) => {
    console.log(value, name);
    setFundRequest({
      ...fundRequest,
      [name]: value,
    });
  };

  const createNewFundRequest = (e) => {
    if (!e.target.value) return;
    setTableData([...tableData, fundRequest]);
    e.target.blur();

    setTimeout(() => {
      setFundRequest({ ...initialFundRequest });
    }, 0);
  };

  const formik = useFormik({
    initialValues: {
      title: previousFormValues.title,
      type: previousFormValues.type,
      department: previousFormValues.department,
      recipient: previousFormValues.recipient,
      comment: previousFormValues.comment,
      vendor: previousFormValues.vendor,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      type: yup.string().required(),
      department: yup.string().required(),
      recipient: yup.string().required(),
      comment: yup.string(),
      vendor: yup.string().required(),
    }),
    onSubmit: (values) => {
      if (isEmpty(values.comment)) values.comment = "...";

      save(values);
    },
  });

  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const { data: { departments } = { departments: null } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { users } = { users: null },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS, formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  const fetchVendors = async (department) => {
    let response = await fetch(`${backendUrl}/api/vendors`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { vendors } = { vendors: [] },
    isFetching: isLoadingVendors,
  } = useQuery([queryActions.GET_VENDORS], () => fetchVendors(), {
    enabled: true,
  });

  useEffect(() => {
    if (formik.values.department) {
      getUsers();
    }
  }, [formik.values.department]);

  return (
    <div>
      <Modal
        show={true}
        onHide={() => setShowNewRequisitionModal(false)}
        dialogClassName="requisition-details-modal preview-modal create-form"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="py-2 capitalize">{previousFormValues.type}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="preview p-0">
          <div className="preview-content p-0">
            <div className="content">
              <div className="preview-body">
                <Form
                  className="mb-3"
                  id="requisition-form"
                  noValidate
                  onSubmit={formik.handleSubmit}
                >
                  <Form.Group className="mb-3">
                    <Form.Label>Title :</Form.Label>
                    <Form.Control
                      name="title"
                      placeholder="Enter a title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.title && !!formik.errors.title}
                    />

                    <Form.Control.Feedback type="invalid">
                      {formik.errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Vendor :</Form.Label>
                    <Form.Select
                      name="vendor"
                      value={formik.values.vendor}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.vendor && !!formik.errors.vendor
                      }
                    >
                      <option value="">Select Vendor</option>
                      {vendors.map((el, index) => (
                        <option key={index}>{`  ${el.CompanyName} / ${
                          el.Vendor_ID
                        }`}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.vendor}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className="row gap-3">
                    <Form.Group className="col mb-3">
                      <Form.Label>Department :</Form.Label>
                      <Form.Select
                        name="department"
                        value={formik.values.department}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.department &&
                          !!formik.errors.department
                        }
                      >
                        <option value="">Select Recipient's Department</option>
                        {departments &&
                          departments.map((el, index) => (
                            <option key={index} value={el.Department}>
                              {el.Department}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.department}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col mb-3">
                      <Form.Label>Recipient :</Form.Label>
                      <Form.Select
                        name="recipient"
                        value={formik.values.recipient}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.recipient && !!formik.errors.recipient
                        }
                        disabled={isLoadingUsers}
                      >
                        <option value="">Select Recipient </option>
                        {users &&
                          users.map((el, index) => (
                            <Fragment key={index}>
                              {el.Staff_ID !== authUser.Staff_ID && (
                                <option value={el.Staff_ID}>{el.Name}</option>
                              )}
                            </Fragment>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.recipient}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <Form.Group className="mb-3">
                    <Form.Label>Comment :</Form.Label>
                    <label className="border p-0 rounded message-area d-block">
                      <Form.Control
                        name="comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        as="textarea"
                        className="w-100 border-0 p-3"
                        placeholder="Enter a comment"
                        rows={4}
                      />
                    </label>
                  </Form.Group>
                </Form>

                {lowerCase(previousFormValues.type) === "inventory" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Barcode</th>
                        <th>Item Name</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody ref={ref}>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td
                              className="p-cursor"
                              onClick={(e) => handleRowClick(e, index)}
                            >
                              {el.barcode}
                            </td>
                            <td>{el.itemName}</td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                //    disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={false}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr
                        onClick={(e) => createNew(e)}
                        className={`p-cursor ${activeTableIndex === -1 &&
                          "active-row"}`}
                      >
                        <td>
                          <span className="">{"..."}</span>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </Table>
                ) : lowerCase(previousFormValues.type) === "fund request" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Description</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>
                              <input
                                className="form-control border-0 px-1"
                                value={el.description}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: "description",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={false}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}

                      <tr
                        className={`bg-light p-cursor ${activeTableIndex ===
                          -1 && "active-row"}`}
                        onClick={() =>
                          activeTableIndex !== -1 && setActiveTableIndex(-1)
                        }
                      >
                        <td>
                          <label
                            className=""
                            htmlFor="fund-request-description"
                          >
                            {"..."}
                          </label>
                        </td>
                        <td>
                          <input
                            className="form-control border-0 px-1 bg-white"
                            name="description"
                            id="fund-request-description"
                            value={fundRequest.description}
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            onChange={(e) =>
                              handleFundRequestInput(
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-0 px-1 bg-white"
                            value={fundRequest.unitCost}
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            name="unitCost"
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            disableGroupSeparators
                            decimalsLimit={2}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-0 px-1 bg-white"
                            value={fundRequest.quantity}
                            name="quantity"
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            disableGroupSeparators
                            allowNegativeValue={false}
                            allowDecimals={false}
                          />
                        </td>
                        <td>
                          {fundRequest.unitCost && fundRequest.quantity ? (
                            <CurrencyInput
                              className="form-control border-0 px-0 bg-white"
                              name="subtotal"
                              disabled
                              value={
                                !isNaN(
                                  fundRequest.unitCost * fundRequest.quantity
                                )
                                  ? currency(fundRequest.unitCost, {
                                      precision: 2,
                                    }).multiply(fundRequest.quantity)
                                  : ""
                              }
                              //  disableGroupSeparators
                              decimalsLimit={2}
                            />
                          ) : null}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : null}
                <Overlay
                  show={show}
                  onHide={() => setShow(false)}
                  target={target}
                  container={document.body}
                  containerPadding={0}
                  rootClose={true}
                  popperConfig={{
                    strategy: "fixed",
                    modifiers: [
                      {
                        name: "ga-custom",
                        enabled: true,
                        phase: "beforeWrite",
                        fn({ state }) {
                          const oldTransfrom = String(
                            state.styles.popper?.transform
                          );
                          const y = Number(
                            String(oldTransfrom)
                              .split(",")[1]
                              .split(")")[0]
                              .split("px")[0]
                              .trim()
                          );

                          const x = Number(
                            String(oldTransfrom)
                              .split(",")[0]
                              .split("(")[1]
                              .split("px")[0]
                              .trim()
                          );

                          //  console.log(x, y, y < -210);

                          // state.styles.popper.left = `50%`;
                          // state.styles.popper.top = `50%`;
                          state.styles.popper.transform =
                            y < -210
                              ? `translate(${x}px, ${-210}px)`
                              : `translate(${x}px, ${y}px)`;
                        },
                      },
                    ],
                  }}
                >
                  <Popover
                    style={{
                      maxWidth: "unset",
                      height: "28.75rem",
                      overflow: "hidden",
                      boxShadow: "0px 4px 20px 0px #131B2814",
                    }}
                  >
                    <ItemsTable handleSelectedItem={handleSelectedItem} />
                  </Popover>
                </Overlay>
              </div>
              <div className="preview-footer">
                {isInventoryOrFundRequest(previousFormValues.type) ? (
                  <>
                    <p>Total Amount Request Expense: </p>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">₦</InputGroup.Text>
                      <CurrencyInput
                        placeholder="0.00"
                        className="form-control"
                        // disableGroupSeparators
                        disabled
                        value={total}
                      />
                    </InputGroup>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="px-4 bg-white">
          {![null, -1].includes(activeTableIndex) ? (
            <Button
              disabled={createRequisitionMutation.isLoading}
              onClick={() => removeSelection()}
              variant="danger"
              size="sm"
              className="text-white px-3 "
            >
              Remove Selection
            </Button>
          ) : (
            <span />
          )}

          <div className="proceed-actions">
            <Button
              disabled={createRequisitionMutation.isLoading}
              //  onClick={() => save()}
              variant="primary"
              size="sm"
              type="submit"
              form="requisition-form"
            >
              {createRequisitionMutation.isLoading
                ? "Please wait..."
                : "Send request"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
