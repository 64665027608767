import { Dropdown, Table, Form, Button, Modal } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  DropdownEyeIcon,
  LinkVariantIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  employeeFullName,
  paginationOptions,
  reactSelectTheme,
  styleHelper,
} from "../../utils/helpers";
import RsDateRangePicker from "./../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { CoinIcon } from "../Icons";
import PageHeader from "../PageHeader";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import GenerateDriverSalaryModal from "./GenerateDriverSalaryModal";
import { Link } from "react-router-dom";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import Datetime from "react-datetime";
import moment from "moment";
import { CSVLink } from "react-csv";
import EditDriverSalaryModal from "./EditDriverSalaryModal";
import { isEmpty } from "lodash";

const tableHeaders = [
  { label: "OrgTranParticular", key: "OrgTranParticular" },
  { label: "OrgTranRefNo", key: "OrgTranRefNo" },
  { label: "Amount", key: "Amount" },
  { label: "DebitAccountNo", key: "DebitAccountNo" },
  { label: "DebitBankCode", key: "DebitBankCode" },
  { label: "CreditAccountNo", key: "CreditAccountNo" },
  { label: "CreditBankCode", key: "CreditBankCode" },
  { label: "BeneficiaryName", key: "BeneficiaryName" },
  { label: "PaymentDate", key: "PaymentDate" },
  { label: "Narration", key: "Narration" },
];

function SalarySheet({ setShowSalarySheet, queryParams, setQueryParams }) {
  const { backendUrl, user: authUser } = useAuth();
  const [selectedBank, setSelectedBank] = useState(null);
  const [tableData, setTableData] = useState([]);

  const fetchSalary = async (date) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/driver/sheet?date=${queryParams.date}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.banks = data.banks
      .map((el) => ({
        ...el,
        label: el.BankName,
        value: el.BankName,
      }))
      .filter((el) => el.value);
    return data;
  };

  const { data = { banks: [] }, refetch } = useQuery(
    ["DRIVER_SALARY_SHEET", queryParams],
    () => fetchSalary(queryParams),
    {
      keepPreviousData: true,
      onSuccess: ({ drivers, banks }) => {
        setTableData([...drivers]);
        setSelectedBank(banks[0].value);
      },
    }
  );

  const getSelectedBank = useMemo(() => {
    return data?.banks.find((el) => el.value === selectedBank);
  }, [selectedBank, data?.banks]);

  /* const create = async (payload) => {
    let response = await fetch(`${backendUrl}/api/driver/save-salary`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createMutation = useMutation((payload) => create(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      handleCreatedSalary(date);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const editPay = (index, formValues) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const save = () => {
    const driversSalary = tableData.map((el) => ({
      ...el,
      RegDate: date,
      PreparedBy: authUser.Name,
      Name: employeeFullName(el),
    }));
    createMutation.mutate({ driversSalary, date });
  }; */

  const tableDataCleanUp = useMemo(() => {
    return tableData.map((el) => {
      const Narration = format(new Date(), "MM yyyy");
      return {
        ...el,
        OrgTranParticular: el.employeeBankAccount.AccountNo,
        OrgTranRefNo: "",
        Amount: Number(el.NetSalary).toFixed(),
        DebitAccountNo: getSelectedBank?.AccountNo,
        DebitBankCode: getSelectedBank?.BankCode,
        CreditAccountNo: el.employeeBankAccount.AccountNo,
        CreditBankCode: el.employeeBankAccount.Branch,
        BeneficiaryName: el.Name,
        PaymentDate: format(new Date(), "dd/MM/yyyy"),
        Narration: `${Narration} Salary`,
      };
    });
  }, [tableData, getSelectedBank]);

  return (
    <Modal
      show={true}
      onHide={() => setShowSalarySheet(false)}
      dialogClassName="modal-90w mx-auto"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Salary Sheet</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex  gap-3">
            <Datetime
              closeOnSelect={true}
              dateFormat="MMMM, YYYY"
              timeFormat={false}
              inputProps={{
                className: `date-input form-control fs-6`,
                placeholder: "Select date",
                readOnly: true,
              }}
              value={moment(queryParams.date)}
              onChange={(date) => {
                setQueryParams({
                  ...queryParams,
                  date,
                });
              }}
            />
            <Select
              classNamePrefix={"form-select"}
              value={getSelectedBank}
              onChange={({ value }) => setSelectedBank(value)}
              options={data?.banks}
            />
          </div>
          <div className="">
            <CSVLink
              className="btn btn-light-blue"
              data={tableDataCleanUp}
              headers={tableHeaders}
              filename={`slip.csv`}
              asyncOnClick={true}
              onClick={(event, done) => {}}
            >
              <ExportIcon /> Export to Excel{" "}
            </CSVLink>
          </div>
        </div>

        <Table
          responsive
          borderless
          hover
          striped
          className="product-table text-nowrap"
        >
          <thead>
            <th>OrgTranParticular</th>
            <th>OrgTranRefNo</th>
            <th>Amount</th>
            <th>DebitAccountNo</th>
            <th>DebitBankCode</th>
            <th>CreditAccountNo</th>
            <th>CreditBankCode</th>
            <th>BeneficiaryName</th>
            <th>PaymentDate</th>
            <th>Narration</th>
          </thead>
          <tbody>
            {tableData.map((el, index) => (
              <tr key={index}>
                <td>{el.employeeBankAccount.AccountNo}</td>
                <td>{el.employeeBankAccount.RefNo}</td>
                <td>
                  {currency(el.NetSalary, {
                    symbol: "",
                  }).format()}
                </td>
                <td>{getSelectedBank?.AccountNo}</td>
                <td>{getSelectedBank?.BankCode}</td>
                <td>{el.employeeBankAccount.AccountNo}</td>
                <td>{el.employeeBankAccount.Branch}</td>
                <td>{el.Name}</td>
                <td>{format(new Date(), "dd/MM/yyyy")}</td>
                <td>{format(new Date(), "MM yyyy")} Salary</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="gap-3">{"..."}</Modal.Footer>
    </Modal>
  );
}

export default function DriverSalary() {
  useScrollTop();

  const initialFilterParams = {
    date: moment(),
  };

  const { backendUrl } = useAuth();
  const [showGenerateSalaryModal, setShowGenerateSalaryModal] = useState(false);
  const [
    showEditGenerateSalaryModal,
    setShowEditGenerateSalaryModal,
  ] = useState(false);
  const [showSalarySheet, setShowSalarySheet] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);

  const [queryParams, setQueryParams] = useQueryParams({
    /*  page: 1,
    limit: 50, */
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const fetchSalaryForDriver = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/driver/salary?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { count: 0, salary: [] }, refetch } = useQuery(
    [queryActions.DRIVER_SALARY, queryParams],
    () => fetchSalaryForDriver(queryParams),
    {
      keepPreviousData: true,
    }
  );
  const handleCreatedSalary = (date) => {
    setShowGenerateSalaryModal(false);
    setQueryParams({
      ...queryParams,
      date,
    });
  };

  const handleEditedSalary = (date) => {
    setShowEditGenerateSalaryModal(false);
    setQueryParams({
      ...queryParams,
      date,
    });
  };

  const totalNetSalary = useMemo(() => {
    return data?.salary
      .map((el) => el.NetSalary)
      .filter((el) => Number(el) > 0)
      .reduce(
        (a, b) =>
          currency(a, {
            symbol: "",
            precision: 2,
          }).add(b),
        0
      );
  }, [data?.salary]);

  return (
    <main className="inner-page-outlet">
      <PageHeader
        name="Drivers Salary Center"
        description=""
        icon={<CoinIcon />}
      />

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Salary
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <Datetime
                    closeOnSelect={true}
                    dateFormat="MMMM, YYYY"
                    timeFormat={false}
                    inputProps={{
                      className: `date-input form-control fs-6`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={moment(queryParams.date)}
                    onChange={(date) => {
                      setQueryParams({
                        ...queryParams,
                        date,
                      });
                    }}
                  />

                  <button
                    disabled={isEmpty(data.salary)}
                    onClick={() => copyText(window.location)}
                    className="btn border-light text-primary px-3"
                  >
                    <LinkVariantIcon /> {"  "}
                    Copy Link
                  </button>

                  <button
                    disabled={isEmpty(data.salary)}
                    onClick={() => setShowSalarySheet(true)}
                    className="btn btn-light-blue text-primary px-3"
                  >
                    Open Salary Sheet
                  </button>

                  <button
                    disabled={isEmpty(data.salary)}
                    onClick={() => setShowEditGenerateSalaryModal(true)}
                    className="btn btn-light-blue text-primary px-3"
                  >
                    Edit Payslip
                  </button>

                  <button
                    onClick={() => setShowGenerateSalaryModal(true)}
                    className="btn btn-primary px-3"
                  >
                    Generate New Payslip
                  </button>
                </div>
              </header>

              <div className="px-4">
                <Table
                  responsive
                  borderless
                  striped
                  hover
                  className="product-table"
                >
                  <thead>
                    <tr>
                      <th>EmployeeId</th>
                      <th>Name</th>
                      {/*  <th>Month, Year</th> */}
                      <th>Total Wages</th>
                      <th>Salary Advanced Collected</th>
                      <th>Bonuses</th>
                      <th>Deductions</th>
                      <th>Remark</th>
                      <th>Net Pay</th>
                      <th> Prepared Date </th>
                      <th>Prepared By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.salary?.map((el, index) => (
                      <tr key={index}>
                        <td>{el.EmployeeID}</td>
                        <td>{el.Name}</td>
                        {/*   <td>{format(new Date(el.RegDate), "MMM, yyyy")}</td> */}
                        <td>
                          {currency(el.TotalWages, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.SalaryAdvanceCollected, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.Bonuses, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.Deductions, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>{el.Remark}</td>
                        <td className="fw-bold">
                          {currency(el.NetSalary, {
                            symbol: "",
                          }).format()}{" "}
                        </td>

                        <td>{format(new Date(el.RegDate), "dd MMM, yyyy")}</td>
                        <td>{el.PreparedBy}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="summaryNew ">
                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(totalNetSalary, {
                          symbol: "₦",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Net Salary</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                  {/* 
                  <div className="gridChild gridChildBorderLeftDarkGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalValueOfItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Value of Item in Stock</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {showGenerateSalaryModal && (
        <GenerateDriverSalaryModal
          setShowGenerateSalaryModal={setShowGenerateSalaryModal}
          handleCreatedSalary={handleCreatedSalary}
        />
      )}

      {showSalarySheet && (
        <SalarySheet
          setShowSalarySheet={setShowSalarySheet}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      )}
      {showEditGenerateSalaryModal && (
        <EditDriverSalaryModal
          date={queryParams.date}
          setShowEditGenerateSalaryModal={setShowEditGenerateSalaryModal}
          handleEditedSalary={handleEditedSalary}
        />
      )}
    </main>
  );
}
