import { format } from "date-fns";
import { Modal, Form, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../config";
import { queryActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import CurrencyInput from "react-currency-input-field";
import NewFundAndInventoryModal from "./NewFundAndInventoryModal";
import { isInventoryOrFundRequest } from "../../utils/helpers";
import RequisitionService from "../../hooks/requisitionService";
import { isEmpty } from "lodash";

export default function NewRequisitionModal({
  location,
  setShowNewRequisitionModal,
}) {
  const { user: authUser, backendUrl } = useAuth();
  const { createRequisitionMutation } = RequisitionService();
  // const queryClient = useQueryClient();
  const navigate = useNavigate();
  let { id } = useParams();

  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const { data: { departments } = { departments: null } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  const formik = useFormik({
    initialValues: {
      title: "",
      type: "",
      cost: 0.0,
      description: "",
      department: "",
      recipient: "",
      comment: "",
      vendor: "",
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      description: yup.string(),
      type: yup.string().required(),
      cost: yup.number().default(0),
      department: yup.string().required(),
      recipient: yup.string().required(),
      vendor: yup.string().required(),
      comment: yup.string(),
    }),
    onSubmit: (values) => {
      values.amount = values.cost;
      values.requestto = values.recipient;
      values.regdate = format(new Date(), "yyyy-MM-dd");
      values.status = "Pending";

      if (isEmpty(values.comment)) values.comment = "...";

      delete values.cost;
      delete values.recipient;

      // console.log(values);

      createRequisitionMutation.mutate(
        { ...values },
        {
          onSuccess: (data) => {
            formik.resetForm();
            eventBus.dispatch("REQUISITION_CREATED", data.requisition);
            setShowNewRequisitionModal(false);
          },
          onError: ({ errors }) => {
            if (errors) formik.setErrors(errors);
          },
        }
      );
    },
  });

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { users } = { users: null },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS, formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  const fetchVendors = async (department) => {
    let response = await fetch(`${backendUrl}/api/vendors`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { vendors } = { vendors: [] },
    isFetching: isLoadingVendors,
  } = useQuery([queryActions.GET_VENDORS], () => fetchVendors(), {
    enabled: true,
  });

  useEffect(() => {
    if (formik.values.department) {
      getUsers();
    }
  }, [formik.values.department]);

  return !isInventoryOrFundRequest(formik.values.type) ? (
    <Modal
      show={true}
      onHide={() => setShowNewRequisitionModal(false)}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1> New Requisition</h1>
        </Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Body className="approve-action">
          <div className="">
            <Form.Group className="mb-3">
              <Form.Label>Title :</Form.Label>
              <Form.Control
                name="title"
                placeholder="Enter a title"
                value={formik.values.title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.title && !!formik.errors.title}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Type :</Form.Label>
              <Form.Select
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                isInvalid={formik.touched.type && !!formik.errors.type}
              >
                <option value="">Select Type of Requisition</option>
                <option>Inventory</option>
                <option>Fund Request</option>
                <option>Normal</option>
                <option>Other</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.type}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description :</Form.Label>
              <label className="border p-0 rounded pb-2 message-area d-block">
                <Form.Control
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  as="textarea"
                  className="w-100 border-0 p-3"
                  placeholder="Enter your detail description"
                  rows={4}
                />
              </label>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Cost :</Form.Label>
              <CurrencyInput
                allowNegativeValue={false}
                className="form-control"
                name="cost"
                value={formik.values.cost}
                onValueChange={(value, name) =>
                  formik.setFieldValue(name, value, true)
                }
                isInvalid={formik.touched.cost && !!formik.errors.cost}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.cost}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Vendor :</Form.Label>
              <Form.Select
                name="vendor"
                value={formik.values.vendor}
                onChange={formik.handleChange}
                isInvalid={formik.touched.vendor && !!formik.errors.vendor}
              >
                <option value="">Select Vendor</option>
                {vendors.map((el, index) => (
                  <option key={index}>{`  ${el.CompanyName} / ${
                    el.Vendor_ID
                  }`}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.vendor}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row gap-3">
              <Form.Group className="col mb-3">
                <Form.Label>Department :</Form.Label>
                <Form.Select
                  name="department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.department && !!formik.errors.department
                  }
                >
                  <option value="">Select Recipient's Department</option>
                  {departments &&
                    departments.map((el, index) => (
                      <option key={index} value={el.Department}>
                        {el.Department}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.department}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col mb-3">
                <Form.Label>Recipient :</Form.Label>
                <Form.Select
                  name="recipient"
                  value={formik.values.recipient}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.recipient && !!formik.errors.recipient
                  }
                  disabled={isLoadingUsers}
                >
                  <option value="">Select Recipient </option>
                  {users &&
                    users.map((el, index) => (
                      <Fragment key={index}>
                        {el.Staff_ID !== authUser.Staff_ID && (
                          <option value={el.Staff_ID}>{el.Name}</option>
                        )}
                      </Fragment>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.recipient}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Form.Group className="mb-3">
              <Form.Label>Comment :</Form.Label>
              <label className="border p-0 rounded pb-2 message-area d-block">
                <Form.Control
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  as="textarea"
                  className="w-100 border-0 p-3"
                  placeholder="Enter a comment"
                  rows={4}
                />
              </label>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span />
          <div className="proceed-actions">
            <Button
              disabled={createRequisitionMutation.isLoading}
              onClick={() => setShowNewRequisitionModal(false)}
              type="button"
              variant="white"
              className="border bg-white"
            >
              Cancel
            </Button>
            <Button
              disabled={createRequisitionMutation.isLoading}
              type="submit"
              variant="primary"
            >
              {createRequisitionMutation.isLoading
                ? "Please wait..."
                : "Send request"}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  ) : (
    <NewFundAndInventoryModal
      setShowNewRequisitionModal={setShowNewRequisitionModal}
      previousFormValues={formik.values}
    />
  );
}
