import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  convertMomentDate,
  customerFullName,
  employeeFullName,
  maxTopPopperConfig,
  reactSelectTheme,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import AccountPlusOutlineIcon from "mdi-react/AccountPlusOutlineIcon";
import { isEmpty } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, { useUpdateEffect } from "../../utils/hooks";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import { Popover } from "react-tiny-popover";
import AddCustomerItemModal from "./AddCustomerItemModal";
import moment, { isMoment } from "moment";
import { format } from "date-fns";
import CementEditRow from "./CementEditRow";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";

export default function CreateCustomersInvoice() {
  const { backendUrl } = useAuth();
  const itemOverlayRef = useRef();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer] = useState(null);

  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );

  const [tableData, setTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(0);
  const [editedSaleIndex, setEditedSaleIndex] = useState(0);

  const [salesForm, setSaleForm] = useState({});

  const driverType = [
    {
      label: "Self Collection",
      value: "Self Collection",
    },
    {
      label: "Direct Delivery",
      value: "Direct Delivery",
    },
  ];

  const createInvoice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createInvoiceMutation = useMutation(
    (payload) => createInvoice(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);

        formik.resetForm();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      /*  //  tax: "",
      taxType: "None",
      PaymentType: "Cash",
      proformaNumber: "",
      splitPaymentType: "",
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      dueIn: 1, */

      Quantity: 1,
      PriceSold: 0,
      Discount: 0,
      loadingDate: moment(),
      deliveryDate: moment(),
      salesDate: moment(),
      truckNumber: "",
      driver: "",
      shippingCost: "",
      loadingCharge: "",
      offloadingCharge: "",
    },
    validationSchema: yup.object().shape({
      // PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
      Quantity: yup
        .string()
        .required("required")
        .min(1),
      PriceSold: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      alert("fkj");
      /* tableData[editedItemIndex].sales[editedSaleIndex].Quantity =
        values.Quantity;
      setTableData([...tableData]);
      unsetActiveRow(); */
    },
    onReset: () => {},
  });

  const submit = () => {};

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));
    data.drivers = data.drivers
      .map((el) => ({
        label: employeeFullName(el),
        value: employeeFullName(el),
      }))
      .filter((el) => el.label);
    return data;
  };

  const { data = { invoiceCat: [], drivers: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const setSelectedCustomer = (customerData) => {
    // console.log(tableData[editedItemIndex], editedItemIndex);
    console.log(customerData);
    customerData.customerBalance = customerData.balance;
    delete customerData.balance;

    const oldCustomerInTable = tableData[editedItemIndex]
      ? tableData[editedItemIndex]?.sales.find(
          (el) => el.customer && el?.customer?.Cust_ID === customerData.Cust_ID
        )
      : null;
    if (!isEmpty(oldCustomerInTable)) {
      return toast.error("Customer has already been selected for this item");
    }
    tableData[editedItemIndex].sales[editedSaleIndex] = {
      ...tableData[editedItemIndex].item,
      ...tableData[editedItemIndex].values,
      ...tableData[editedItemIndex].sales[editedSaleIndex], // if user has values before retain
      customer: customerData,
    };
    setTableData([...tableData]);
  };

  const handleAddItem = (item) => {
    if (tableData.find((el) => el.item.Bar_Code === item.item.Bar_Code)) {
      return toast.error("Item already added");
    }
    const newTableData = [...tableData, item];
    setTableData(newTableData);
    setShowItemSelectorModal(false);

    handleAddCustomer(newTableData.length - 1, 0);
  };

  /* const handleEditItem = (item) => {
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );

    tableData[foundIndex] = item;
    setTableData([...tableData]);

    setEditedItemIndex(null);
  }; */

  // Mirror state

  const handleRemoveCustomer = (index, salesIndex) => {
    // Check stock

    setTableData([
      ...tableData.map((table, tableIndex) => {
        if (tableIndex === index) {
          return {
            ...table,
            sales: table.sales.filter((el, i) => i !== salesIndex),
          };
        } else {
          return table;
        }
      }),
    ]);
  };

  const handleRowClick = (e, index, salesIndex) => {
    if (e.target.tagName === "TD") {
      setActiveRow(index, salesIndex);
    }
  };

  const setActiveRow = (index, salesIndex) => {
    setEditedItemIndex(index);
    setEditedSaleIndex(salesIndex);
  };

  const unsetActiveRow = () => {
    setEditedItemIndex(null);
    setEditedSaleIndex(null);
  };

  const salesQuantities = useMemo(() => {
    const sums = tableData.map((el) => {
      const toSell = el.sales
        .map((el) => el.Quantity)
        .reduce(
          (a, b) =>
            currency(a, {
              symbol: "",
              precision: 1,
            })
              .add(b)
              .format(),
          0
        );

      const canSell = Boolean(
        Number(el.item.quantityInStock) >= Number(toSell)
      );

      return {
        canSell,
        toSell,
      };
    });
    return sums;
  }, [tableData]);

  /*  const populateForm = (index, salesIndex) => {
    const selectedItem = tableData[index].sales[salesIndex];
    console.log(selectedItem);
    if (selectedItem) {
      formik.setFieldValue("Quantity", selectedItem.Quantity);
      formik.setFieldValue("Discount", selectedItem.Discount);

      formik.setFieldValue("loadingDate", selectedItem.loadingDate);
      formik.setFieldValue("deliveryDate", selectedItem.deliveryDate);
      formik.setFieldValue("salesDate", selectedItem.salesDate);
    }
  }; */

  /* useEffect(() => {
    if (tableData.length) populateForm(editedItemIndex, editedSaleIndex);
  }, [formik, tableData, editedItemIndex, editedSaleIndex]);  */

  /*  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.Discount)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const subTotal = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                symbol: "",
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .add(discount)
          .format()
      : "0.00";
  }, [tableData, discount]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;

    const total = sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .multiply(100)
          .divide(100)
          .add(taxValue)
          .format()
      : "0.00";

    formik.setFieldValue("amountPaid", total);
    formik.setFieldValue("cashAmount", total);
    return total;
  }, [tableData, taxValue]);

  const balance = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? formik.values.cashAmount
          : formik.values.amountPaid
      )
      .format();
  }, [
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.Profit)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const grandTotal = useMemo(() => {
    return amountDue;
  }, [amountDue]); */

  const handleEditCustomer = (index, salesIndex) => {
    setActiveRow(index, salesIndex);
    setShowCustomerSelectorModal(true);
  };

  const handleAddCustomer = (index, salesIndex) => {
    setActiveRow(index, salesIndex);
    setShowCustomerSelectorModal(true);
  };

  const editSale = ({ index, salesIndex, formValues }) => {
    tableData[index].sales[salesIndex] = {
      ...tableData[index].sales[salesIndex],
      ...formValues,
    };

    setTableData([...tableData]);
  };

  const createCustomerInvoice = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/invoice/create-cement-invoice`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createCustomerInvoiceMutation = useMutation(
    (payload) => createCustomerInvoice(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setTableData([]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const canNotCreateInvoice = useMemo(() => {
    return salesQuantities.find((el) => !el.canSell);
  }, [tableData]);

  const sendInvoice = () => {
    if (canNotCreateInvoice) {
      return toast.error("Quantity entered is greater than quantity in Stock");
    }

    const payload = tableData
      .map((el) => el.sales)
      .flat()
      .map((el) => ({
        ...el,
        items: [{ ...el, salesDate: convertMomentDate(el.salesDate) }],
      }));

    if (payload.find((el) => Number(el.Quantity) <= 0)) {
      toast.error("Quantity cannot be less than or equal to 0");
    }

    createCustomerInvoiceMutation.mutate({
      customerSales: payload,
    });
  };

  return (
    <main className="create-invoice">
      <PageHeader
        name="ATC Distribution center"
        description="Create a new transaction invoice with your customers."
        icon={<CreateInvoiceIcon />}
      />
      <div className="p-3 content">
        <div className="d-flex content-holder rounded">
          <section className="item-details customers">
            <div>
              <header>
                <h1>Invoice</h1>
              </header>
              {/*   <div className="actions">
               <div>
                  <h2>Item Details</h2>
                  <p>Add items in to the invoice table. </p>
                </div> 
                <div>{"  "}</div>
              </div> */}

              <div className="selected-data-area mt-3">
                {tableData.map(({ item, sales }, index) => (
                  <Fragment key={item.Bar_Code}>
                    <h2 className="h2 mb-2">{item.Item_Name}</h2>
                    <div className="mb-3 d-flex gap-3">
                      <div>
                        <p>
                          ATC: <b> {item.Bar_Code}</b>
                        </p>
                      </div>

                      <div>
                        <p>
                          Quantity In Stock: <b>{item.quantityInStock}</b>
                        </p>
                      </div>

                      <div>
                        <p>
                          Quantity To Sell:{" "}
                          <b>{salesQuantities[index].toSell}</b>
                        </p>
                      </div>

                      <div>
                        {!isEmpty(salesQuantities) &&
                        !salesQuantities[index].canSell ? (
                          <p className="text-danger fw-5">
                            Quantity entered is greater than quantity in Stock{" "}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="table-holder">
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table text-nowrap"
                      >
                        <thead>
                          <tr>
                            <th />

                            <th>Customer</th>
                            <th>ATC</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <th>Location</th>
                            <th>Region</th>
                            <th>Stock In Date</th>
                            <th>Price Sold</th>

                            <th>Discount</th>
                            <th>Product name</th>
                            <th>Unit Price</th>
                            <th>Profit</th>
                            <th>Cost</th>
                            <th>Type</th>

                            <th>LoadingDate</th>
                            <th>Delivery Date</th>

                            <th>Delivery</th>
                            <th>Driver</th>
                            <th>Driver Phone Number</th>

                            <th>Truck No</th>
                            <th>Loading Charge</th>
                            <th>Offloading Charge</th>

                            <th>Shipping Cost</th>

                            <th>Sales Date</th>
                            <th>Proforma No</th>
                            <th>Tax Type</th>
                            <th>Tax Amount</th>
                            <th>Payment Type</th>
                            <th>Subtotal</th>
                            <th>Amount Due</th>
                            <th>Amount Paid</th>

                            <th>Balance</th>
                            <th>Due In (days)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sales.map((el, salesIndex) => (
                            <Fragment key={el.customer?.Cust_ID}>
                              <CementEditRow
                                sale={el}
                                drivers={data.drivers}
                                driverType={driverType}
                                index={index}
                                salesIndex={salesIndex}
                                handleEditCustomer={handleEditCustomer}
                                handleRemoveCustomer={handleRemoveCustomer}
                                editSale={editSale}
                              />
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-flex gap-3">
                      <button
                        onClick={() => handleAddCustomer(index, sales.length)}
                        className="btn text-primary my-3 d-flex align-items-center gap-2"
                        type="button"
                      >
                        <AccountPlusOutlineIcon />
                        Select Customer
                      </button>

                      <button
                        onClick={() => setShowCreateNewCustomerModal(true)}
                        className="btn text-primary my-3 d-flex align-items-center gap-2"
                        type="button"
                      >
                        Create New Customer
                      </button>
                    </div>
                    <hr />
                  </Fragment>
                ))}

                {/*  */}

                <button
                  onClick={() => setShowItemSelectorModal(true)}
                  type="button"
                  className="btn text-primary my-3 d-flex align-items-center gap-2"
                >
                  <AddCircleIcon />
                  Add an Item
                </button>

                {/*  No item  */}
                {isEmpty(tableData) ? (
                  <div className="no-item my-5">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">Haven't selected an item yet</h2>
                      <p>
                        You can click +Add Item Button to add an item to the
                        table.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {!isEmpty(tableData) && (
              <div className="d-flex justify-content-between total-info">
                {/*  <table className="table table-borderless balance">
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td />
                    </tr>

                    <tr>
                      <td>Discount</td>
                      <td>
                       
                      </td>
                    </tr>

                    <tr>
                      <td>Amount Due</td>
                      <td />
                    </tr>

                    <tr>
                      <td>Balance</td>
                      <td />
                    </tr>

                    <tr>
                      <td>Total</td>
                      <td />
                    </tr>
                  </tbody>
                </table> */}
                <div />
                <div>
                  <Button
                    onClick={() => sendInvoice()}
                    disabled={createCustomerInvoiceMutation.isLoading}
                    type="button"
                    className="py-3 px-5"
                  >
                    {createCustomerInvoiceMutation.isLoading
                      ? "Please wait..."
                      : " Post Invoice"}
                  </Button>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>

      {/*   Modals */}
      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          withCredit={true}
          // selectedCustomer={selectedCustomer}
        />
      )}

      {showItemSelectorModal && (
        <AddCustomerItemModal
          showItemSelectorModal={showItemSelectorModal}
          setShowItemSelectorModal={setShowItemSelectorModal}
          handleAddItem={handleAddItem}
          drivers={data.drivers}
          driverType={driverType}
          selectedItemsBarcodes={tableData
            .map((el) => el.item)
            .map((el) => el.Bar_Code)}
        />
      )}

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {/*
      {editedItemIndex !== null && (
        <EditItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItem={tableData[editedItemIndex]}
        />
      )} */}

      <ModalLoader show={createCustomerInvoiceMutation.isLoading} />
    </main>
  );
}
