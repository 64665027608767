export const ThankYouFeedBack = () => {
  const closeWindow = () => {
    // window.opener = null;
    // window.open("", "_self");
    // window.close();
    window.open("https://excellentbridge.com/", "_self");
  };

  return (
    <main
      className=""
      style={{
        background:
          "linear-gradient(to bottom, #2463ae 200px, #2463ae 200px, #2463ae)",
        height: "100vh",
      }}
    >
      <div
        className="container"
        style={{
          paddingTop: "10%",
          paddingBottom: "4rem",
        }}
      >
        <div className="card shadow p-4 w-75 mx-auto">
          <div className="card-body">
            {/*  */}
            <div className="text-center">
              <div>
                <h3>Awesome! &#128578; Thanks for Your FeedBack</h3>
              </div>
              <div className="my-4">
                <p style={{ fontSize: "16px" }}>
                  We are happy to hear from you.
                </p>
              </div>
              <button
                onClick={closeWindow}
                className="btn btn-primary btn-lg mt-4"
              >
                Done
              </button>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <div className="d-flex justify-content-between align-items-baseline">
          <div>
            <img src="/man.png" alt="man" className="img-fluid" />
          </div>
          <div>
            <img src="/people.png" alt="people" className="img-fluid" />
          </div>
          <div>
            <img src="/women.png" alt="women" className="img-fluid" />
          </div>
        </div>
      </div>
    </main>
  );
};
