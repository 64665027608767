import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import { ApproveBlueModalIcon } from "./Icons";
import { Table } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";

function Dialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  // under score is added to summary object keys, incase of duplicates
}) {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info">
          <ApproveBlueModalIcon className="mb-4" />
          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, index) => (
                  <tr key={index}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                              symbol: "",
                            }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            No
          </Button>
          <Button onClick={() => proceed(true)} variant="primary">
            Yes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default function ConfirmDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
