import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Card, Button, Nav, Dropdown } from "react-bootstrap";
import { NavLink, Outlet, Modal } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import NewRequisitionFormBuilder from "./NewRequisitionFormBuilder";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import { format, parse } from "date-fns";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { DeleteIcon, EditIcon } from "../Icons";
import NewCustomRequisitionModal from "./NewCustomRequisitionModal";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import ModalLoader from "../utils/ModalLoader";

export default function Send() {
  const initialFilterParams = {
    q: "",
  };
  const { backendUrl } = useAuth();
  const [template, setTemplate] = useState();
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [
    showNewCustomRequisitionModal,
    setShowNewCustomRequisitionModal,
  ] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 100,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchTemplates = async (queryParams) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/forms?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = {
      count: 0,
      templates: [],
    },
    refetch,
  } = useQuery(["FORM_TEMPLATES"], () => fetchTemplates(queryParams), {
    keepPreviousData: true,
  });

  const deleteTemplateApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/forms/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deleteMutation = useMutation((payload) => deleteTemplateApi(payload), {
    onSuccess: () => {
      toast.success(`Template Deleted`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });
  const deleteTemplate = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete this template",
      })
    ) {
      deleteMutation.mutate({
        id: el.id,
      });
    }
  };

  return (
    <>
      <div className="requisition-control-area d-flex">
        <div className="search-area">
          <MagnifyIcon />
          <Form.Control
            id="queryParams-q"
            className=""
            name="q"
            /*  value={queryParams.q}
            onChange={(e) => handleSearchQueryChange(e)} */
            placeholder="Search Title..."
          />
        </div>
        {/*   <Form.Select
          name="type"
          value={queryParams.type}
          onChange={(e) => handleSearchQueryChange(e)}
        >
          <option value="">Sort by Type</option>
          <option>Inventory</option>
          <option>Fund Request</option>
          <option>Normal</option>
          <option>Other</option>
        </Form.Select>

        <Form.Select
          name="status"
          value={queryParams.status}
          onChange={(e) => handleSearchQueryChange(e)}
        >
          <option value="">Sort by Status</option>
          <option>Pending</option>
          <option>Approved & Sent</option>
          <option>Approved & Closed</option>
          <option>Disapprove</option>
        </Form.Select>

        <Button
          onClick={() => refetch()}
          variant="outline-primary"
          className="border-0"
        >
          <CachedIcon />
        </Button> */}
      </div>

      <main className="cash-book">
        <div className="dashboard-tabs innerpage-tabs px-4">
          <ul className="nav nav-tabs">
            <Nav>
              <Nav.Link className={`nav-link ga-nav-tab`}>Draft</Nav.Link>
              <Nav.Link className={`nav-link ga-nav-tab active`}>
                Templates
              </Nav.Link>
            </Nav>
          </ul>
        </div>
        <div className="content">
          <header>
            <h1>...</h1>

            <button
              onClick={() => setShowNewRequisitionModal(true)}
              className="btn btn-primary new"
            >
              + New Requisition Form
            </button>
          </header>

          <div className="p-4">
            <div className="row gx-4 px-0">
              {data.templates.map((el, i) => (
                <div key={i} className="col-md-4 mb-4">
                  <Card>
                    <Card.Header className="bg-white border-0 p-4 pb-0 d-flex justify-content-between">
                      <span>
                        Created :{" "}
                        {el?.Date_Log
                          ? format(new Date(el.Date_Log), "dd/MM/yyyy")
                          : "..."}
                      </span>

                      <Dropdown style={{ margin: 0 }}>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-white border border-white"
                          bsPrefix="print more"
                        >
                          <DotsHorizontalIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="dropdown-with-icons"
                        >
                          {/* <Dropdown.Item
                                  as="button"
                                  onClick={() =>
                                    copyText(
                                      `${
                                        services.frontendUrl
                                      }/approval/details/${el.TransactionID}`
                                    )
                                  }
                                >
                                  <LinkVariantIcon />
                                  Copy Link
                                </Dropdown.Item> */}

                          {/*  <Dropdown.Item as="div">
                                  <Link
                                    to={`/approval/details/${el.TransactionID}`}
                                  >
                                    <DropdownEyeIcon /> View Details
                                  </Link>
                                </Dropdown.Item> */}
                          <Dropdown.Item as="button">
                            <EditIcon /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => deleteTemplate(el)}
                          >
                            <DeleteIcon /> Delete
                          </Dropdown.Item>
                          {/* 
                              <Dropdown.Item as="button" onClick={() => {}}>
                                <DropdownCheckIcon /> Appove
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                className="text-danger"
                                onClick={() => {}}
                              >
                                <DropdownCloseIcon /> Reject
                              </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Card.Header>
                    <Card.Body className="p-4">
                      {" "}
                      <Card.Subtitle className="mb-2 text-muted fw-normal">
                        {el.Type || "..."}
                      </Card.Subtitle>
                      <Card.Title className="fw-bolder">
                        {el.Title || "..."}
                      </Card.Title>
                    </Card.Body>
                    <Card.Footer className="bg-white border-top-0 p-4">
                      <Button
                        variant="outline-primary w-100 border-light py-2"
                        onClick={() => {
                          setTemplate(el.Template);
                          setShowNewCustomRequisitionModal(true);
                        }}
                      >
                        Use Form Template
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
              ))}
            </div>
            {/*   <div className="d-flex justify-content-between px-3 align-items-center pagination">
             
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                hrefBuilder={hrefBuilder}
                pageCount={data.count / queryParams.limit}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={handlePageChange}
                forcePage={queryParams.page - 1}
              />
            </div> */}
          </div>
        </div>
      </main>

      <Outlet />

      <NewRequisitionFormBuilder
        showNewRequisitionModal={showNewRequisitionModal}
        setShowNewRequisitionModal={setShowNewRequisitionModal}
        refetch={refetch}
      />

      {showNewCustomRequisitionModal && template ? (
        <NewCustomRequisitionModal
          template={template}
          setShowNewCustomRequisitionModal={setShowNewCustomRequisitionModal}
        />
      ) : null}

      <ModalLoader show={deleteMutation.isLoading} />
    </>
  );
}
