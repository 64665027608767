import currency from "currency.js";
import { format } from "date-fns";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
} from "../../utils/helpers";
import queryString from "query-string";
import ReactPaginate from "react-paginate";

export default function PurchaseHistorySelector({
  handleSelectedPurchaseHistory,
}) {
  const { backendUrl, isIronRod } = useAuth();
  const [searchString, setSearchString] = useState({
    invoiceNumberLike: "",
    page: 1,
    limit: 100,
  });
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const debouncedSearchString = useDebounce(searchString, 500);

  const fetchPurchaseHistory = async (debouncedSearchString) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/purchase-history?${queryString.stringify(
        debouncedSearchString
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { count: 0, purchaseHistory: [] } } = useQuery(
    [reportActions.PURCHASE_HISTORY, debouncedSearchString],
    () => fetchPurchaseHistory(debouncedSearchString),
    {
      keepPreviousData: true,
    }
  );
  return (
    <div className="items-table-area rounded">
      <h2 className="h5 p-3 pb-1 m-0">Purchase History</h2>
      <div className="search-area m-3">
        <MagnifyIcon />
        <input
          className="form-control search-input"
          name="q"
          value={searchString.invoiceNumberLike}
          onChange={(e) =>
            setSearchString({
              ...searchString,
              invoiceNumberLike: e.target.value,
            })
          }
          placeholder="Search Invoice number..."
          autoFocus
          autoComplete="off"
          type={"text"}
        />
      </div>

      <div className="content p-2 mb-3">
        <Table
          responsive
          borderless
          striped
          hover
          className="product-table text-nowrap"
        >
          <thead>
            <tr>
              <th>S/N</th>
              <th>Invoice No</th>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Vendor</th>
              <th>QTY Purchased</th>
              <th>Unit Cost</th>
              <th>Purchase Date</th>
              <th>Username</th>
              <th>Vendor ID</th>
            </tr>
          </thead>
          <tbody>
            {data?.purchaseHistory.map((el, index) => (
              <tr
                key={index}
                className="p-cursor"
                onClick={() => handleSelectedPurchaseHistory({ ...el })}
              >
                <td>{data?.startIndex + index + 1}</td>
                <td>{el.InvoiceNo}</td>
                <td>{el.Bar_Code}</td>
                <td>{el.ItemName}</td>
                <td>{el?.vendor?.CompanyName}</td>
                <td>
                  {isIronRod
                    ? qtyFormatToString(
                        qtyFormat(
                          el.Quantity,
                          el.item.Item_Desc,
                          itemMeasurements
                        )
                      )
                    : el.Quantity}
                </td>
                <td>{el.UnitPrice}</td>
                <td>
                  {el.PurchaseDate
                    ? format(new Date(el.PurchaseDate), "dd MMM yyyy")
                    : "..."}
                </td>
                <td>{el.Username}</td>
                <td>{el.Vendor_ID}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / searchString.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setSearchString({
              ...searchString,
              page: selected + 1,
            });
          }}
          forcePage={searchString.page - 1}
        />
      </div>
    </div>
  );
}
