import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  BookIcon,
  RecieptIcon,
  PageHeaderListIcon,
  ExcelIcon,
  PDFIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse, startOfMonth } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  formatDate,
  paginationOptions,
  reactSelectTheme,
  removeTimeZone,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CustomerSelector from "../utils/CustomerSelector";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty, lowerCase } from "lodash";
import printJS from "print-js";
import ModalLoader from "../utils/ModalLoader";
import { toast } from "react-toastify";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import TableComponent from "../TableComponent";
import VendorSelector from "./VendorSelector";

export default function AtcPaymentReport() {
  useScrollTop();

  const initialFilterParams = {
    Trans_ID: "",
    Vendor_ID: "",
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
    // requireCustomer: true,
  };

  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 100,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const navigate = useNavigate();

  const fetchVendorDuePayments = async (queryParams) => {
    let response = await fetch(
      `${backendUrl}/api/vendors/atc-payment-report?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = {
      count: 0,
      payments: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["ATC_PAYMENT_REPORT", queryParams],
    () => fetchVendorDuePayments(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/vendors/atc-payment-reports?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.payments.map((row) => ({
      ...row,
      Post_Time: format(new Date(row.Post_Time), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map((d) => [
      d.Post_Time,
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      d.Remark,
      d.Debit,
      d.Credit,
      d.Balance,
      d.Username,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Vendor Ledger Report"],
      [date],
      [""],
      [
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Transaction Remark",
        "Debit Amount",
        "Credit Amount",
        "Balance (LCY)",
        "Username",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Invoice Amount",
        currency(data?.totalInvoiceAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Payment Amount",
        currency(data?.totalPaymentAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data?.balance, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Invoice Amount",
        currency(data?.reversedInvoiceAmount, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Payment Amount",
        currency(data?.reversedPaymentAmount, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      Vendor_ID: customer.Vendor_ID,
      customerName: customer.CompanyName,
    });
    setShowItemsPopover(false);
  };

  const viewInvoice = (el) => {
    navigate(
      `/reports/purchase-history?invoiceNumber=${
        el.TrackID
      }&NoDate=${true}&CompName=${el.vendor.CompanyName}`
    );
  };

  /* const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th />
          <th>Ref No</th>
            <th>Due Date</th>
            <th>Tranche</th>
            <th>Amount</th>
            <th>Bank</th>
            <th>Trans Date</th>
            <th>Status</th>
        </tr>
      </thead>
    );
  };
  const tableData = (el, index) => {
    return (
      <Fragment>
        <td>
        </td>
        <td>{el.Trans_ID}
        </td>
        <td>{el.DueDate ? format(new Date(el.DueDate), 'dd MMM, yyyy') : '...'}
        </td> 
        <td>{el.Installment_Count}
        </td>
        <td>{ currency( el.AmountPaid, {symbol:""}).format() }
        </td>
        <td>{el.Bank}
        </td>
        <td>{el.Trans_Date ? format(new Date(el.Trans_Date), 'dd MMM, yyyy') : '...'}
        </td>
        <td>{el.Status}
        </td>
       
      </Fragment>
    );
  }; */

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                     <Form.Group className="mb-2-5">
                      <Form.Label>Vendor ID</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="Vendor_ID"
                          placeholder="Enter Vendor ID"
                          value={filterParams?.Vendor_ID}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <VendorSelector
                              handleSelectedCustomer={handleSelectedCustomer}
                              setShowCustomerSelector={setShowItemsPopover}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group> 

                    {/* <Form.Group className="mb-2-5">
                    <Form.Label>Vendor Name</Form.Label>
                    <Form.Control
                      name="customerName"
                      value={filterParams?.customerName}
                      placeholder="Enter Vendor Name"
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </Form.Group> */}

                    <Form.Group className="mb-2-5">
                      <Form.Label>Invoice No</Form.Label>
                      <Form.Control
                        name="Trans_ID"
                        value={filterParams?.Trans_ID}
                        onChange={(e) => handleFilterParamsChange(e)}
                        placeholder="Search..."
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label="Enable Date Range"
                        name="enableDateRange"
                        type={"checkbox"}
                        id={`enableDateRange`}
                        checked={filterParams?.enableDateRange}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  ATC Payment Report
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="fw-bold">
                  <h6>{filterParams?.customerName}</h6>
                </div>
                <div className="actions">
                  {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}
                  {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>
                <button className="btn print">
                  Export <ExportIcon />
                </button>

                <button className="btn print">
                  Open Receipt <BusinessBillIcon className="pageListIcon" />
                </button> */}

                  {/*  <a
                  href={`${backendUrl}/api/customers/pdf/ledger/${
                    filterParams?.Vendor_ID
                  }?${queryString.stringify(queryParams)}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn print"
                >
                  Print <PrintIcon />
                </a> */}
                  {/*  <CSVLink
                    className="btn print d-none"
                    filename={`Vendor Ledger(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  /> */}

                  {/*  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                      as="div"
                      onClick={() =>
                        !filterParams?.Vendor_ID
                          ? toast.error("Please select a customer")
                          : printJS({
                              printable: `${backendUrl}/api/customers/pdf/ledger/${
                                filterParams?.Vendor_ID
                              }?${queryString.stringify(queryParams)}`,
                              onLoadingStart: () => setLoadingPrint(true),
                              onLoadingEnd: () => setLoadingPrint(false),
                            })
                      }
                      className="p-cursor"
                    >
                      PDF <PDFIcon color="#ff0000" />
                    </Dropdown.Item> 
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <button
                    onClick={() => setBtnSummaryActive(true)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </header>

              <div className="px-md-4">
                {data.payments.map((payEl, index) => (
                  <div key={index}>
                    <h2 className="h5 text-center">{payEl.Trans_ID}</h2>
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table"
                    >
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account Type</th>
                          <th>Invoice</th>
                          <th>Payment Type</th>

                          <th>Debit </th>
                          <th>Credit</th>
                          <th>Balance</th>
                          <th>Username</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payEl.transactions.map((el, index) => (
                          <tr key={index}>
                            {/*  <td>{data?.startIndex + index + 1}</td> */}
                            <td>
                              {el.Post_Date
                                ? format(new Date(el.Post_Date), "dd MMM, yyyy")
                                : "..."}
                            </td>
                            <td>{el.DocType}</td>
                            <td>{el.Trans_ID}</td>
                            <td>{el.PaymentType}</td>
                            <td>
                              {currency(el.Debit, { symbol: "" }).format()}
                            </td>
                            <td>
                              {currency(el.Credit, { symbol: "" }).format()}
                            </td>
                            <td>
                              {/* {currency(el.Balance, { symbol: "" }).format()} */} -
                            </td>
                            <td>{el.Username}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr className="fw-bold bg-white border-black border-top border-bottom">
                          <td className="fw-bold">
                            {payEl.Post_Date
                              ? format(
                                  new Date(payEl.Post_Date),
                                  "dd MMM, yyyy"
                                )
                              : "..."}
                          </td>

                          <td>{"  "}</td>
                          <td colSpan={2} className="fw-bold">
                            Current Period Change
                          </td>

                          <td className="fw-bold">
                            {currency(payEl.totalDebit, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td className="fw-bold">
                            {currency(payEl.totalCredit, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td className="fw-bold">
                            {currency(payEl.balance, { symbol: "" }).format()}
                          </td>
                          <td>{"  "}</td>
                        </tr>

                        <tr className="fw-bold bg-white border-black border-top border-bottom">
                          <td className="fw-bold" />
                          <td>{"  "}</td>
                          <td colSpan={2} className="fw-bold">
                            Ending Balance
                          </td>
                          <td>{"  "}</td>
                          <td>{"  "}</td>
                          <td className="fw-bold">
                            {currency(payEl.endingbalance, { symbol: "" }).format()}
                          </td>
                          <td>{"  "}</td>
                        </tr>
                      </tfoot>
                    </Table>
                    <div className="my-5" />
                  </div>
                ))}
                {!isFetching && isSuccess && isEmpty(data?.payments) ? (
                  <div>
                    <NoTableItem queryParams={queryParams} />
                    <p className="text-center">{queryParams?.customerName}</p>
                  </div>
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="40">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>

              {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>218,134,177.92</td>
                </tr>

                <tr>
                  <td>Total Profit</td>
                  <td>9,276,961.63</td>
                </tr>

                <tr>
                  <td>Total Losses</td>
                  <td>N/A</td>
                </tr>

                <tr>
                  <td>Qty Sold</td>
                  <td>19884</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalDebit, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total  Debit</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalCredit, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total credit</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.balance, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Balance </p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalLoader show={loadingPrint || isfetchingExcel} />
      </main>
    </section>
  );
}
