import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Form } from "react-bootstrap";
import LineChart from "./LineChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function SalesOverView() {
  return (
    <div className="chart">
      <header className="section-header p-3 pe-4">
        <h2>Sales Overview</h2>

        <Form.Select aria-label="Default select example">
          <option value="1">This Year</option>
          <option value="1">This Month</option>
        </Form.Select>
      </header>

      <div className="chart-holder ps-3">
        <LineChart />
        <div className="blur" />
      </div>
    </div>
  );
}
