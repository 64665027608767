import { useState } from "react";
import NewItemModal from "./NewItemModal";
import NewItemServiceModal from "./NewItemServiceModal";

export default function NewItemModalWrapper({ ...props }) {
  const [itemType, setItemType] = useState("Inventory");

  return (
    <>
      {itemType === "Inventory" ? (
        <NewItemModal {...props} setItemType={setItemType} />
      ) : (
        <NewItemServiceModal {...props} setItemType={setItemType} />
      )}
    </>
  );
}
