import { Form } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function RevenueVsExpenses() {
  const { backendUrl } = useAuth();
  // const queryClient = useQueryClient();

  const getRevenue = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/dashboard/get-revenue?by=month`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        //  credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["GET_REVENUE"],
    () => getRevenue(),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <div className="chart">
      <header className="section-header p-3 pe-4">
        <h2>Total Revenue vs Expenses</h2>

        <Form.Select aria-label="Default select example">
          <option value="1">This Year</option>
          <option value="1">This Month</option>
        </Form.Select>
      </header>

      {/* bar chart */}

      <div className="chart-holder ps-3">
        {
          <Bar
            options={{
              //  responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    fontColor: "#94A3B8",
                    fontSize: 12,
                    boxHeight: 8,
                    boxWidth: 8,
                    pointStyle: "circle",
                    usePointStyle: true,
                  },
                },
                title: {
                  display: false,
                },
                tooltip: {
                  backgroundColor: "#00000080",
                  boxHeight: 8,
                  boxWidth: 8,
                  pointStyle: "circle",
                  usePointStyle: true,
                  boxPadding: 8,
                },
              },

              scales: {
                y: {
                  grid: {
                    color: "#F4F7FA",
                  },
                  ticks: {
                    color: "#94A3B8",
                    stepSize: 100000000,
                    fontSize: 12,
                  },
                },
                x: {
                  grid: {
                    color: "#fff",
                  },
                  ticks: {
                    color: "#94A3B8",
                    fontSize: 12,
                  },
                },
              },
            }}
            data={{
              labels: [...(isSuccess ? data.map((el) => el.date) : [])],
              datasets: [
                {
                  label: "Expenses",
                  data: [...(isSuccess ? data.map((el) => el.expenses) : [])],
                  backgroundColor: "#E0E0E0",
                  borderRadius: 100,
                  barThickness: 16,
                },
                {
                  label: "Revenue",
                  data: [...(isSuccess ? data.map((el) => el.revenue) : [])],
                  backgroundColor: "#2463AE",
                  borderRadius: 100,
                  barThickness: 16,
                },
              ],
            }}
          />
        }
        <div className="blur" />
      </div>
    </div>
  );
}
