// import { Pagination } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import { CSVLink } from "react-csv";

import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { ExcelIcon, PDFIcon } from "../Icons";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";

const months = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Date Range",
    label: "Date Range",
  },
  {
    value: "01-01",
    label: "January",
  },
  {
    value: "02-01",
    label: "February",
  },
  {
    value: "03-01",
    label: "March",
  },
  {
    value: "04-01",
    label: "April",
  },
  {
    value: "05-01",
    label: "May",
  },
  {
    value: "05-01",
    label: "June",
  },
  {
    value: "07-01",
    label: "July",
  },
  {
    value: "08-01",
    label: "August",
  },
  {
    value: "09-01",
    label: "September",
  },
  {
    value: "10-01",
    label: "October",
  },
  {
    value: "11-01",
    label: "November",
  },
  {
    value: "12-01",
    label: "December",
  },
];

function IncomeStatement() {
  useScrollTop();
  const initialFilterParams = {
    year: new Date().getFullYear(),
    month: "All",
    // startDate: "",
    // endDate: "",
  };

  const [disableDate, setDisableDate] = useState(null);
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const { backendUrl } = useAuth();

  const [queryParams, setQueryParams] = useQueryParams({
    // page: 1,
    // limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  useEffect(() => {
    filterParams?.startDate && filterParams?.endDate
      ? setDisableDate(false)
      : setDisableDate(true);
  }, [filterParams?.startDate, filterParams?.endDate]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/income-statement?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    // data.branch = [{ value: "All", label: "All" }];

    data.grossProfit = currency(data.salesRevenue, {
      symbol: "",
    })
      .add(data.otherIncome)
      .subtract(data.costOfgoodsold)
      .format();

    data.administrativeExpensesTotal = currency(
      Object.entries(data.administrativeExpenses).reduce(
        (a, c) => a + parseFloat(c[1]),
        0
      ),
      {
        symbol: "",
      }
    ).format();

    data.netIncome = currency(data.grossProfit, {
      symbol: "",
    })
      .subtract(data.administrativeExpensesTotal)
      .format();

    console.log(data);
    return data;
  };

  const {
    data = {
      count: 0,
      customerLedgers: [],
    },
    refetch,
    isFetching,
    // isSuccess,
  } = useQuery(
    ["INCOME_STATEMENT", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/income-statement?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const {
      company,
      salesRevenue,
      otherIncome,
      costOfgoodsold,
      administrativeExpenses,
    } = exData.data;

    const grossProfit = currency(salesRevenue, {
      symbol: "",
    })
      .add(otherIncome)
      .subtract(costOfgoodsold)
      .format();

    const administrativeExpensesTotal = currency(
      Object.entries(administrativeExpenses).reduce(
        (a, c) => a + parseFloat(c[1]),
        0
      ),
      {
        symbol: "",
      }
    ).format();

    const netIncome = currency(grossProfit, {
      symbol: "",
    })
      .subtract(administrativeExpensesTotal)
      .format();

    const admins = Object.entries(administrativeExpenses).map((a, i) => [
      a[0],
      a[1],
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company[0]?.CompName],
      ["Income Statement Report"],
      [date],
      [""],
      ["REVENUE"],
      [""],
      [
        "Sales Revenue",
        currency(salesRevenue, {
          symbol: "",
        })
          .subtract(otherIncome)
          .format(),
      ],
      ["Cost Of Goods Sold", costOfgoodsold],
      ["Other Income", otherIncome],
      [""],
      ["Gross Profit", grossProfit],
      [""],
      [""],
      ["Administrative Expenses"],
      ...admins,
      [""],
      ["Total Expenses", administrativeExpensesTotal],
      [""],
      [""],
      ["Net Income", netIncome],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const filterByPeroid = (e) => {
    const selected = e.target;
    if (selected.value === "Date Range") {
      setDisableDate(false);
      setFilterParams({
        ...filterParams,
        month: "",
        startDate: format(new Date(), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
      });
    } else {
      setFilterParams({
        ...filterParams,
        month: selected.value,
        startDate: "",
        endDate: "",
      });
      setDisableDate(true);
    }
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  // const clearDateRange = () => {
  //   setFilterParams({
  //     ...filterParams,
  //     startDate: "",
  //     endDate: "",
  //   });
  // };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <main className="cash-book">
      <div className="content">
        <header>
          <h1>
            Income Statement
            <button
              title="Refresh"
              onClick={() => refetch()}
              className="btn text-primary"
            >
              <CachedIcon />
            </button>
          </h1>
          <div className="actions">
            <div className="d-flex align-items-center text-nowrap">
              <label htmlFor="months" className="mx-2 fw-bold">
                Accounting Period:
              </label>

              <select
                id="months"
                className="form-select"
                aria-label="Default select example"
                onChange={filterByPeroid}
                value={
                  queryParams?.month === "" ? "Date Range" : queryParams?.month
                }
              >
                {months.map((d) => (
                  <option value={d.value}>{d.label}</option>
                ))}
              </select>
            </div>
            <div className="">
              <div className="position-relative">
                <RsDateRangePicker
                  disabled={disableDate}
                  placement="bottomStart"
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                          parse(
                            filterParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  // onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>
            </div>

            <div className="d-flex align-items-center text-nowrap">
              <label htmlFor="years" className="mx-2 fw-bold">
                Accounting Year:
              </label>
              <input
                name="year"
                value={filterParams?.year}
                onChange={(e) => handleFilterParamsChange(e)}
                type="number"
                className="form-control smallwidth"
                id="years"
              />
            </div>
            <CSVLink
              className="btn print d-none"
              filename={`Income Statement Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            />
            <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/income-statement?${queryString.stringify(
                rest
              )}`}
              className="btn print income-statement-excel"
            >
              PDF <PDFIcon color="#ff0000" />
            </a>
            <button className="btn print mx-4" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button>
          </div>
        </header>

        <div className="px-4 my-4">
          {/* REVENUE */}
          <div>
            <div className="fw-bold p-2 mb-2 bg-low-blue text-white">
              <p>REVENUE</p>
            </div>
            <div>
              <div className="container px-5">
                <div className="d-flex justify-content-between">
                  <p className="fs-6 mb-1">Sales Revenue</p>
                  <p className="fs-6 mb-1">
                    {data?.salesRevenue &&
                      // data.otherIncome &&
                      currency(data.salesRevenue, {
                        symbol: "",
                      })
                        // .subtract(data.otherIncome)
                        .format()}
                  </p>
                </div>
                <div>
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Cost Of Goods Sold</p>
                    <p className="fs-6 mb-1">
                      {currency(data?.costOfgoodsold, {
                        symbol: "",
                      }).format()}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="fs-6 mb-1">Other Income</p>
                    <p className="fs-6 mb-1">
                      {currency(data?.otherIncome, {
                        symbol: "",
                      }).format()}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="fw-bold p-1 mt-3 bg-primary text-white">
              <div className="d-flex justify-content-between align-items-center container px-5">
                <p className="fs-6">Gross Profit</p>
                <p className="fs-6">
                  {currency(data?.grossProfit, {
                    symbol: "",
                  }).format()}
                </p>
              </div>
            </div>
          </div>

          {/* Administrative Expenses */}
          <div className="mt-5">
            <div className="fw-bold p-2 mb-2 bg-low-blue text-white">
              <p>Administrative Expenses</p>
            </div>
            <div>
              <div className="container px-5">
                {data?.administrativeExpenses &&
                  Object.entries(data.administrativeExpenses).map((a, i) => (
                    <div className="d-flex justify-content-between" key={i}>
                      <p className="fs-6 mb-1">{a[0]} </p>
                      <p className="fs-6 mb-1">
                        {currency(a[1], {
                          symbol: "",
                        }).format()}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="fw-bold p-1 mt-3 bg-primary text-white">
              <div className="d-flex justify-content-between container px-5">
                <p className="fs-6">Total Expenses</p>
                <p className="fs-6">
                  {currency(data?.administrativeExpensesTotal, {
                    symbol: "",
                  }).format()}
                </p>
              </div>
            </div>
          </div>

          <div className="fw-bold p-1 mt-3 bg-primary text-white">
            <div className="d-flex justify-content-between container px-5">
              <p className="fs-6">Net Income</p>
              <p className="fs-6">
                {currency(data?.netIncome, {
                  symbol: "",
                }).format()}
              </p>
            </div>
          </div>
        </div>

        {/* <div className="pagination">
        
          <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div>

      
          <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div>
        </div> */}
      </div>
      <ModalLoader show={isFetching || isfetchingExcel} />
    </main>
  );
}

export default IncomeStatement;
