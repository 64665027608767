import { useFormik } from "formik";
import { Modal, Button, Form } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import DateTime from "react-datetime";
import FormBuilderModal from "./FormBuilderModal";
import { useState } from "react";
import moment from "moment";
import { useAuth } from "../../hooks/useAuth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import ModalLoader from "../utils/ModalLoader";

export default function NewRequisitionFormBuilder({
  showNewRequisitionModal,
  setShowNewRequisitionModal,
  refetch,
}) {
  const queryClient = useQueryClient();
  const { backendUrl, token } = useAuth();
  const [showFormBuilderModal, setShowFormBuilderModal] = useState(false);

  const setup = async () => {
    let response = await fetch(`${backendUrl}/api/forms/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = {
      formCategory: [],
      formType: [],
    },
    // refetch,
  } = useQuery(["FORM_SETUP"], () => setup(), {
    keepPreviousData: true,
  });

  const createTemplate = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    // TODO - append files

    let response = await fetch(`${backendUrl}/api/forms/create`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createMutation = useMutation((payload) => createTemplate(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      refetch();
      setShowNewRequisitionModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      Title: "",
      Date_Log: moment(),
      Type: "",
      Category: "",
      Template: "",
      // Signatories: [],
    },
    onSubmit: (values) => {
      createMutation.mutate({ ...values });
    },
  });

  //
  const saveForm = (data) => {
    formik.setFieldValue("Template", data);
    setShowFormBuilderModal(false);
    // setShowNewRequisitionModal(false);
    //  formik.submitForm();
  };

  const handleCreateType = (newType) => {
    queryClient.setQueryData(["FORM_SETUP"], (data) => {
      data.formType = [...data.formType, { label: newType, value: newType }];
      return data;
    });
    formik.setFieldValue("Type", newType);
  };

  const handleCreateCategory = (newCategory) => {
    queryClient.setQueryData(["FORM_SETUP"], (data) => {
      data.formCategory = [
        ...data.formCategory,
        { label: newCategory, value: newCategory },
      ];
      return data;
    });
    formik.setFieldValue("Category", newCategory);
  };

  return (
    <>
      <Modal
        show={showNewRequisitionModal}
        backdrop="static"
        animation={false}
        onHide={() => setShowNewRequisitionModal(false)}
      >
        <Modal.Header closeButton>
          <div>
            <h1 className="h6">Create New Requisition</h1>{" "}
            <p>Build your forms correctly and as needed</p>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Title</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Title"
                name="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Title && !!formik.errors.Title}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Date</Form.Label>
              <DateTime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.Date_Log}
                onChange={(date) => {
                  formik.setFieldValue("Date_Log", date, true);
                }}
              />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Type</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.formType}
                value={data.formType.find(
                  (el) => el.value === formik.values.Type
                )}
                onChange={({ value }) => formik.setFieldValue("Type", value)}
                isSearchable
                onCreateOption={handleCreateType}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Category</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.formCategory}
                value={data.formCategory.find(
                  (el) => el.value === formik.values.Category
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Category", value)
                }
                isSearchable
                onCreateOption={handleCreateCategory}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => setShowFormBuilderModal(true)}
            className="px-4"
            variant="outline-primary"
          >
            Create Form
          </Button>

          <Button onClick={() => formik.submitForm()} className="px-4">
            Post
          </Button>
        </Modal.Footer>
      </Modal>

      {showFormBuilderModal && (
        <FormBuilderModal
          showFormBuilderModal={showFormBuilderModal}
          setShowFormBuilderModal={setShowFormBuilderModal}
          saveForm={saveForm}
        />
      )}

      <ModalLoader show={createMutation.isLoading} />
    </>
  );
}
