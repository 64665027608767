import { Table, Pagination, Button } from "react-bootstrap";
import { CalendarIcon, NoSelectedItemIcon, PrintIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { reportActions } from "../../utils/reactQueryActions";
import { useQueryParams, useScrollTop } from "../../utils/hooks";
import queryString from "query-string";
import { services } from "../../config";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import AccountTransferModal from "../SalesAndInvoicing/AccountTransferModal";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import AddAccounts from "../modals/AddAccounts";

function CreateNewAccount({ refetch, usage }) {
  const [showCreateNewAccountModal, setShowCreateNewAccountModal] = useState(
    false
  );
  return (
    <>
      <Button
        onClick={() => setShowCreateNewAccountModal(true)}
        className="px-3"
        variant="outline-primary"
      >
        Create New
      </Button>
      {showCreateNewAccountModal && (
        <AddAccounts
          show={showCreateNewAccountModal}
          onHide={() => setShowCreateNewAccountModal(false)}
          refetch={() => refetch()}
          usage={usage}
        />
      )}
    </>
  );
}

export default function Cashes() {
  const { backendUrl } = useAuth();
  useScrollTop();
  const [isAccountTransferOpen, setIsAccountTransferOpen] = useState(false);
  const [btnSummaryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const initialFilterParams = {
    // startDate: format(new Date(), "yyyy-MM-dd"),
    //  endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const fetchCashbook = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal/cashes-report?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { cash: [], bank: [], imprest: [] },
    refetch,
    isFetching,
  } = useQuery(
    [reportActions.CASH_BOOK, queryParams],
    () => fetchCashbook(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const cashEndingBalance = useMemo(() => {
    const total = data?.cash
      .map((el) => el.RunningBalanceCalculated)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );

    return currency(total, {
      symbol: "",
      separator: "",
    }).format();
  }, [data?.cash]);

  const bankEndingBalance = useMemo(() => {
    const total = data?.bank
      .map((el) => el.RunningBalanceCalculated)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );

    return currency(total, {
      symbol: "",
      separator: "",
    }).format();
  }, [data?.bank]);

  const imprestEndingBalance = useMemo(() => {
    const total = data?.imprest
      .map((el) => el.RunningBalanceCalculated)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );

    return currency(total, {
      symbol: "",
      separator: "",
    }).format();
  }, [data?.imprest]);

  //------------

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <main className="cash-book">
      <div className="content">
        <header>
          <h1>
            Cash/Bank Balances
            <button onClick={() => refetch()} className="btn text-primary">
              <CachedIcon />
            </button>
          </h1>
          <div className="actions mr-5">
            {/*  <DateRangePicker
              apply={(date) => filterByDateRange(date)}
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
            /> */}
            <RsDateRangePicker
              placement="bottomEnd"
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />

            <button
              className="btn btn-primary"
              onClick={() => setIsAccountTransferOpen(true)}
            >
              Cash Transfer and Lodgement
            </button>
            {/*  <button
              onClick={() => setBtnSummaryActive(!btnSummaryActive)}
              className="btn summaryBtn "
            >
              Show Summary
            </button> */}
          </div>
        </header>

        <div className="px-md-4" />
        <div
          className={
            btnSummaryActive
              ? `summaryNew -border-top open`
              : `summaryNew -border-top`
          }
        >
          <div className="summaryHeader">
            {/*  <h2>Summary</h2> */}
            <div />
            {btnSummaryActive && (
              <button onClick={() => close()} className="btn p-0">
                <SummaryCloseIcon />
              </button>
            )}
          </div>

          <div className="row justify-content-between">
            <div className="col">
              <div className="mb-3">
                <div className="d-flex gap-3 align-items-center mb-4">
                  <h2 className="m-0">Cash on hand</h2>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                <div
                  className="gridCont mb-3"
                  style={{ gridTemplateRows: "unset" }}
                >
                  {data.cash.map((el, index) => (
                    <Link
                      to={`/maintenance/account-list?&Description=${
                        el.Description
                      }&AccountID=${el.AccountID}`}
                      key={index}
                      className="gridChild gridChildBorderLeftDarkPurple"
                    >
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">
                          {el.Description === "Cash on hand"
                            ? "Cash on hand - Ending balance"
                            : el.Description}
                        </p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </Link>
                  ))}
                </div>

                {isEmpty(data.cash) && !isFetching ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <NoSelectedItemIcon />
                      <h2 className="m-3">None Found</h2>
                      <CreateNewAccount
                        refetch={refetch}
                        usage={"cash on hand"}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="w-100 d-flex align-items-center justify-content-end my-3 mb-5 p-3 gap-4">
                  <h2 className="m-0">Ending Balance:</h2>
                  <div className="border-top border-bottom p-3">
                    <p className="h5 m-0 pe-4">
                      {currency(cashEndingBalance, {
                        symbol: "",
                      }).format()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="d-flex gap-3 align-items-center mb-4">
                  <h2 className="m-0">Imprest</h2>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                <div
                  className="gridCont mb-3"
                  style={{ gridTemplateRows: "unset" }}
                >
                  {data.imprest.map((el, index) => (
                    <Link
                      to={`/maintenance/account-list?&Description=${
                        el.Description
                      }&AccountID=${el.AccountID}`}
                      key={index}
                      className="gridChild gridChildBorderLeftLightYellow"
                    >
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">{el.Description}</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </Link>
                  ))}
                </div>

                {isEmpty(data.imprest) && !isFetching ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <NoSelectedItemIcon />
                      <h2 className="m-3">None Found</h2>
                      <CreateNewAccount refetch={refetch} usage={"imprest"} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="w-100 d-flex align-items-center justify-content-end my-3 mb-5 p-3 gap-4">
                  <h2 className="m-0">Ending Balance:</h2>
                  <div className="border-top border-bottom p-3">
                    <p className="h5 m-0 pe-4">
                      {currency(imprestEndingBalance, {
                        symbol: "",
                      }).format()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="d-flex gap-3 align-items-center mb-4">
                  <h2 className="m-0">Bank</h2>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                <div
                  className="gridCont mb-3"
                  style={{ gridTemplateRows: "unset" }}
                >
                  {data.bank.map((el, index) => (
                    <Link
                      to={`/maintenance/account-list?&Description=${
                        el.Description
                      }&AccountID=${el.AccountID}`}
                      key={index}
                      className="gridChild gridChildBorderLeftOrange"
                    >
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(el.RunningBalanceCalculated, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">{el.Description}</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </Link>
                  ))}
                </div>

                {isEmpty(data.bank) && !isFetching ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <NoSelectedItemIcon />
                      <h2 className="m-3">None Found</h2>
                      <CreateNewAccount refetch={refetch} usage={"bank"} />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="w-100 d-flex align-items-center justify-content-end my-3 mb-5 p-3 gap-4">
                  <h2 className="m-0">Ending Balance:</h2>
                  <div className="border-top border-bottom p-3">
                    <p className="h5 m-0 pe-4">
                      {currency(bankEndingBalance, {
                        symbol: "",
                      }).format()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isAccountTransferOpen && (
        <AccountTransferModal
          setShowAccountTransferModal={setIsAccountTransferOpen}
          refetch={refetch}
        />
      )}
    </main>
  );
}
