import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { backendApis, services } from "../config";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import {
  useBackendUrl,
  useIsAdmin,
  useLocalStorage,
  useToken,
} from "../utils/hooks";
import { LockIcon } from "./Icons";

function Login({
  proceed,
  show,
  title = "Admin Authentication",
  loginRequired = false,
  description = "",
}) {
  const backendUrl = useBackendUrl();
  const isAdmin = useIsAdmin();
  const token = useToken();

  if (isAdmin && !loginRequired) {
    proceed(true);
  }

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { username: "", password: "" };
  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const loginUser = async (values) => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/auth/admin-authenticate`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...values }),
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        if (response.errors) formik.setErrors(response.errors);
        toast.error(response.message);
      } else {
        toast.success("Authenticated");
        proceed(true);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to submit");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      loginUser(values);
    },
  });
  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6 d-flex gap-2 m-0 align-items-center">
            <LockIcon /> {title}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">{description}</p>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              isInvalid={formik.touched.username && !!formik.errors.username}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.username}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="••••••••"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              isInvalid={formik.touched.password && !!formik.errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Continue"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function LoginDialog(props) {
  return createConfirmation(confirmable(Login))(props);
}
