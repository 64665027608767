import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { vendorManagement } from "../../config";

export function VendorsInventoryNav(props) {
  const items = useMemo(() => {
    return vendorManagement.map((d) => ({
      name: d.name,
      to: d.to.split("/")[1],
    }));
  }, []);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            {Object.entries(item).length > 0 && (
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
