import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../Icons";
import NavBar from "../NavBar";

export function UnAuthorized() {
  const navigate = useNavigate();
  return (
    <>
      {/*  <NavBar /> */}
      <div className="vh-100 vw-100 d-flex align-items-center justify-content-center text-center">
        <div>
          <Logo />
          <h1 className="display-5">Admin Only</h1>{" "}
          <p className="">Unauthorized</p>
          <Button
            onClick={() => navigate(-1)}
            variant=""
            className="text-primary text-underline"
          >
            Go back
          </Button>
        </div>
      </div>
    </>
  );
}
