import { Table, Form, Button, Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import queryString from "query-string";
import { toast } from "react-toastify";
import { Popover } from "react-tiny-popover";

import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { reportActions } from "../../utils/reactQueryActions";
import {
  ExportIcon,
  FilterTwoIcon,
  FilterCollapseIcon,
  AddCircleIcon,
  CirclesFourIcon,
  TagSimpleIcon,
  DotSpinner,
  SearchIcon,
  NoSelectedItemIcon,
} from "../Icons";
import "../../assets/scss/reports/cashbook.scss";
import { paginationOptions } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import ViewInventryTransit from "../modals/ViewInventryTransit";
import { fetchActionsUtil } from "../../utils/helpers";
import { CustomInput } from "../utils/CustomInput";
import ConfirmDialog from "../ConfirmDialogue";
import TableComponent from "../TableComponent";
import WarehouseItemTable from "./WarehouseItemTable";
import { AddWarehouseMaintanceModal } from "../modals/AddWarehouseMaintance";
import { ViewMaintanceDetailsModal } from "../modals/ViewMaintanceDetails";

const allStatus = [
  "All",
  "Rejected",
  "Partly Received",
  "Received All",
  "Retured",
  null,
];

export function WarehouseMaintance() {
  useScrollTop();
  const initialFilterParams = {
    barCode: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
  };

  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", false);
  const [issueItemModalShow, setIssueItem] = useState(false);
  const [viewItemModalShow, setViewItem] = useState(false);
  //   const [allIsuesItems, setAllIsuesItems] = useState([]);
  const [selectCompany, setSelectCompany] = useState("All");
  const [loadingId, setLoadingId] = useState();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showStatus, setShowStatus] = useState("All");
  const [viewMaintanceDetails, setViewMaintanceDetails] = useState(false);
  const [maintaince, setMaintaince] = useState({});

  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const fetchSetUpData = async (queryParams, ware) => {
    let response = await fetch(
      `${backendUrl}/api/warehouse/issuer-setup/${ware}?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    const warehousesData = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/categories/${wareshouseLocal?.cater}`,
      "GET"
    );

    const maintainceData = await fetchActionsUtil(
      `${backendUrl}/api/maintance`,
      "GET"
    );

    data.staff = data.staffs.map((el) => ({
      value: el.Staff_ID,
      label: el.Name,
    }));

    data.vendors = data.vendors.map((el) => ({
      value: el.CompanyName,
      label: el.CompanyName,
    }));

    data.warehouses = warehousesData?.warehouses
      ?.filter((e) => e.W_ID !== wareshouseLocal?.whID)
      ?.map((el) => ({
        value: el.W_ID,
        label: el.W_name,
      }));

    // data.MaintenanceExpenses = maintainceData.data.MaintenanceExpenses;

    data.maintainStatus = maintainceData.data.maintainStatus;

    // data.maintaince = maintainceData.data.maintaince;
    data.maintaince = maintainceData.data.maintaince?.filter(
      (e) => e.warehouse === wareshouseLocal?.whID
    );

    // console.log(data);

    return data;
  };

  const {
    data = { countItemsIssueLog: 0, staff: [], requesters: [] },
    refetch,
  } = useQuery(
    ["WAREHOUSE_MAINTANCE", { queryParams, wareshouseLocal }],
    () => fetchSetUpData(queryParams, wareshouseLocal?.whID),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  // const returnItemMutation = useMutation(
  //   (payload) =>
  //     fetchActionsUtil(`${backendUrl}/api/pending/return`, "POST", "", payload),
  //   {
  //     onSuccess: ({ message, data }) => {
  //       queryClient.setQueryData(
  //         [reportActions.SALE_BY_INVOICE, queryParams],
  //         (oldData) => {
  //           console.log("oldData => ", oldData.issuedItems);
  //           oldData = data;
  //           return oldData;
  //         }
  //       );
  //       toast.success(message);
  //     },
  //     onError: () => {
  //       toast.error(`Unable to perform action`);
  //     },
  //   }
  // );
  const handleSelectedItem = (el) => {
    setFilterParams({
      ...filterParams,
      barCode: el.Bar_Code,
    });
    setShowItemsPopover(false);
  };

  //   const onSelect = (value) => {
  //     setSelectCompany(value);
  //     console.log(value);
  //     const filter = data?.issuedItems.filter((d) => d.Requester === value);

  //     if (value === "All") {
  //       setAllIsuesItems(() => data.issuedItems);
  //     } else {
  //       setAllIsuesItems(() => filter);
  //     }
  //   };

  //   const onStatusSelect = (value) => {
  //     setShowStatus(value);
  //     console.log(value);

  //     const filter =
  //       value !== "Pending"
  //         ? data?.issuedItems.filter((d) => d.Status === value)
  //         : data?.issuedItems.filter((d) => d.Status === null);

  //     if (value === "All") {
  //       setAllIsuesItems(() => data.issuedItems);
  //     } else {
  //       setAllIsuesItems(() => filter);
  //     }
  //   };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const displayTableData = (el) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
              style={{ height: "5px" }}
            >
              <DotsHorizontalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setMaintaince(el);
                  setViewMaintanceDetails(true);
                }}
              >
                <TagSimpleIcon className="text-light" />
                Open
              </Dropdown.Item>

              {/* <Dropdown.Item
                as="button"
                // onClick={() => handleDeleteItem(el)}
              >
                <CirclesFourIcon />
                Move to Damages
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{el?.Serial_Number}</td>
        <td>{el?.Item_Name}</td>
        <td>{el?.Type}</td>
        <td className="text-center">{el?.Total_Cost}</td>
        <td className="text-nowrap">
          {format(new Date(el?.new_Maintain_Date), "MMM dd, yyyy")}
        </td>
        <td className="text-center">{el?.WH_New_Certified_By?.Name}</td>
        <td>{el?.New_Maintain_By}</td>
        <td>
          <div
            className={`px-2 ${
              el.Status === "Rejected"
                ? "view_status_reject"
                : el.Status === "Pending Approval"
                ? "view_status_partly"
                : el.Status === "Approve"
                ? "view_status_all"
                : el.Status === "Partial Approve"
                ? "view_status_pending"
                : ""
            }`}
          >
            {el.Status !== null ? el.Status : "Pending"}
          </div>
        </td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>Serial Number</th>
          <th>Item Name</th>
          <th className="text-center">Type</th>
          <th>Total Cost</th>
          <th>Maintain On</th>
          <th>Certified By</th>
          <th>Maintained By</th>
          <th className="text-center">Status</th>
        </tr>
      </thead>
    );
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Bar Code</Form.Label>
                      <div className="input-group mb-3">
                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <WarehouseItemTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <>
                            <CustomInput
                              className="p-cursor"
                              name="itemId"
                              placeholder="Bar Code"
                              typeOfInput="text"
                              value={filterParams?.barCode}
                              disabled
                              onChange={(e) => handleFilterParamsChange(e)}
                            />

                            <span
                              className="input-group-text border p-cursor"
                              id="basic-addon1"
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <SearchIcon />
                            </span>
                          </>
                        </Popover>
                      </div>
                    </Form.Group>

                    <hr className="mt-3 mb-4" />

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label="Enable Date Range"
                        name="enableDateRange"
                        type={"checkbox"}
                        id={`enableDateRange`}
                        defaultChecked={JSON.parse(
                          filterParams?.enableDateRange || false
                        )}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams?.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header className="text-nowrap">
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Maintance Report
                  <button
                    title="Refresh"
                    onClick={() => {
                      refetch();
                    }}
                    className="btn text-primary mx-3"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions">
                  <div
                    className="btn btn-primary"
                    onClick={() => setIssueItem(true)}
                  >
                    Add Maintance
                    <AddCircleIcon color="#FFFFFF" width="" height="" />
                  </div>

                  <div className="btn bg print">
                    Export <ExportIcon className="px-2" />
                  </div>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={data?.maintaince}
                  tableDataRowFunction={displayTableData}
                  className="product-table text-nowrap"
                />
              </div>

              {data?.maintaince?.length < 1 && (
                <div className="d-flex justify-content-center">
                  <div className="info text-center">
                    <NoSelectedItemIcon />
                    <h2 className="my-2">No Item Available</h2>
                    <p />
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(
                    data.countItemsIssueLog / queryParams.limit
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={parseInt(queryParams.page) - 1}
                />
              </div>
            </div>
          </div>
        </div>
        <AddWarehouseMaintanceModal
          show={issueItemModalShow}
          onHide={() => setIssueItem(false)}
          staffs={data.staff}
          refetchMain={refetch}
          warehouses={data.warehouses}
          vendors={data.vendors}
        />

        <ViewMaintanceDetailsModal
          show={viewMaintanceDetails}
          onHide={() => setViewMaintanceDetails(false)}
          Maintenance={maintaince}
          warehouses={data.warehouses}
        />
        <ViewInventryTransit
          show={viewItemModalShow}
          onHide={() => setViewItem(false)}
        />
      </main>
    </section>
  );
}
