import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, { useBackendUrl, useEffectOnce } from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function GeneralSettingsModal({
  showGeneralSettingsModal,
  setShowGeneralSettingsModal,
}) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );

  const formik = useFormik({
    initialValues: {
      convertToTonsAndPcs: false,
    },
    onSubmit: (values) => {
      setGeneralSettings(values);
      toast.success("Settings saved");
      setShowGeneralSettingsModal(false);
    },
  });

  useEffectOnce(() => {
    formik.setValues(generalSettings);
  });
  return (
    <>
      <Modal
        size="md"
        show={showGeneralSettingsModal}
        onHide={() => setShowGeneralSettingsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
            <Form.Group>
              <Form.Check
                inline
                label="Convert Quantity to Tons and pcs"
                name="convertToTonsAndPcs"
                type={"checkbox"}
                checked={formik.values.convertToTonsAndPcs}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => formik.submitForm()} variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
