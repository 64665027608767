import currency from "currency.js";
import { useQuery } from "react-query";
import { Outlet } from "react-router-dom";
import {
  LineChartIcon,
  PageHeaderListIcon,
  CurrencyFillIcon,
  GroupUsers,
} from "../../components/Icons";
import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import { useIsSales } from "../../utils/hooks";
import "./../../assets/scss/invoicewrapper.scss";

export function ProductWrapper({ Nav }) {
  const { backendUrl } = useAuth();
  const isSales = useIsSales();

  const getStats = async () => {
    const data = await fetchActionsUtil(
      `${backendUrl}/api/production/statistics`,
      "GET"
    );
    // , ,
    return data;
  };

  const { data } = useQuery(["PRODUCTION_STAT"], () => getStats(), {});
  //   console.log(data);
  return (
    <div className="invoice-wrapper">
      <section className="stats mb-3">
        <div className="stat">
          <div className="icon bg-light-blue">
            <GroupUsers color="#4169E1" />
          </div>
          <div className="details">
            <p className="text-center">{data?.totalProduce}</p>
            <span>Total Produce</span>
          </div>
        </div>

        {!isSales ? (
          <>
            <div className="stat">
              <div className="icon bg-light-blue">
                <PageHeaderListIcon />
              </div>
              <div className="details">
                <p className="text-center">
                  {/* {currency(data?.totalStock, {
                    symbol: "₦",
                  }).format()} */}
                  {data?.totalStock}
                </p>
                <span>Total In Stock</span>
              </div>
            </div>

            <div className="stat">
              <div className="icon bg-light-blue">
                <CurrencyFillIcon />
              </div>
              <div className="details">
                <p className="text-center">
                  {currency(data?.valueComsumed, {
                    symbol: "₦",
                  }).format()}
                </p>
                <span>Total Value Comsumed</span>
              </div>
            </div>
          </>
        ) : null}

        <div className="stat">
          <div className="icon bg-light-blue">
            <LineChartIcon />
          </div>
          <div className="details">
            <p className="text-center">100.0%</p>
            <span>TInventory Shipped</span>
          </div>
        </div>
      </section>

      {Nav && <div className="nav-bar">{Nav}</div>}
      <Outlet />
    </div>
  );
}
