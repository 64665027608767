import { Dropdown } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";

const PlantChangeSide = (props) => {
  const navigate = useNavigate();

  const { backendUrl } = useAuth();

  const { data } = useQuery(
    ["FETCH_PLANTS"],
    () => fetchActionsUtil(`${backendUrl}/api/production/plants`, "GET"),
    {
      keepPreviousData: true,
    }
  );
  console.log();
  const plant = JSON.parse(window.localStorage.getItem("plantDetails"));

  const changeData = useMemo(() => {
    return data?.allPlants?.map((d) => ({ ...d[0] }));
  }, [data]);

  const onGoToWarehouse = (namePL, PLID) => {
    window.localStorage.setItem(
      "plantDetails",
      JSON.stringify({ namePL, PLID })
    );
    navigate("/plant-inventory/inventry-entry");
  };

  return (
    <div className="page-header d-flex justify-content-between align-items-center px-3 py-4 bg-white mx-4">
      <div className="title-area d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="mx-4">
            <h2>{plant?.namePL}</h2>
          </div>
          <div>
            <Dropdown style={{ margin: 0 }}>
              <Dropdown.Toggle
                variant=""
                className="bg-light-blue text-primary"
                bsPrefix="change"
              >
                Change
              </Dropdown.Toggle>

              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className=""
              >
                {changeData?.map(
                  (el, i) =>
                    plant?.namePL !== el.Plant_Name && (
                      <Dropdown.Item
                        as="button"
                        key={i}
                        onClick={() =>
                          onGoToWarehouse(el.Plant_Name, el.Plant_ID)
                        }
                      >
                        {el.Plant_Name}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantChangeSide;
