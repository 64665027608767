import { Table, Form } from "react-bootstrap";
// import { PageHeaderListIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useState, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import currency from "currency.js";
import { format, parse } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";

// import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import { paginationOptions } from "../../utils/helpers";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";

export default function PlantHistoryLog() {
  const { backendUrl } = useAuth();
  const initialFilterParams = {
    q: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  const refreshButton = useRef(null);

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  const { data, refetch } = useQuery(
    ["PLANT_ITEM_HISTORY", { queryParams, plantLocal }],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/production/items-history/${
          plantLocal?.PLID
        }?&${queryString.stringify(queryParams)}`,
        "GET"
      ),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  // console.log(data);

  return (
    <main className="inner-page-outlet">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header className="flex-wrap gap-3">
                <div className="d-flex flex-grow-1">
                  <div className="global-search-area col col-md-6">
                    <MagnifyIcon />
                    <Form.Control
                      id="queryParams-q"
                      className=""
                      name="q"
                      value={filterParams.q}
                      onChange={(e) => handleSearchQueryChange(e)}
                      placeholder="Search by Item Code and Description..."
                    />
                  </div>
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary mx-3"
                    ref={refreshButton}
                  >
                    <CachedIcon />
                  </button>
                </div>
                <div className="actions mr-5">
                  {/* <CSVLink
              className="btn print d-none"
              filename={`General Ledger Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            /> */}

                  <div className="position-relative">
                    <RsDateRangePicker
                      placement="topEnd"
                      value={
                        filterParams.startDate && filterParams.endDate
                          ? [
                              parse(
                                filterParams.startDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                              parse(
                                filterParams.endDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                            ]
                          : []
                      }
                      onClean={() => clearDateRange()}
                      onOk={(date) => filterByDateRange(date)}
                    />
                  </div>

                  {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                queryParams
              )}`}
              className="btn print mx-4"
            >
              PDF <PDFIcon color="#ff0000" />
            </a>
            <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button> */}
                </div>
              </header>

              <div className="px-md-4">
                <Table
                  borderless
                  responsive
                  hover
                  striped
                  className="product-table"
                >
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th className="ml-3">Item Code</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>UnitCost</th>
                      <th>Amount</th>
                      <th>Date Received</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.history?.map((el, ind) => (
                      <tr key={ind}>
                        <td>{ind + 1}</td>
                        <td>{el?.Bar_Code}</td>
                        <td>{el?.Item_Name}</td>
                        <td>{el?.Quantity}</td>
                        <td>{el?.Unit}</td>
                        <td>
                          {currency(el?.AmountDue, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(
                            parseFloat(el?.AmountDue) *
                              parseFloat(el?.Quantity),

                            {
                              symbol: "",
                            }
                          ).format()}
                        </td>
                        <td>
                          {format(new Date(el?.PurchaseDate), "yyyy-MM-dd")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {data?.history?.length < 1 && (
                <NoTableItem queryParams={queryParams} />
              )}
              {data?.history?.length > 0 && (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data?.count / queryParams?.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={parseInt(queryParams.page) - 1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <Outlet />
      <ModalLoader show={false} />
    </main>
  );
}
