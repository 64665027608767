import { Dropdown } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";

const WarehouseChangeSide = (props) => {
  const navigate = useNavigate();

  const { backendUrl } = useAuth();

  const { data } = useQuery(
    ["FETCH_WAREHOUSE"],
    () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const wares = JSON.parse(window.localStorage.getItem("warehouseName"));

  const onGoToWarehouse = (namewh, cater, whID) => {
    window.localStorage.setItem(
      "warehouseName",
      JSON.stringify({ namewh, cater, whID })
    );
    navigate("/warehouse-inventory/inventry-entry");
  };

  return (
    <div className="page-header d-flex justify-content-between align-items-center px-3 py-4 bg-white mx-4">
      <div className="title-area d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="mx-4">
            <h2>{wares?.namewh}</h2>
            <p>{wares?.cater} Category</p>
          </div>
          <div>
            <Dropdown style={{ margin: 0 }}>
              <Dropdown.Toggle
                variant=""
                className="bg-light-blue text-primary"
                bsPrefix="change"
              >
                Change
              </Dropdown.Toggle>

              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className=""
              >
                {data?.warehouses?.map(
                  (el, i) =>
                    wares?.namewh !== el.W_name && (
                      <Dropdown.Item
                        as="button"
                        key={i}
                        onClick={() =>
                          onGoToWarehouse(el.W_name, el.W_Category, el.W_ID)
                        }
                      >
                        {el.W_name}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseChangeSide;
