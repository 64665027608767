import Modal from "react-bootstrap/Modal";
import { useState, useMemo } from "react";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";

import { useAuth } from "../../hooks/useAuth";
import { sendFormData, fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import Avatar from "../utils/Avatar";

const allDepartment = [
  "",
  "Admin",
  "Accountant",
  "Cashier",
  "Content Management",
  "Contracts",
  "HR",
  "Sales",
  "Shout Out",
  "Store",
  "Warehouse",
  "Production",
];

const alLevels = ["", "High", "Low"];

const branches = ["", "HQ", "Small Branch"];

const initials = {
  profilePics: "",
  Name: "",
  password: "",
  confirmpassword: "",
  AccessLavel: "",
  Warehouse: [],
  username: "",
  Department: "",
  email: "",
  Branch: "",
  Staff_ID: "",
  Title: "",
};

const CreateUserAccount = (props) => {
  const { backendUrl } = useAuth();

  const [inputData, setInputData] = useState(initials);
  const [showText, setShowText] = useState({
    password: false,
    confirmpassword: false,
  });

  const { data, isFetching } = useQuery(
    ["FETCH_WAREHOUSE"],
    () => fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const mergedWarehouse = useMemo(() => {
    return data?.warehouses?.map((el) => ({
      label: el.W_name,
      value: el.W_name,
    }));
  }, [data]);

  const OnChangeInput = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePics")
      return setInputData({ ...inputData, [name]: files[0] });
    setInputData({ ...inputData, [name]: value });
  };

  const saveAccount = useMutation(
    (payload) => sendFormData(`${backendUrl}/api/users`, payload),
    {
      onSuccess: () => {
        props.refetch();
        toast.success("Account saved Successfully");
        setInputData(initials);
        props.onHide();
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  const saveData = async (e) => {
    e.preventDefault();
    const { profilePics, confirmpassword, ...rest } = inputData;
    if (!rest.Staff_ID) return toast.error("Empl ID can not be empty");
    if (!confirmpassword || !inputData.password)
      return toast.error("Enter Pasword and Confirm Password");
    if (confirmpassword !== inputData.password)
      return toast.error("MisMatch Password");

    rest.Warehouse = rest?.Warehouse?.map((d) => d?.value);
    const formData = new FormData();
    for (const [key, value] of Object.entries(rest)) {
      formData.append(key, value);
    }

    if (profilePics) {
      formData.append(
        "profilePics",
        profilePics,
        `${rest.Staff_ID}_profilePics`
      );
    }

    if (
      await ConfirmDialog({
        title: "Save Account",
        description: "Are you sure you want to save this Account",
      })
    ) {
      saveAccount.mutate(formData);
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Create User</h6>
              <h5 role="button" className="text-lg" onClick={props.onHide}>
                x
              </h5>
            </div>
            <p className="text-muted">
              Add new user by filling in the following forms.
            </p>
          </div>
          <div className="row my-4">
            <div className="col-9">
              <div>
                <form onSubmit={saveData}>
                  <div className="row">
                    <div className="col-2 p-3 mt-4">
                      <Avatar
                        onFileUpload={OnChangeInput}
                        image={inputData.profilePics}
                        name={inputData.Name}
                        nameInput="profilePics"
                        style={{ width: "8rem", height: "8rem" }}
                        userId="E"
                      />
                    </div>
                    <div className="col-10 p-3">
                      <div className="row">
                        <div className="col-6">
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Empl ID
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.Staff_ID}
                                name="Staff_ID"
                                onChange={OnChangeInput}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Full Name
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.Name}
                                name="Name"
                                onChange={OnChangeInput}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Password
                            </label>
                            <div>
                              <div className="input-group">
                                <input
                                  type={showText.password ? "text" : "password"}
                                  className="form-control"
                                  value={inputData.password}
                                  name="password"
                                  onChange={OnChangeInput}
                                />
                                {showText.password ? (
                                  <span
                                    onClick={() =>
                                      setShowText({
                                        ...showText,
                                        password: false,
                                      })
                                    }
                                    className="input-group-text text-xs bg-white"
                                  >
                                    <EyeOutline />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      setShowText({
                                        ...showText,
                                        password: true,
                                      })
                                    }
                                    className="input-group-text text-xs bg-white"
                                  >
                                    <EyeOffOutline />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Comfirm Password
                            </label>
                            <div>
                              <div className="input-group">
                                <input
                                  type={
                                    showText.confirmpassword
                                      ? "text"
                                      : "password"
                                  }
                                  className="form-control"
                                  value={inputData.confirmpassword}
                                  name="confirmpassword"
                                  onChange={OnChangeInput}
                                />

                                {showText.confirmpassword ? (
                                  <span
                                    onClick={() =>
                                      setShowText({
                                        ...showText,
                                        confirmpassword: false,
                                      })
                                    }
                                    className="input-group-text text-xs bg-white"
                                  >
                                    <EyeOutline />
                                  </span>
                                ) : (
                                  <span
                                    onClick={() =>
                                      setShowText({
                                        ...showText,
                                        confirmpassword: true,
                                      })
                                    }
                                    className="input-group-text text-xs bg-white"
                                  >
                                    <EyeOffOutline />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-form-label fw-bold">
                              Access Level
                            </label>
                            <div>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                value={inputData.AccessLavel}
                                name="AccessLavel"
                                onChange={OnChangeInput}
                              >
                                {alLevels.map((a, i) => (
                                  <option key={i} value={a}>
                                    {a}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/*  */}
                        </div>
                        {/* Second side inputs */}
                        <div className="col-6">
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold">
                              Title
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              value={inputData.Title}
                              name="Title"
                              onChange={OnChangeInput}
                            />
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold text-nowrap">
                              User Name
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.username}
                                name="username"
                                onChange={OnChangeInput}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold">
                              Department
                            </label>
                            <div>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                value={inputData.Department}
                                name="Department"
                                onChange={OnChangeInput}
                              >
                                {allDepartment.map((d, i) => (
                                  <option key={i} value={d}>
                                    {d}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/*  */}
                          {inputData.Department === "Warehouse" && (
                            <div className="mb-3 row">
                              <label className="col-sm-4 col-form-label fw-bold text-nowrap">
                                Choose Warehouse
                              </label>
                              <div>
                                <Select
                                  closeMenuOnSelect={true}
                                  isLoading={isFetching}
                                  isMulti
                                  isSearchable={true}
                                  onChange={(select) => {
                                    setInputData({
                                      ...inputData,
                                      Warehouse: select,
                                    });
                                  }}
                                  options={mergedWarehouse}
                                />
                              </div>
                            </div>
                          )}
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold">
                              Email
                            </label>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={inputData.email}
                                name="email"
                                onChange={OnChangeInput}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className="mb-3 row">
                            <label className="col-sm-4 col-form-label fw-bold">
                              Branch
                            </label>
                            <div>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                value={inputData.Branch}
                                name="Branch"
                                onChange={OnChangeInput}
                              >
                                {branches.map((d, i) => (
                                  <option key={i} value={d}>
                                    {d}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                      {/*  */}

                      <div className="mt-4 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          Create User
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* End of right side */}
            <div className="col-3 mt-4">
              <div className="border border-primary rounded">
                <div className="bg-primary text-white text-center p-2">
                  <h6 className="fw-bold">Assign Roles</h6>
                </div>
                <div className="p-3">
                  <div>side</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserAccount;
