import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import { ApproveModalIcon, BookIcon, RecieptIcon } from "./Icons";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";
import { useBackendUrl } from "../utils/hooks";

function Dialog({
  proceed,
  show,
  title = "",
  description = "",
  TransactionID,
  thermalPrinter,
}) {
  const backendUrl = useBackendUrl();

  const openInvoiceAndWaybill = () => {
    window.open(
      `${backendUrl}/api/invoice/pdf/invoice/${TransactionID}?${
        thermalPrinter ? `thermalPrinter=${thermalPrinter}` : ""
      }`,
      "_blank",
      "noopener,noreferrer"
    );

    window.open(
      `${backendUrl}/api/invoice/pdf/waybill/${TransactionID}?`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info">
          <ApproveModalIcon className="mb-4" />
          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <section className="row flex-wrap gap-4 px-3 mt-4">
          <Dropdown className="col px-0">
            <Dropdown.Toggle variant="outline-primary" className="w-100 py-3">
              Open Invoice
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-with-icons">
              <Dropdown.Item
                href={`${backendUrl}/api/invoice/pdf/invoice/${TransactionID}`}
                target="blank"
              >
                <BookIcon />
                A4 Size Printer
              </Dropdown.Item>
              <Dropdown.Item
                href={`${backendUrl}/api/invoice/pdf/invoice/${TransactionID}?thermalPrinter=true`}
                target="blank"
              >
                <BookIcon />
                Thermal Printer
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="col px-0">
            <Dropdown.Toggle variant="outline-primary" className="w-100 py-3">
              Open Reciept
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-with-icons">
              <Dropdown.Item
                href={`${backendUrl}/api/invoice/pdf/receipt/${TransactionID}`}
                target="blank"
              >
                <RecieptIcon />
                A4 Size Printer
              </Dropdown.Item>
              <Dropdown.Item
                href={`${backendUrl}/api/invoice/pdf/receipt/${TransactionID}?thermalPrinter=true`}
                target="blank"
              >
                <RecieptIcon />
                Thermal Printer
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <a
            href={`${backendUrl}/api/invoice/pdf/waybill/${TransactionID}`}
            target="blank"
            className="btn btn-primary p-3 col-12"
            //  disabled={isEmpty(TransactionID)}
          >
            Open Waybill
          </a>

          <Button
            className="btn btn-primary p-3 col-12"
            onClick={() => openInvoiceAndWaybill()}
          >
            Open Invoice and Waybill
          </Button>
        </section>
      </Modal.Body>
    </Modal>
  );
}

export default function InvoiceAndWaybillDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
