import { cloneDeep, isEmpty } from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Dropdown, Form } from "react-bootstrap";
import Editor from "./Editor";

/* import Mention from "./Mention";
import {
  AppRegExp,
  calculateFileSize,
  createMarkup,
  escapeHTML,
  formatFileSize,
  fullName,
  GetFileIcon,
  linkify,
  showToast,
  styleHelper,
  truncateFileName,
  usernameFromText,
} from "../utils";
import { cloneDeep } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { useLocalStorage, useOnClickOutside } from "../custom-hooks";
import EmojiPicker from "./EmojiPicker";
import EmoticonHappyOutline from "mdi-react/EmoticonHappyOutlineIcon";
import AtIcon from "mdi-react/AtIcon";
import PaperclipIcon from "mdi-react/PaperclipIcon";
import Comments from "./Comments";
import CloseIcon from "mdi-react/CloseIcon";
import PlusIcon from "mdi-react/PlusIcon";
import FolderIcon from "mdi-react/FolderOutlineIcon";
import { isEmpty, truncate } from "lodash";
import { DropboxIcon, GoogleDriveIcon } from "./Icons";
import FileOutlineIcon from "mdi-react/FileOutlineIcon"; */
import eventBus from "../../../utils/EventBus";

export const CommentArea = ({
  selectedProject,
  selectedTask,
  projectUsersList,
  hasAccountExpired,
  user,
  token,
  setGlobalComment,
  setUploadFile,
  isShared,
  scrollToBottom,
  thread,
  rootFolderId,
}) => {
  const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;

  let commentRef = useRef(null);
  let attachRef = useRef(null);
  let commentAreaRef = useRef(null);
  let editorRef = useRef(null);
  let mentionRef = useRef(null);
  let emojiRef = useRef(null);
  let fowardedMentionRef = useRef(null);

  const [data, setData] = useState({
    newComment: {
      employeeId: null,
      employeeImage: "",
      employeeName: "",
      date: "",
      time: "",
      files: [],
      filesToUpload: [],
      alreadySavedfiles: [],
      mentionedUsers: [],
      googleFiles: [],
      dropboxFiles: [],
    },
  });

  // --
  const _data = useRef(data);
  const _setData = (data) => {
    _data.current = data;
    setData(data);
  };
  // --

  const setGoogleFiles = (files) => {
    files = files.map((file) => {
      const lastDot = file.name.lastIndexOf(".");
      const name = file.name.substring(0, lastDot);
      const ext = file.name.substring(lastDot + 1);

      return {
        ...file,
        originalName: name,
        extension: `.${ext}`,
        // size: formatFileSize(file.sizeBytes),
      };
    });

    _setData({
      ...data,
      newComment: {
        ...data.newComment,
        googleFiles: [...data.newComment.googleFiles, ...files],
      },
    });
  };

  const setDropboxFiles = (files) => {
    files = files.map((file) => {
      const lastDot = file.name.lastIndexOf(".");
      const name = file.name.substring(0, lastDot);
      const ext = file.name.substring(lastDot + 1);

      return {
        ...file,
        originalName: name,
        extension: `.${ext}`,
        // size: formatFileSize(file.bytes),
      };
    });

    _setData({
      ...data,
      newComment: {
        ...data.newComment,
        dropboxFiles: [...data.newComment.dropboxFiles, ...files],
      },
    });
  };

  const [showUsersList, setShowUsersList] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [inputActive, setInputActive] = useState(false);
  const [commentToReply, setCommentToReply] = useState({});
  const [commentToQuote, setCommentToQuote] = useState({});
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [position, setPosition] = useState({
    mention: {
      top: 0,
      left: 0,
    },
    emoji: {
      top: 0,
      left: 0,
    },
  });
  /*const [selectedFolderToUpload, setSelectedFolderToUpload] = useLocalStorage(
    `task folder ${selectedTask._id}`,
    {
      _id: rootFolderId,
      name: selectedTask.taskName,
    }
  );

  useOnClickOutside(commentAreaRef, () => {
    if (!commentRef?.current?.value) {
      setInputActive(false);
    }
  });*/

  /*const scrolToModalBottom = () => {
    let modal = document.querySelector(".modal-content");
    if (modal && !styleHelper.isMobile) {
      setTimeout(() => {
        modal.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        // //modal.scrollTop = modal.scrollHeight;
      }, 100);
    }
  };*/

  useEffect(() => {
    // if (inputActive) scrolToModalBottom();

    return () => {};
  }, [inputActive]);

  useEffect(() => {
    if ((commentToReply._id || commentToQuote._id) && commentRef?.current) {
      if (commentRef?.current) {
        commentRef.current.focus();
      }
    }
    return () => {};
  }, [commentToReply, commentToQuote, commentRef]);

  useLayoutEffect(() => {
    if (showEmoji) {
      handleSetPosition({ e: emojiRef?.current, item: "emoji" });
    }
    if (showUsersList) {
      handleSetPosition({ e: mentionRef?.current, item: "mention" });
    }
  }, [showEmoji, showUsersList]);

  const resetCommentTextarea = () => {
    //  commentRef.current.style.height = "";
    commentRef.current.style.height = "3.5rem";
    eventBus.dispatch("setText", { newText: "" });
  };

  const pickFiles = (files) => {
    let data = _data.current;
    let { newComment } = data;

    let fl = files.length;
    let i = 0;
    while (i < fl) {
      const taskFile = {
        originalName: "",
        uniqueName: "",
        extension: "",
        size: "",
      };
      const file = files[i];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      let size = file.size;
      // taskFile.size = size;
      if (size < 1024) {
        taskFile.size = size + " B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        taskFile.size = (size / 1024).toFixed(2) + " KB";
      } else {
        taskFile.size = (size / 1024 / 1024).toFixed(2) + " MB";
      }
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      taskFile.originalName = fileName;
      taskFile.uniqueName = currentDate;
      taskFile.extension = ext;
      taskFile.file = file;
      //  taskFile.id = uuidv4();
      newComment.files = newComment.files.concat(taskFile);
      _setData({
        ...data,
        newComment,
      });
      i++;
    }

    if (commentRef?.current) {
      commentRef.current.focus();
      // scrolToModalBottom();
    }
  };

  const removeFile = (id, _id) => {
    let data = _data.current;
    _setData({
      ...data,
      newComment: {
        ...data.newComment,
        files: data.newComment.files.filter(
          (file, index) => file.id !== id || file._id !== _id
        ),
        alreadySavedfiles: data.newComment.alreadySavedfiles.filter(
          (file, index) => file.id !== id || file._id !== _id
        ),
      },
    });
  };

  const removeGoogleFile = (index) => {
    let data = _data.current;
    _setData({
      ...data,
      newComment: {
        ...data.newComment,
        googleFiles: data.newComment.googleFiles.filter(
          (file, i) => i !== index
        ),
      },
    });
  };

  const removeDropboxFile = (index) => {
    let data = _data.current;
    _setData({
      ...data,
      newComment: {
        ...data.newComment,
        dropboxFiles: data.newComment.dropboxFiles.filter(
          (file, i) => i !== index
        ),
      },
    });
  };

  const submitCommentReply = async (event) => {
    try {
      //const project = selectedProject;
      let data = _data.current;
      let { newComment } = data;
      /*const { text, mentionedUsers } = await usernameFromText({
        text: linkify(escapeHTML(commentRef.current.value)),
        members: [
          ...cloneDeep(
            projectUsersList.leaders ? projectUsersList.leaders : []
          ),
          ...cloneDeep(projectUsersList.team ? projectUsersList.team : []),
        ],
      });*/
      let text = "";

      newComment.ogComment = text;
      newComment.commentMarkUp = text;
      // newComment.mentionedUsers = mentionedUsers;

      // ToDO - check if has more

      setDisableSubmitBtn(true);
      if (!hasAccountExpired) {
        // if (true) {
        if (
          newComment &&
          (newComment.ogComment.trim() || newComment.files.length)
        ) {
          // let { taskFilesDataList } = data;
          // const project = selectedProject;
          const filesToUpload = newComment.files.filter(
            (file) => !file.cloud_id
          );
          const taskId = selectedTask._id;

          //  newComment.id = uuidv4();
          // newComment.comment = newComment.comment.trim();
          newComment.employeeId = user._id;
          newComment.employeeName = user.name.first + " " + user.name.last;
          newComment.employeeImage = user.photo;
          newComment.parentCommentId = commentToReply._id;
          newComment.files = [...newComment.alreadySavedfiles];

          const date = new Date();
          newComment.date =
            date.getDate() +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear();
          newComment.time =
            date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);

          newComment.taskId = taskId;
          newComment.token = token;
          newComment.usage = "comment-reply";
          newComment.filesToUploadCount = filesToUpload.length;
          newComment.employeeId = {
            ...user,
          };
          newComment.createdAt = date;
          newComment.repliesCount = 0;
          newComment.taskCommentReplies = [];

          // save to redux
          let commentToSend = {
            [newComment.id]: {
              id: newComment.id,
              ...newComment,
              cancel: 0,
              status: 0,
              error: 0,
              loading: 0,
            },
          };

          //setGlobalComment(commentToSend);
          _setData({
            ...data,
            newComment: {
              employeeId: null,
              employeeImage: "",
              employeeName: "",
              date: "",
              time: "",
              // comment: "",
              files: [],
              alreadySavedfiles: [],
              // filesToUpload: [],
              googleFiles: [],
              dropboxFiles: [],
            },
          });

          resetCommentTextarea();
          setCommentToReply({});
          setCommentToQuote({});
          setDisableSubmitBtn(false);
          setInputActive(false);

          setGlobalComment(commentToSend);

          let folder_id = "";

          if (filesToUpload && filesToUpload.length) {
            let commentFilesToUpload = {};
            for (const [i, file] of filesToUpload.entries()) {
              if (!file.file) return;
              commentFilesToUpload = {
                ...commentFilesToUpload,
                [file.id]: {
                  sortIndex: i,
                  id: file.id,
                  ...cloneDeep(file),
                  progress: 0,
                  cancel: 0,
                  status: 0,
                  projectId: "",
                  parentCommentId: commentToReply._id,
                  tempCommentReplyId: newComment.id,
                  replyId: "",
                  taskId: selectedTask._id,
                  folder_id,
                  stage: 0,
                  loading: 1,
                  error: 0,
                  token,
                  usage: "comment-reply",
                  show: 0,
                },
              };
            }
            setUploadFile({ ...commentFilesToUpload });
          }
          return;
        } else {
          setDisableSubmitBtn(false);
        }
      }
    } catch (err) {
      console.log("Task comment error:", err);
    } finally {
    }
  };

  const submitComment = async (event) => {
    event.preventDefault();
    // console.log("submit comment");
    try {
      setShowUsersList(false);
      let data = _data.current;
      let { newComment } = data;

      /* const { text, mentionedUsers } = await usernameFromText({
        text: linkify(escapeHTML(commentRef.current.value)),
        members: [
          ...cloneDeep(
            projectUsersList.leaders ? projectUsersList.leaders : []
          ),
          ...cloneDeep(projectUsersList.team ? projectUsersList.team : []),
        ],
      }); */
      let text = "";

      newComment.ogComment = text;
      newComment.commentMarkUp = text;
      // newComment.mentionedUsers = mentionedUsers;

      if (Object.keys(commentToReply).length) {
        submitCommentReply();
        return;
      } else {
        setDisableSubmitBtn(true);
      }

      // const project = selectedProject;
      if (!hasAccountExpired) {
        // if (true) {
        if (
          newComment &&
          (newComment.ogComment.trim() ||
            newComment.files.length ||
            newComment.dropboxFiles.length ||
            newComment.googleFiles.length)
        ) {
          // let { taskFilesDataList } = data;
          const filesToUpload = newComment.files.filter(
            (file) => !file.cloud_id
          );
          const taskId = selectedTask._id;

          // newComment.id = uuidv4();
          newComment.employeeId = user._id;
          newComment.employeeName = user.name.first + " " + user.name.last;
          newComment.employeeImage = user.photo;
          newComment.files = [...newComment.alreadySavedfiles];

          const date = new Date();
          newComment.date =
            date.getDate() +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear();
          newComment.time =
            date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2);

          newComment.taskId = taskId;
          newComment.token = token;
          newComment.usage = "task-board";
          newComment.filesToUploadCount = filesToUpload.length;
          newComment.employeeId = {
            ...user,
          };
          newComment.createdAt = date;
          newComment.repliesCount = 0;
          newComment.taskCommentReplies = [];

          if (commentToQuote.parentCommentId) {
            newComment.quotedReplyId = commentToQuote._id;
          } else {
            newComment.quotedCommentId = commentToQuote._id;
          }

          // save to redux
          let commentToSend = {
            [newComment.id]: {
              id: newComment.id,
              ...cloneDeep(newComment),
              // commentMarkUp,
              cancel: 0,
              status: 0,
              error: 0,
              loading: 0,
            },
          };

          let folder_id = "";
          if (filesToUpload && filesToUpload.length) {
            let commentFilesToUpload = {};
            for (const [i, file] of filesToUpload.entries()) {
              if (!file.file) return;
              commentFilesToUpload = {
                ...commentFilesToUpload,
                [file.id]: {
                  sortIndex: i,
                  id: file.id,
                  ...cloneDeep(file),
                  progress: 0,
                  cancel: 0,
                  status: 0,
                  projectId: "",
                  commentId: "",
                  tempCommentId: newComment.id,
                  taskId: selectedTask._id,
                  folder_id,
                  error: 0,
                  stage: 0,
                  show: 0,
                  loading: 1,
                  token,
                  usage: "comment",
                },
              };
            }

            setUploadFile({ ...commentFilesToUpload });
          }

          _setData({
            ...data,
            newComment: {
              employeeId: null,
              employeeImage: "",
              employeeName: "",
              date: "",
              time: "",
              // comment: "",
              files: [],
              alreadySavedfiles: [],
              // filesToUpload: [],
              googleFiles: [],
              dropboxFiles: [],
            },
          });
          resetCommentTextarea();
          setCommentToQuote({});
          setCommentToReply({});
          setDisableSubmitBtn(false);
          setInputActive(false);
          setGlobalComment(commentToSend);

          setTimeout(() => {
            scrollToBottom(thread);
          }, 500);
          return;
        } else {
          setDisableSubmitBtn(false);
        }
      }
    } catch (err) {
      console.log("Task comment error:", err);
    }
  };

  const resizeInput = (e) => {
    e.target.style.height = "";
    e.target.style.height = e.target.scrollHeight - 2 + "px";
    // scrollToBottom(thread);
  };

  const selectEmoji = (emoji) => {
    let comment = commentRef.current.value;
    const selectionStart = commentRef.current.selectionStart;
    const currentSelection =
      selectionStart === comment.length + 1
        ? selectionStart - 1
        : selectionStart;
    let firstTextBatch = comment.substring(0, currentSelection);
    let secondTextBatch = comment.substring(
      currentSelection,
      comment.length + 1
    );
    firstTextBatch =
      comment.length && !firstTextBatch.endsWith(" ")
        ? `${firstTextBatch} `
        : firstTextBatch;
    secondTextBatch =
      comment.length && !secondTextBatch.startsWith(" ")
        ? ` ${secondTextBatch}`
        : secondTextBatch;
    const newText = `${firstTextBatch}${emoji.native}${secondTextBatch}`;
    eventBus.dispatch("setText", { newText });
    setShowEmoji(false);
  };

  const handleSelectUser = (user) => {
    let comment = commentRef.current.value;
    const selectionStart = commentRef.current.selectionStart;
    const currentSelection =
      selectionStart === comment.length + 1
        ? selectionStart - 1
        : selectionStart;
    const firstTextBatch = comment.substring(0, currentSelection);
    const secondTextBatch = comment.substring(
      currentSelection,
      comment.length + 1
    );

    const username = `@${user.name} `;
    let textToReplace = comment
      .substring(0, currentSelection)
      .split(/\s+/)
      .pop();
    let newFirstTextBatch;

    console.log(textToReplace);
    // if (!textToReplace.includes('@')) {

    /*newFirstTextBatch =
        comment.length && !firstTextBatch.endsWith(" ")
          ? `${firstTextBatch} ${username}`
          : `${firstTextBatch}${username}`; */
    // } else {
    newFirstTextBatch = firstTextBatch.replace(
      new RegExp(`${textToReplace}$`),
      username
    );
    // }
    const newText = newFirstTextBatch + secondTextBatch;
    eventBus.dispatch("setText", { newText });
    setShowUsersList(false);
    setTimeout(() => {
      setCaretPosition(commentRef.current, selectionStart + username.length);
    }, 500);
  };

  const setCaretPosition = (textareaRef, caretPos) => {
    // console.log(textareaRef);
    if (textareaRef != null) {
      if (textareaRef.createTextRange) {
        const range = textareaRef.createTextRange();
        range.move("character", caretPos);
        range.select();
      } else {
        if (textareaRef.selectionStart) {
          textareaRef.focus();
          textareaRef.setSelectionRange(caretPos, caretPos - 1);
        } else textareaRef.focus();
      }
    }
  };

  const updateCommentFile = (e) => {
    e.preventDefault();
    let fileInput = e.target;
    let files = fileInput.files;
    pickFiles(files);
  };

  const handleSetPosition = ({ e, item }) => {
    let clientRect = e.getBoundingClientRect();
    let clientX = clientRect.left;
    let clientY = clientRect.top;
    let box, width, height;

    if (item === "mention") {
      box = fowardedMentionRef.current;
    } else {
      box = fowardedMentionRef.current;
    }

    width = box.offsetWidth;
    height = box.offsetHeight;

    console.log(width, height);

    let x = clientX,
      y = clientY,
      // sx = e.screenX,
      // sy = e.screenY,
      w = window.innerWidth,
      h = window.innerHeight,
      top = y,
      // If screen left is < 300 Else if screen left
      // left = x < 150 ? 150 : w - x < 150 ? x - 175 : x;
      left = x;

    if (item === "mention") {
      if (h - y < 300) {
        top = y - 300;
      }
      if (w - x < 310) {
        left = x - 310;
      }
    } else {
      if (h - y < 255) {
        top = y - 255;
      }
      if (w - x < 310) {
        left = x - 310;
      }
    }

    setPosition({
      ...position,
      [item]: {
        top,
        left,
      },
    });
  };

  eventBus.useCustomEventListener("setCommentToReply", ({ comment }) => {
    setCommentToReply(comment);
  });
  eventBus.useCustomEventListener("setCommentToQuote", ({ comment }) => {
    setCommentToQuote(comment);
  });

  eventBus.useCustomEventListener("add-file-to-comment", (file) => {
    const data = _data.current;
    if (file?.type === "googleFiles") {
      _setData({
        ...data,
        newComment: {
          ...data.newComment,
          googleFiles: [...data.newComment.googleFiles, file.file],
        },
      });
    } else if (file?.type === "dropboxFiles") {
      _setData({
        ...data,
        newComment: {
          ...data.newComment,
          dropboxFiles: [...data.newComment.dropboxFiles, file.file],
        },
      });
    } else {
      _setData({
        ...data,
        newComment: {
          ...data.newComment,
          files: [...data.newComment.files, file.file],
          alreadySavedfiles: [...data.newComment.alreadySavedfiles, file.file],
        },
      });
    }
    if (commentRef?.current) commentRef.current.focus();
  });
  eventBus.useCustomEventListener("pickFiles", pickFiles);

  const shareFromGoogle = async () => {
    try {
      const response = await window.shareFromGoogle();
      console.log(response);
      if (response?.files) setGoogleFiles(response.files);
    } catch (err) {
      console.log("shareFromGoogle", err);
      /*showToast({
        message: {
          title: "Unable to load Google file picker",
          description: "check your connection and try again",
        },
        type: "error",
      });*/
    }
  };

  const shareFromDropbox = async () => {
    try {
      const response = await window.shareFromDropbox();
      console.log(response);
      if (response.files) setDropboxFiles(response.files);
    } catch (err) {
      console.log("shareFromDropbox", err);
      /*showToast({
        message: {
          title: "Unable to load Dropbox file picker",
          description: "check your connection and try again",
        },
        type: "error",
      });*/
    }
  };

  return (
    <div ref={commentAreaRef}>
      <div className="position-relative 9">
        {/* <Mention
          fowardedMentionRef={fowardedMentionRef}
          hideTitle={true}
          key={showUsersList}
          handleSelectUser={handleSelectUser}
          setShowUsersList={setShowUsersList}
          usersList={projectUsersList}
          inputFocus={false}
          style={{
            ...(styleHelper.isMobile
              ? {
                  position: "absolute",
                  right: 0,
                  left: 0,
                  marginBottom: "0.5rem",
                  maxHeight: "18.5rem",
                }
              : {
                  // position: "fixed",
                  //...position.mention,
                  position: "absolute",
                  right: 0,
                  left: 0,
                  marginBottom: "0.5rem",
                  zIndex: 10000000,
                }),
            display: `${!showUsersList ? "none" : "block"}`,
          }}
          listStyle={{ maxHeight: "14rem" }}
        />*/}
      </div>

      {Object.keys(commentToReply).length ? (
        <div
          className="project-files thread h-auto p-2"
          style={{ background: /*"#D8E6F5"*/ "#e9f1f8" }}
        >
          <button
            className="btn btn-sm close-reply bg-light p-0 text-secondary m-1 border"
            style={{ zIndex: 10 }}
            title="Close"
            onClick={() => setCommentToReply({ ...{} })}
          >
            <CloseIcon />
          </button>
          {/*<Comments
            comment={commentToReply}
            isReply={true}
            isShared={isShared}
      />*/}
        </div>
      ) : null}
      {!isEmpty(data.newComment.files) ||
      !isEmpty(data.newComment.googleFiles) ||
      !isEmpty(data.newComment.dropboxFiles) ? (
        <div className="conversation-files" style={{ zIndex: 3 }}>
          <div className="files">
            {data.newComment?.files.map((file, index) =>
              /*AppRegExp.isImageFullPath(`.${file.extension}`)*/ false ? (
                <div
                  key={index}
                  className="grid-item d-flex flex-column justify-content-between align-items-center p-0"
                >
                  <img
                    alt="file"
                    src={
                      file.cloud_id
                        ? `${watcherServerUrl}/files/${file.cloud_id}/${
                            file.originalName
                          }.${file.extension}?view`
                        : URL.createObjectURL(file.file)
                    }
                  />
                  <span
                    className="close"
                    onClick={() => removeFile(file.id, file._id)}
                  >
                    ✖
                  </span>
                </div>
              ) : (
                <div
                  key={index}
                  className="grid-item d-flex flex-column justify-content-between align-items-center"
                >
                  {/*} <GetFileIcon file={file} />
                  <div className="d-flex flex-column file-details">
                    <span>{truncateFileName(file)}</span>
                    <span>{calculateFileSize(file.size)}</span>
                  </div>
                  <span
                    className="close"
                    onClick={() => removeFile(file.id, file._id)}
                  >
                    ✖
              </span> */}
                </div>
              )
            )}
            {data.newComment?.googleFiles.map((file, index) => (
              <div
                key={index}
                className="grid-item d-flex flex-column justify-content-between align-items-center"
              >
                {/* <GetFileIcon file={file} />
                <div className="d-flex flex-column file-details">
                  <span>{truncateFileName(file)}</span>
                  <span>{calculateFileSize(file.size)}</span>
                </div>
                <span className="close" onClick={() => removeGoogleFile(index)}>
                  ✖
            </span>*/}
              </div>
            ))}
            {data.newComment?.dropboxFiles.map((file, index) => (
              <div
                key={index}
                className="grid-item d-flex flex-column justify-content-between align-items-center"
              >
                {/*  <GetFileIcon file={file} />
                <div className="d-flex flex-column file-details">
                  <span>{truncateFileName(file)}</span>
                  <span>{calculateFileSize(file.size)}</span>
                </div>
                <span
                  className="close"
                  onClick={() => removeDropboxFile(index)}
                >
                  ✖
                </span> */}
              </div>
            ))}
            <label
              htmlFor="commentFiles"
              className="grid-item d-flex flex-column justify-content-around align-items-center text-primary p-cursor py-3"
            >
              {/*<PlusIcon /> */}
              <b>Add File</b>
            </label>
          </div>
        </div>
      ) : null}

      {/*  <EmojiPicker
        selectEmoji={selectEmoji}
        setShowEmoji={setShowEmoji}
        style={{
          display: `${!showEmoji ? "none" : "block"}`,

          ...(styleHelper.isMobile
            ? {}
            : {
                position: "fixed",
                width: "21.625rem",
                ...position.emoji,
                zIndex: 10000000,
              }),
        }}
      /> */}

      <Form
        onSubmit={(e) => {
          submitComment(e);
        }}
      >
        <div className="comment-section">
          {Object.keys(commentToQuote).length ? (
            <div className="px-3 pt-3 d-flex justify-content-beiween align-items-center">
              <div className="quote flex-grow-1">
                <div className="d-flex justify-content-between align-items-center h-100">
                  <div className="content">
                    <p className="quote-username m-0">
                      {commentToQuote?.employeeId?.name.first}{" "}
                      {commentToQuote?.employeeId?.name.last}{" "}
                    </p>

                    {/* <p
                      className="comment m-0"
                      dangerouslySetInnerHTML={createMarkup(
                        commentToQuote._id
                          ? commentToQuote.comment
                          : commentToQuote.commentMarkUp
                      )}
                    /> */}
                  </div>

                  {/* commentToQuote.files.length ? (
                    <div className="image-holder">
                      <>
                        {AppRegExp.isImageFullPath(
                          `${commentToQuote.files[0].originalName}.${
                            commentToQuote.files[0].extension
                          }`
                        ) ? (
                          <img
                            src={`${watcherServerUrl}/files/${
                              commentToQuote.files[0].cloud_id
                            }/${commentToQuote.files[0].originalName}.${
                              commentToQuote.files[0].extension
                            }?view`}
                            dsrc="https://images.unsplash.com/photo-1629581477374-9237dc216942?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzMjIyMzQ2Ng&ixlib=rb-1.2.1&q=80&w=1080"
                            alt={`${commentToQuote.files[0].originalName}.${
                              commentToQuote.files[0].extension
                            }`}
                            className="w-100"
                          />
                        ) : (
                          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                            <GetFileIcon
                              file={commentToQuote.files[0]}
                              className={`icon-ga`}
                              style={{
                                transform: "scale(2) !important",
                              }}
                            />
                          </div>
                        )}
                      </>
                    </div>
                  ) : (
                    <span />
                  ) */}
                </div>
              </div>
              <button
                className="btn close-quote-btn ml-2"
                onClick={() => setCommentToQuote({})}
              >
                ✖
              </button>
            </div>
          ) : null}

          {
            <Editor
              ref={(el) => (editorRef = el)}
              setShowUsersList={setShowUsersList}
              resizeInput={resizeInput}
              pickFiles={pickFiles}
              setInputActive={setInputActive}
              commentRef={commentRef}
              data={data}
            />
          }

          <div
            className={`w-100 actions position-relative ${
              inputActive ||
              Object.keys(commentToReply).length ||
              Object.keys(commentToQuote).length
                ? "active"
                : "in-active"
            }`}
          >
            <div className="w-100 position-absolute d-flex justify-content-between align-items-center">
              <div>
                <button
                  className="btn btn-sm btn-primary mr-2 px-3"
                  disabled={disableSubmitBtn}
                >
                  Send
                </button>
                {/*<button
                      className="btn"
                      type="button"
                      onClick={() => setInputActive(false)}
                    >
                      ✖
                    </button>
                    */}
              </div>

              <div className="d-flex utils">
                <label
                  htmlFor="commentFiles"
                  className="m-0 btn btn-light btn-sm p-1"
                  style={{ marginRight: "-10px !important" }}
                  ref={(el) => (attachRef.current = el)}
                  title="Attachments"
                >
                  <input
                    type="file"
                    id="commentFiles"
                    className="d-none"
                    onChange={(e) => {
                      updateCommentFile(e);
                      e.target.value = "";
                    }}
                    multiple
                  />
                </label>

                <button
                  className="m-0 btn btn-light btn-sm p-1"
                  type="button"
                  ref={(el) => (mentionRef.current = el)}
                  title="Mention"
                  onClick={() => {
                    if (!showUsersList) {
                      setShowUsersList(true);
                      // setShowEmoji(false);
                    }
                  }}
                >
                  {/* <AtIcon className={`${showUsersList && "text-primary"}`} /> */}
                </button>

                <button
                  className="m-0 btn btn-light btn-sm p-1"
                  type="button"
                  ref={(el) => (emojiRef.current = el)}
                  title="Emoji"
                  onClick={() => {
                    if (!showEmoji) {
                      setShowEmoji(true);
                    }
                    // setShowUsersList(false);
                  }}
                >
                  {/*  <EmoticonHappyOutline
                    className={`${showEmoji && "text-primary"}`}
                  /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CommentArea;
