import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Form, Modal, Nav, Table } from "react-bootstrap";
import MessageProcessingOutlineIcon from "mdi-react/MessageProcessingOutlineIcon";
import "./../../assets/scss/requisition/index.scss";
import ApprovalBadge from "../utils/ApprovalBadge";
import {
  ApproveCheckIcon,
  DisapprovedCheckIcon,
  PendingCheckIcon,
} from "../Icons";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../config";
import { useQuery, useQueryClient } from "react-query";
import { lowerCase } from "lodash";
import { format } from "date-fns";
import { ApproveAndCloseDialog, RejectDialog } from "./Dialog";
import { requisitionActions } from "../../utils/reactQueryActions";
import Avatar from "../utils/Avatar";
import { useAuth } from "../../hooks/useAuth";
import { last } from "lodash";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import eventBus from "../../utils/EventBus";
import {
  isInventoryOrFundRequest,
  resolveApprovalBadgeBg,
  styleHelper,
} from "../../utils/helpers";
import { useCanGoBack } from "../../utils/hooks";

export default function DetailsModal({ location }) {
  const { user: authUser, backendUrl } = useAuth();
  // const queryClient = useQueryClient();
  let { id } = useParams();
  const [activeKey, setActiveKey] = useState("details");

  const handleSelect = (eventKey) => setActiveKey(eventKey);
  let navigate = useNavigate();
  const goBack = useCanGoBack();

  const getRequisitionDetails = async (id) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/requisition/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    error,
    data: { requisition } = { requisition: null },
    isSuccess,
    refetch,
    isFetching,
  } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, id],
    () => getRequisitionDetails(id),
    {}
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  const reject = async () => {
    await RejectDialog({
      authUser,
      requisitionTitle: requisition.title,
      requisition: last(requisition?.requisitiontracks),
      previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
    });
  };

  const approveAndClose = async () => {
    await ApproveAndCloseDialog({
      authUser,
      requisitionTitle: requisition.title,
      requisition: last(requisition?.requisitiontracks),
      previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
    });
  };

  const canPerformAction = () => {
    // return true;
    const pendingRequisition = last(requisition?.requisitiontracks);
    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  return (
    <Modal
      show={true}
      onHide={() => goBack("/requisition/request")}
      dialogClassName="requisition-details-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 onClick={() => refetch()}> Details Requisition</h1>
          <p>More information about the request sent</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-end">
          <Nav
            variant="tabs"
            onSelect={handleSelect}
            // defaultActiveKey="1"
            activeKey={activeKey}
          >
            <Nav.Item>
              <Nav.Link className={`ga-nav-tab`} eventKey="details">
                Details
              </Nav.Link>
            </Nav.Item>

            {isInventoryOrFundRequest(requisition?.type) && (
              <Nav.Item>
                <Nav.Link
                  className={`ga-nav-tab`}
                  // eventKey="requisition-form"
                >
                  <Link
                    to={`/requisition/request/preview/${id}`}
                    state={{ backgroundLocation: location }}
                  >
                    Requisition Form
                  </Link>
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          {/* 
          <Link
            to={`/requisition/request/conversations/${id}`}
            state={{ backgroundLocation: location }}
            className="btn border text-primary message-btn"
          >
            <MessageProcessingOutlineIcon />
          </Link> */}
        </div>

        {activeKey === "details" ? (
          <>
            <div className="info p-3 my-3 border rounded">
              <p className="border-bottom pb-3 mb-3 d-flex gap-3 title">
                <span>From:</span>
                <span className="fw-5">
                  {requisition?.requestbyUser?.Department || "..."}
                </span>
              </p>

              <h2>{requisition?.title}</h2>
              <p>{requisition?.type}</p>

              <div className="d-md-flex">
                <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    <tr>
                      <td>ID requisition :</td>
                      <td>#{id}</td>
                    </tr>
                    <tr>
                      <td>Date:</td>
                      <td>
                        {requisition?.regdate
                          ? format(
                              new Date(requisition?.regdate),
                              "dd / MM / yyyy"
                            )
                          : "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table borderless className="info-table mb-0 mb-md-3">
                  <tbody>
                    <tr>
                      <td>Status :</td>
                      <td>
                        <ApprovalBadge
                          text={requisition?.status}
                          className="approval"
                          bg={resolveApprovalBadgeBg(requisition?.status)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Recipient : </td>
                      <td className="fw-5">
                        {requisition?.requesttoUser?.Department || "..."}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div className="approval p-3 my-3 border rounded">
              <p className="border-bottom pb-3 mb-3 title">
                <span>Approval</span>
              </p>

              <div className="approval-history">
                {requisition?.requisitiontracks?.map((el, i) => (
                  <div key={i} className="item">
                    {i !== 0 && <div className="line" />}
                    {lowerCase(el.status) === "pending" ? (
                      <>
                        <div className="timeline border">
                          <PendingCheckIcon />
                        </div>

                        <div className="detail">
                          <Avatar
                            className="image"
                            name={el.receivedbyUser?.Name}
                            userId={el.receivedbyUser?.Staff_ID}
                          />

                          <div>
                            <ApprovalBadge
                              text={el.status}
                              className="approval"
                              bg="warning"
                            />
                            <p className="mb-1">
                              <span className="fw-5">
                                {el.sentbyUser?.Name || "..."}
                              </span>{" "}
                              is waiting for a response from{" "}
                              <span className="fw-5">
                                {el.receivedbyUser?.Name || "..."}
                              </span>
                            </p>
                            <i>{el?.comment}</i>
                          </div>
                        </div>
                      </>
                    ) : lowerCase(el.status) === "disapproved" ? (
                      <>
                        <div className="timeline border">
                          <DisapprovedCheckIcon />
                        </div>

                        <div className="detail">
                          <Avatar
                            className="image"
                            name={el.receivedbyUser?.Name}
                            userId={el.receivedbyUser?.Staff_ID}
                          />

                          <div>
                            <ApprovalBadge
                              text={el.status}
                              className="approval"
                              bg="danger"
                            />
                            <p className="mb-1">
                              <span className="fw-5">
                                {el.receivedbyUser?.Name || "..."}
                              </span>{" "}
                              disapproved a request from{" "}
                              <span className="fw-5">
                                {el.sentbyUser?.Name || "..."}
                              </span>
                            </p>
                            <i>{el?.comment}</i>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="timeline border">
                          <ApproveCheckIcon />{" "}
                        </div>

                        <div className="detail">
                          <Avatar
                            className="image"
                            name={el.receivedbyUser?.Name}
                            userId={el.receivedbyUser?.Staff_ID}
                          />

                          <div>
                            <ApprovalBadge
                              text={el.status}
                              className="approval"
                              bg="success"
                            />
                            <p className="mb-1">
                              {el.status === "Approved & Sent" ? (
                                <>
                                  <span className="fw-5">
                                    {el.receivedbyUser?.Name || "..."}
                                  </span>{" "}
                                  has <span className="fw-5">approved</span> and
                                  sent
                                </>
                              ) : (
                                <>
                                  <span className="fw-5">
                                    {el.receivedbyUser?.Name || "..."}
                                  </span>{" "}
                                  has <span className="fw-5">approved</span> and
                                  closed{" "}
                                </>
                              )}
                            </p>
                            <i>{el?.comment}</i>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          {
            /* <div className="info p-3 my-3 border rounded">
                  <h2>Material Marketing Request</h2>

                  <Table borderless className="info-table">
                    <tbody>
                      <tr className="text-dark">
                        <td>Type:</td>
                        <td>
                          Procurement request <br />
                          <span className="text-light">
                            Material Procurement
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Link
                    to={`/requisition/preview/${id}`}
                    state={{ backgroundLocation: location }}
                    replace
                    className="btn btn-l-ght w-100 text-primary border-0 bg-light-blue preview-btn lh-1"
                  >
                    Preview Requisition Form
                  </Link>
              </div>*/
          }
        )}
      </Modal.Body>
      {canPerformAction() && (
        <Modal.Footer className="d-print-none variant-2">
          <Button
            onClick={() => reject()}
            variant="danger"
            className="text-white px-3 reject"
          >
            Reject
          </Button>

          <div className="proceed-actions">
            <Link
              to={`/requisition/request/approve-and-send/${id}`}
              state={{ backgroundLocation: location }}
              className="btn btn-white border bg-white"
            >
              Approve & Sent
            </Link>
            <Button
              className="approve-and-close"
              onClick={() => approveAndClose()}
              variant="primary"
            >
              Approve & Close
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
