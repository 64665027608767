import { useState, useEffect } from "react";
import { format } from "date-fns";
import currency from "currency.js";

export default function PlantWarehouseDamagesTable(props) {
  const [active, setActive] = useState(null);

  useEffect(() => {
    setActive((prev) =>
      props?.selectedItems?.find((it) => it?.FaultID === props?.el?.FaultID)
    );
  }, [props.el.FaultID, props?.selectedItems]);

  return (
    <tr
      key={props?.el?.FaultID + props?.index}
      style={
        !["Active"].includes(props?.el.DamageStatus)
          ? {
              pointerEvents: "none",
              opacity: 0.5,
            }
          : {}
      }
    >
      <td>
        <input
          className="form-check-input"
          type="checkbox"
          onChange={() => props?.onChangeSelected(props?.el)}
          checked={active && props.el.DamageStatus === "Active"}
          id={props?.index}
          disabled={props.el.DamageStatus !== "Active"}
        />

        <label className="form-check-label px-3" htmlFor={props?.el.FaultID}>
          {props?.el.FaultID}
        </label>
      </td>
      <td>{props?.el?.Barcode}</td>
      <td>{props?.el?.Item_Name}</td>

      <td className="p-cursor" title={props?.el.Quantity}>
        {currency(props?.el.Quantity, {
          symbol: "",
          precision: 1,
        }).format()}
      </td>
      <td className="text-nowrap">
        {props?.el?.Warehouse_Damages_Plants?.Plant_Name}
      </td>
      <td className="text-nowrap">{props?.el?.DamageStatus}</td>
      <td>{props?.el.Faults}</td>
      <td className="text-nowrap">
        {props?.el?.EntryDate
          ? format(new Date(props?.el.EntryDate), "dd MMM, yyyy")
          : "..."}
      </td>
    </tr>
  );
}
