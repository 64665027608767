import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { services } from "../config";
import { useAuth } from "../hooks/useAuth";

export default function RequisitionService() {
  const { backendUrl } = useAuth();
  try {
    const createRequisition = async (payload) => {
      // await waitFor(5000);
      const formData = new FormData();
      for (const [key, value] of Object.entries(payload)) {
        formData.append(key, value);
      }
      // formData.append("signature", file, `${authUser.Name}_signature`);

      let response = await fetch(`${backendUrl}/api/requisition/create`, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();
      return data;
    };

    const createRequisitionMutation = useMutation(
      (payload) => createRequisition(payload),
      {
        onSuccess: (data) => {
          toast.success("Requisition created successfully");
        },
        onError: (err) => {
          toast.error("Unable to create Requisition");
        },
      }
    );

    return {
      createRequisitionMutation,
    };
  } catch (err) {
    console.log(err);
  }
}
