import { Table } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import queryString from "query-string";
// import { CSVLink } from "react-csv";
// import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
// import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import { ExchangeFunds } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
// import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { fetchActionsUtil } from "../../utils/helpers";
import CreateStorageLocationModal from "../modals/CreateStorageLocation";

function StorageLocation() {
  useScrollTop();
  const initialFilterParams = {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  const { backendUrl } = useAuth();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [allStorage, setAllStorage] = useState([]);
  // const [selectedStorage, setSelectedStorage] = useState("");

  const [queryParams, setQueryParams] = useQueryParams({
    // page: 1,
    // limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fechWareHouse = async (query) => {
    const { storagelocation } = await fetchActionsUtil(
      `${backendUrl}/api/StorageLocation?${queryString.stringify(query)}`,
      "GET"
    );

    const data = await fetchActionsUtil(
      `${backendUrl}/api/StorageLocation`,
      "GET"
    );

    storagelocation.warehouse = data.storagelocation.map((el) => ({
      value: el.Storage_ID,
      label: el.storage_location,
    }));

    setAllStorage(() => storagelocation);
    return storagelocation;
  };

  const { data, refetch, isFetching } = useQuery(
    ["FETCH_STORAGE-LOCATION", queryParams],
    () => fechWareHouse(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const onSelectStorage = async (store) => {
    if (store === "All") return setAllStorage(() => data);
    const { storagelocation } = await fetchActionsUtil(
      `${backendUrl}/api/StorageLocation/storage/${store}`,
      "GET"
    );
    setAllStorage(() => storagelocation);
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <main className="cash-book table-sticky-header">
      <div className="content">
        <header className="container">
          <h1>
            Storage Location
            <button
              title="Refresh"
              onClick={() => refetch()}
              className="btn text-primary"
            >
              <CachedIcon />
            </button>
          </h1>

          <div className="actions mr-5">
            {/* <CSVLink
              className="btn print d-none"
              filename={`General Ledger Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            /> */}

            <div>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => onSelectStorage(e.target.value)}
              >
                <option value="All">All</option>
                {data?.warehouse?.map((value, i) => (
                  <option key={i} value={value.value}>
                    {value.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="position-relative">
              <RsDateRangePicker
                placement="bottomStart"
                value={
                  filterParams.startDate && filterParams.endDate
                    ? [
                        parse(filterParams.startDate, "yyyy-MM-dd", new Date()),
                        parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                    : []
                }
                onClean={() => clearDateRange()}
                onOk={(date) => filterByDateRange(date)}
              />
            </div>

            <button
              onClick={() => setOpenCreateModal(true)}
              className="btn bg-primary text-white"
            >
              Create New <ExchangeFunds color="white" />
            </button>
            {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                queryParams
              )}`}
              className="btn print mx-4"
            >
              PDF <PDFIcon color="#ff0000" />
            </a>
            <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button> */}
          </div>
        </header>

        <div>
          <Table
            // responsive
            borderless
            striped
            className="product-table container"
          >
            <thead className="position-sticky top-0">
              <tr>
                <th>Storage Location</th>
                <th>WareHouse Name</th>
                <th>Created Date</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {allStorage?.map((ek, i) => (
                <tr key={i}>
                  <td>{ek.storage_location}</td>
                  <td>{ek.WH_details.W_name}</td>
                  <td>{ek.Storage_date}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {allStorage?.length < 1 && <NoTableItem queryParams={queryParams} />}
        <div className="pagination">
          {/* Left */}
          {/* <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div> */}

          {/* right */}
          {/* <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div> */}
        </div>
      </div>
      <ModalLoader show={isFetching} />
      <CreateStorageLocationModal
        show={openCreateModal}
        onHide={() => setOpenCreateModal(false)}
        refetch={refetch}
      />
    </main>
  );
}

export default StorageLocation;
