import { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import "../../assets/scss/scoped/requisition-wrapper.scoped.scss";

export default function RequisitionWrapper(props) {
  return (
    <div className="wrapper">
      <div className="search-area">{}</div>
      {props.Nav} <Outlet />{" "}
    </div>
  );
}
