import {
  ChartPieSliceIcon,
  CubeIcon,
  FlagIcon,
  PathIcon,
  ShoppingBagIcon,
  UserIconModal,
  ShoppingCart,
  Clock,
  Coin,
  TagSimple,
  Book,
} from "../components/Icons";

// export const sideBarItems = [
//   {
//     name: "New Customer",
//     icon: <UserIconModal />,
//     selected: false,
//   },
//   { name: "Make Sales", icon: <ShoppingCart />, selected: false },
//   { name: "Sales History", icon: <Clock />, selected: false },
//   { name: "Other Incomes", icon: <Coin />, selected: false },
//   { name: "Post Payments", icon: <TagSimple />, selected: false },
//   { name: "Vendors Ledger", icon: <Book />, selected: false },
//   {
//     name: "Sales Inventory",
//     icon: <ChartPieSliceIcon />,
//     selected: false,
//   },
//   {
//     name: "Warehouse Inventory",
//     icon: <CubeIcon />,
//     selected: false,
//   },
//   {
//     name: "Inventory \n Issues Log",
//     icon: <FlagIcon />,
//     selected: false,
//   },
//   {
//     name: "Damages \n Tracker",
//     icon: <PathIcon />,
//     selected: false,
//   },
//   {
//     name: "Item Received \nin Sales",
//     icon: <ShoppingBagIcon />,
//     selected: false,
//   },
// ];

export const sideBarItems = [
  {
    name: "Sales Inventory",
    icon: <ChartPieSliceIcon />,
    selected: false,
    to: "/inventory-for-sales/inventory-entry",
  },
  {
    name: "Warehouse Inventory",
    icon: <CubeIcon />,
    selected: false,
  },
  {
    name: "Inventory \n Issues Log",
    icon: <FlagIcon />,
    selected: false,
    to: "/inventory-for-sales/item-issuer",
  },
  {
    name: "Damages \n Tracker",
    icon: <PathIcon />,
    selected: false,
    to: "/inventory-for-sales/inventory-manager/damages-return-recorder",
  },
  {
    name: "Item Received \nin Sales",
    icon: <ShoppingBagIcon />,
    selected: false,
    to: "/receive-inventory",
  },
];
