import photoUrl from "../../assets/images/profile.png";

const UploadImage = ({ onFileUpload, name, image }) => {
  return (
    <div className="rounded-circle text-center w-100 h-100">
      <label className="text-center d-flex p-cursor">
        {image ? (
          <>
            <input
              type="file"
              onChange={onFileUpload}
              name={name}
              className="d-none"
            />
            <img
              src={URL.createObjectURL(image)}
              alt="pics"
              className="w-100 h-100 img-fluid rounded"
            />
          </>
        ) : (
          <>
            <input
              type="file"
              onChange={onFileUpload}
              name={name}
              className="d-none"
            />
            <img
              src={photoUrl}
              alt="pics"
              className="w-100 h-100 img-fluid rounded"
            />
          </>
        )}
      </label>
      <p className="fw-bold">Upload</p>
    </div>
  );
};

export default UploadImage;
