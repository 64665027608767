import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { backendApis } from "../config";
import { useAuth } from "../hooks/useAuth";
import { searchParamsToObject } from "../utils/helpers";
import { useEffectOnce } from "../utils/hooks";
import ModalLoader from "./utils/ModalLoader";

export default function Authorize() {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const setUp = async () => {
    let url = new URL(document.location.href);
    const { company = "", username = "" } = searchParamsToObject(
      url.searchParams.entries()
    );
    const backendApi = backendApis.find((el) => el.name === company);

    if (!backendApi) {
      return toast.error(
        `Company not found - ${company}, invalid authorization link`
      );
    }

    if (!username) {
      return toast.error(`invalid authorization link`);
    }

    // TODO - secure unique key
    const loginResponse = await fetch(`${backendApi.url}/api/auth/authorize`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({ username }),
      credentials: "include",
    });

    if (!loginResponse.ok) {
      return toast.error(
        "Unable to login, check your connection and refresh the page"
      );
    }

    const { user } = await loginResponse.json();
    if (isEmpty(user)) {
      toast.error("User not found in company");
    } else {
      console.log(user);
      setUser({ ...user, company: backendApi.name });
      navigate("/dashboard", { replace: true });
    }
  };

  useEffectOnce(() => {
    setUp();
  });

  return <ModalLoader show={true} />;
}
