import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  customerFullName,
  maxTopPopperConfig,
  reactSelectTheme,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import { useScrollTop, useUpdateEffect } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment";

export default function CreateInvoice() {
  useScrollTop();
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
    {
      icon: <CustomerAccountSelectIcon />,
      label: "Customer Account",
      value: "Customer Account",
    },
    {
      icon: <CashSelectIcon />,
      label: "Loyalty Account",
      value: "Loyalty Account",
    },
    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    },
  ];

  const taxOptions = [
    {
      label: "None",
      value: "None",
      percentage: 0,
    },
    {
      label: "VAT",
      value: "VAT",
      percentage: 0.075,
    },
    {
      label: "WHT",
      value: "WHT",
      percentage: 0.05,
    },
    {
      label: "NCD",
      value: "NCD",
      percentage: 0.01,
    },
  ];
  const { backendUrl } = useAuth();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );

  const [tableData, setTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);

  const createInvoice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createInvoiceMutation = useMutation(
    (payload) => createInvoice(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        if (formik.values.printReciept) {
          window.open(
            `${backendUrl}/api/invoice/pdf/invoice/${data.TransactionID}`,
            "_blank"
          );
        }
        if (formik.values.printWaybill) {
          window.open(
            `${backendUrl}/api/invoice/pdf/waybill/${data.TransactionID}`,
            "_blank"
          );
        }
        formik.resetForm();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      PaymentType: "Cash",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: 1,
    },
    validationSchema: yup.object().shape({
      PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      if (isEmpty(tableData)) return toast.error(`Please add an Item`);
      if (!selectedCustomer) return toast.error(`Please select a customer`);

      createInvoiceMutation.mutate({
        items: tableData,
        ...values,
        subTotal,
        discount,
        amountDue,
        profit,
        balance,
        amount,
        customer: selectedCustomer,
        taxValue,
        costOFSales,
      });
    },
    onReset: () => {
      setTableData([]);
    },
  });

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));
    return data;
  };

  const { data = { invoiceCat: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const handleAddItem = (item) => {
    // console.log(item);
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );
    if (foundIndex === -1) {
      setTableData([...tableData, item]);
    } else {
      tableData[foundIndex] = item;
      setTableData([...tableData]);
    }

    formik.setFieldValue("invoiceCat", item.Product_Name);
    setShowItemSelectorModal(false);
  };

  const handleEditItem = (item) => {
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );

    tableData[foundIndex] = item;
    setTableData([...tableData]);

    setEditedItemIndex(null);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const handleSelectedItem = (item) => {
    if (Number(item.Quantity) < 1) return toast.error(`No item in stock`);
    const UnitPrice = currency(item.UnitPrice, {
      symbol: "",
      separator: "",
    }).format();

    item = {
      ...item,
      Quantity: 1,
      quantityInStock: item.Quantity,
      UnitPrice,
      PriceSold: UnitPrice,
      SubTotal: UnitPrice,
      Warranty: false,
      Discount: 0,
      Profit: currency(UnitPrice, {
        symbol: "",
        separator: "",
      })
        .subtract(item.UnitCost)
        .format(),
    };
    handleAddItem(item);
    setShowItemsPopover(false);
  };

  const handleRowClick = (e, index) => {
    if (e.target.tagName === "TD") setEditedItemIndex(index);
  };

  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) =>
            currency(el.Discount, { symbol: "", separator: "" })
              .multiply(el.Quantity)
              .format()
          )
          .reduce(
            (a, b) =>
              currency(a, {
                symbol: "",
                separator: "",
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const subTotal = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                symbol: "",
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          // we are adding back the discount because we removed before for ui purposes
          .add(discount)
          .format()
      : "0.00";
  }, [tableData, discount]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;

    const total = sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .multiply(100)
          .divide(100)
          .add(taxValue)
          .format()
      : "0.00";

    formik.setFieldValue("amountPaid", total);
    formik.setFieldValue("cashAmount", total);
    return total;
  }, [tableData, taxValue]);

  const balance = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? formik.values.cashAmount
          : formik.values.amountPaid
      )
      .format();
  }, [
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.Profit)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const grandTotal = useMemo(() => {
    return amountDue;
  }, [amountDue]);

  const costOFSales = useMemo(() => {
    return tableData
      .map((el) =>
        currency(el.UnitCost, { symbol: "", separator: "" })
          .multiply(el.Quantity)
          .format()
      )
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  return (
    <main className="create-invoice">
      <PageHeader
        name="Create Invoice"
        description="Create a new transaction invoice with your customer."
        icon={<CreateInvoiceIcon />}
      />
      <div className="p-3 content">
        <div className="d-flex content-holder rounded">
          <section className="item-details">
            <div>
              <header>
                <h1>Invoice</h1>
              </header>
              <div className="actions">
                <div>
                  <h2>Item Details</h2>
                  <p>Add items in to the invoice table. </p>
                </div>
                <div>
                  <Button
                    onClick={() => setShowItemSelectorModal(true)}
                    variant="outline-primary"
                  >
                    + Add Item
                  </Button>
                </div>
              </div>

              <div className="selected-data-area">
                <div className="table-holder">
                  <Table
                    responsive
                    borderless
                    hover
                    striped
                    className="product-table  text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>Serial Number</th>
                        <th>Item Name</th>
                        <th>Price Sold</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                        <th>Discount</th>
                        <th>Barcode</th>
                        <th>Product name</th>
                        <th>Unit Price</th>
                        <th>Profit</th>
                        <th>Warranty</th>
                        <th>Warranty Duration</th>
                        <th>Overwrite officer</th>
                        <th>Cost</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((el, index) => (
                        <tr
                          key={index}
                          onClick={(e) => handleRowClick(e, index)}
                          className="p-cursor"
                        >
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVeritcalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => setEditedItemIndex(index)}
                                >
                                  <EditIcon />
                                  Edit Items
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => handleRemoveItem(index)}
                                >
                                  <DeleteIcon />
                                  Remove Item
                                </Dropdown.Item>
                              </Dropdown.Menu>{" "}
                            </Dropdown>
                          </td>
                          <td>...</td>
                          <td>{el.Item_Name}</td>
                          <td>
                            {currency(el.PriceSold, { symbol: "" }).format()}
                          </td>
                          <td>
                            {currency(el.Quantity, {
                              symbol: "",
                              precision: 1,
                            }).format()}
                          </td>
                          <td>
                            {currency(el.SubTotal, { symbol: "" }).format()}
                          </td>
                          <td>
                            {currency(el.Discount, { symbol: "" }).format()}
                          </td>
                          <td>{el.Bar_Code}</td>
                          <td>{el.Product_Name}</td>
                          <td>
                            {currency(el.UnitPrice, { symbol: "" }).format()}
                          </td>
                          <td>
                            {currency(el.Profit, { symbol: "" }).format()}
                          </td>
                          <td>{JSON.stringify(el.Warranty)}</td>
                          <td>{el.Warrant_Duration}</td>
                          <td>{"..."}</td>
                          <td>
                            {currency(el.UnitCost, { symbol: "" })
                              .multiply(el.Quantity)
                              .format()}
                          </td>
                          <td>{el.Item_Type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                {/*  */}

                <Popover
                  isOpen={showItemsPopover}
                  onClickOutside={() => setShowItemsPopover(false)}
                  content={() => (
                    <ItemsTable
                      hideItemsNotInStock={true}
                      handleSelectedItem={handleSelectedItem}
                    />
                  )}
                >
                  <button
                    onClick={() => setShowItemsPopover(!showItemsPopover)}
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add an Item
                  </button>
                </Popover>

                {/*  No item  */}
                {isEmpty(tableData) ? (
                  <div className="no-item">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">Haven't selected an item yet</h2>
                      <p>
                        You can click +Add Item Button to add an item to the
                        table.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-end total-info">
              <table className="table table-borderless balance">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td>
                      {currency(subTotal, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Discount</td>
                    <td>
                      {/*  <button className="btn text-primary p-0">
                        +Add Discount
                      </button> */}

                      {currency(discount, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Amount Due</td>
                    <td>
                      {currency(amountDue, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Balance</td>
                    <td>
                      {currency(balance, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Total</td>
                    <td>
                      {currency(grandTotal, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className="customer">
            <h2>Customer</h2>

            <div className="d-flex justify-content-between">
              <div className="avatar">
                <UserSolidIcon />
              </div>
              {selectedCustomer ? (
                <div className="customer-actions d-flex justify-content-between flex-grow-1">
                  <div>
                    <h3>{selectedCustomer?.LastName}</h3>
                    <p>{selectedCustomer.Cust_ID}</p>
                  </div>

                  <div>
                    <Dropdown style={{ margin: 0 }}>
                      <Dropdown.Toggle
                        variant=""
                        className="bg-light-blue text-primary"
                        bsPrefix="change"
                      >
                        Change
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className=""
                      >
                        <Dropdown.Item
                          as="button"
                          onClick={() => setShowCustomerSelectorModal(true)}
                        >
                          Select Customer
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={() => setShowCreateNewCustomerModal(true)}
                        >
                          Create New Customer
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : (
                <div className="customer-actions flex-grow-1">
                  <h3>No customer selected</h3>
                  <p>Select customer or create new customer.</p>

                  <div className="d-grid mt-4">
                    <Button
                      onClick={() => setShowCustomerSelectorModal(true)}
                      variant="outline-primary"
                    >
                      Select Customer
                    </Button>
                    <Button
                      onClick={() => setShowCreateNewCustomerModal(true)}
                      variant="outline-primary"
                    >
                      + Create New Customer
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <section className="date">
                <h2>Invoice Date</h2>

                <Row className="form-mb">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Sales Date</Form.Label>
                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="salesDate"
                      inputProps={{
                        className: `date-input form-control ${
                          formik.touched.salesDate && !!formik.errors.salesDate
                            ? "is-invalid"
                            : ""
                        }`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.salesDate}
                      onChange={(date) => {
                        formik.setFieldValue("salesDate", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("salesDate", true)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>Invoice Cat </Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Select"
                      isSearchable={false}
                      options={data.invoiceCat}
                      value={data.invoiceCat.find(
                        (el) => el.value === formik.values.invoiceCat
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("invoiceCat", value)
                      }
                      onBlur={() => formik.setFieldTouched("invoiceCat", true)}
                      className={
                        formik.touched.invoiceCat && !!formik.errors.invoiceCat
                          ? "is-invalid"
                          : ""
                      }
                    />
                    {formik.touched.invoiceCat && formik.errors.invoiceCat ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.invoiceCat}
                      </span>
                    ) : null}
                  </Form.Group>
                </Row>

                <Form.Group className="form-mb">
                  <Form.Label className="mb-1">Proforma No.</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Proforma No."
                    name="proformaNumber"
                    value={formik.values.proformaNumber}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </section>

              <section className="date">
                <h2>Sales Information</h2>
                <Form.Label>Sales Type</Form.Label>
                <div
                  key={`inline-radio`}
                  className="d-flex justify-content-between"
                >
                  <Form.Check
                    inline
                    label="Retail"
                    name="OverwriteOfficer"
                    type={"radio"}
                    id={`OverwriteOfficer`}
                    checked={formik.values.OverwriteOfficer === "Retail"}
                    onChange={formik.handleChange}
                  />
                  <Form.Check
                    inline
                    label="Wholesale"
                    name="OverwriteOfficer"
                    type={"radio"}
                    id={`OverwriteOfficer`}
                    checked={formik.values.OverwriteOfficer === "Wholesale"}
                    onChange={formik.handleChange}
                  />
                  <Form.Check
                    inline
                    label="Distributor"
                    name="OverwriteOfficer"
                    type={"radio"}
                    id={`OverwriteOfficer`}
                    checked={formik.values.OverwriteOfficer === "Distributor"}
                    onChange={formik.handleChange}
                  />
                </div>

                <Form.Group controlId="formGridPassword" className="mb-3">
                  <Form.Label>Printer Type</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Select printer"
                    isSearchable={false}
                  />

                  <div className="mt-2 form-mb d-flex gap-3">
                    <Form.Check
                      inline
                      label="Send Email"
                      name="sendEmail"
                      type={"checkbox"}
                      checked={formik.values.sendEmail}
                      onChange={formik.handleChange}
                    />
                    <Form.Check
                      inline
                      label="Print Receipt"
                      name="printReciept"
                      type={"checkbox"}
                      checked={formik.values.printReciept}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Form.Group>
              </section>

              <section className="date">
                <h2>Payment Details</h2>

                <div className="row">
                  <Form.Group className="col-6 form-mb">
                    <Form.Label>Payment method</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPlacement="top"
                      placeholder="Choose method"
                      isSearchable={false}
                      value={paymentMethod.find(
                        (el) => el.value === formik.values.PaymentType
                      )}
                      options={paymentMethod}
                      onChange={({ value }) =>
                        formik.setFieldValue("PaymentType", value)
                      }
                      getOptionLabel={(el) => (
                        <div className="label-with-icon d-flex gap-2 align-items-center">
                          <span>{el.icon}</span>{" "}
                          <span className="fw-5">{el.label}</span>
                        </div>
                      )}
                      //  menuIsOpen={true}
                    />
                  </Form.Group>

                  {formik.values.PaymentType === "Split Payment" ? (
                    <Form.Group className="col-6">
                      <Form.Label>Cash Amount</Form.Label>
                      <CurrencyCustomInput
                        name="cashAmount"
                        value={formik.values.cashAmount}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="col-6">
                      <Form.Label>Amount Paid</Form.Label>
                      <CurrencyCustomInput
                        name="amountPaid"
                        value={formik.values.amountPaid}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>
                  )}
                </div>

                {/* Split Payment */}
                {formik.values.PaymentType === "Split Payment" ? (
                  <div>
                    <div className="d-flex justify-content-between mb-2 pb-1">
                      <Form.Check
                        inline
                        label="Card"
                        name="splitPaymentType"
                        value="card"
                        type={"radio"}
                        id={`splitPaymentType-1`}
                        checked={formik.values.splitPaymentType === "card"}
                        onChange={formik.handleChange}
                      />
                      <Form.Check
                        inline
                        label="Direct Transfer"
                        value="directTransfer"
                        name="splitPaymentType"
                        type={"radio"}
                        id={`splitPaymentType-2`}
                        checked={
                          formik.values.splitPaymentType === "directTransfer"
                        }
                        onChange={formik.handleChange}
                      />
                      <Form.Check
                        inline
                        label="Cheque"
                        value="cheque"
                        name="splitPaymentType"
                        type={"radio"}
                        id={`splitPaymentType-3`}
                        checked={formik.values.splitPaymentType === "cheque"}
                        onChange={formik.handleChange}
                      />
                    </div>

                    <Form.Group className="form-mb">
                      <Form.Label>Amount</Form.Label>
                      <CurrencyCustomInput value={amount} placeholder="0.00" />
                    </Form.Group>
                  </div>
                ) : null}

                <section>
                  <div className="row form-mb">
                    <Form.Group className="col-6" controlId="formGridPassword">
                      <Form.Label>Tax </Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select"
                        isSearchable={false}
                        value={taxOptions.find(
                          (el) => el.value === formik.values.taxType
                        )}
                        options={taxOptions}
                        onChange={({ value }) =>
                          formik.setFieldValue("taxType", value)
                        }
                      />
                    </Form.Group>

                    <Form.Group className="col-6">
                      <Form.Label>
                        {formik.values.taxType &&
                        formik.values.taxType !== "None"
                          ? formik.values.taxType
                          : "VAT"}
                      </Form.Label>
                      <CurrencyCustomInput
                        name="tax"
                        value={taxValue}
                        onValueChange={(value, name) => {
                          //   formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>
                  </div>

                  <Form.Group className="form-mb">
                    <Form.Label className="mb-1">Po No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Po No."
                      name="poNumber"
                      value={formik.values.poNumber}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </section>

                <section>
                  <div className="row">
                    <Form.Group className="col-6 mt-2 form-mb">
                      <Form.Label>Shipping Cost</Form.Label>
                      <CurrencyCustomInput
                        name="shippingCost"
                        value={formik.values.shippingCost}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>

                    <Form.Group className="col-6 mt-2 form-mb">
                      <Form.Label>Due In (days)</Form.Label>
                      <NumberCustomInput
                        name="dueIn"
                        value={formik.values.dueIn}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div className="d-flex gap-3">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Supply now"
                      className="mb-3"
                      checked={formik.values.supplyNow}
                      onChange={formik.handleChange}
                    />
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="printWaybill"
                      label="Print Waybill"
                      className="mb-3"
                      checked={formik.values.printWaybill}
                      onChange={formik.handleChange}
                    />
                  </div>
                </section>
              </section>

              <section className="buttons">
                <Button
                  type="button"
                  variant="outline-primary"
                  // className="border-0"
                  onClick={() => formik.resetForm()}
                >
                  Discard Invoice
                </Button>
                <Button type="submit" variant="primary">
                  Send Invoice
                </Button>
              </section>
            </Form>
          </section>
        </div>
      </div>

      {/*   Modals */}

      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}

      {showItemSelectorModal && (
        <AddItemModal
          showItemSelectorModal={showItemSelectorModal}
          setShowItemSelectorModal={setShowItemSelectorModal}
          handleAddItem={handleAddItem}
        />
      )}

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {/*    <NewItemModal /> */}

      {editedItemIndex !== null && (
        <EditItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItem={tableData[editedItemIndex]}
        />
      )}
    </main>
  );
}
