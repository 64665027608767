import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, {
  useEffectOnce,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import currency from "currency.js";
import Datetime from "react-datetime";
import { format, startOfMonth, parse } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";

const options = [
  { label: "Truck", value: "Truck" },
  { label: "Trailer", value: "Trailer" },
  { label: "Tanker", value: "Tanker" },
  { label: "Vehicle", value: "Vehicle" },
  { label: "Other", value: "Other" },
];

export default function TruckExpensesModal({
  selectedVehicle,
  setSelectedVehicle,
  queryParams: parentQueryParams,
  // setQueryParams,
}) {
  const initialFilterParams = {
    /* startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"), */
    RegNo: selectedVehicle.RegNo,
  };

  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    ...initialFilterParams,
    ...parentQueryParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getTruckExpenses = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/transport/truck/expenses?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { expenses: [] } } = useQuery(
    [queryActions.ALL_PERMITS, debouncedqueryParams],
    () => getTruckExpenses(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <Modal
      show={true}
      onHide={() => setSelectedVehicle(null)}
      dialogClassName="requisition-details-modal approve-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Trucks Maintenance History</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4  d-flex flex-column">
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4">
          <div className="global-search-area  flex-grow-1 py-0">
            <MagnifyIcon />
            <Form.Control
              id="queryParams-q"
              className=""
              name="q"
              value={queryParams.q}
              onChange={(e) => handleSearchQueryChange(e)}
              placeholder="Search..."
            />
          </div>

          <Select
            classNamePrefix={"form-select"}
            isSearchable={false}
            placeholder="Sort by Type"
            options={options}
            value={options.find((el) => el.value === queryParams.type)}
            onChange={({ value }) =>
              setQueryParams({
                ...queryParams,
                page: 1,
                type: value,
              })
            }
          />

          <Form.Group>
            <RsDateRangePicker
              placement="bottomEnd"
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />
          </Form.Group>
        </div>

        <div className="content px-3 pb-4 pe-2 flex-grow-1">
          <Table borderless responsive striped className="product-table">
            <thead className="sticky border-bottom">
              <tr>
                <th>Vehicle</th>
                <th>Reg Number</th>
                <th>Type</th>
                <th>Cost</th>
                <th>Maintenance On</th>
                <th>Vendor</th>
                <th>Trans ID</th>
                <th>Reg Date</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data.expenses &&
                data.expenses.map((el, index) => (
                  <tr key={index} className={`p-cursor`}>
                    <td>{el.Vehicle}</td>
                    <td>{el.RegNo}</td>
                    <td>{el.Type}</td>
                    <td>
                      {currency(el.Cost, {
                        symbol: "",
                      }).format()}
                    </td>
                    <td>{format(new Date(el.RegDate), "do MMM, yyyy")}</td>
                    <td>{el.Vendor}</td>
                    <td>{el.ExpenseID}</td>
                    <td>{format(new Date(el.RegDate), "do MMM, yyyy")}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
