import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import { fetchActionsUtil } from "../utils/helpers";

export const useDownloadExcel = (excelStateData, CSVLinkRef) => {
  const [isfetchingExcel, setIsfetchingExcel] = useState(false);
  const fetchExcelData = async (url, method, token, payload) => {
    let data;
    setIsfetchingExcel(true);
    try {
      const mainData = await fetchActionsUtil(url, method, token, payload);
      data = mainData;
      setIsfetchingExcel(false);
    } catch (error) {
      toast.error(error.message);
      console.log(error);
      setIsfetchingExcel(false);
    }
    return data;
  };

  useEffect(() => {
    if (excelStateData.length > 0) CSVLinkRef.current.link.click();
  }, [excelStateData, CSVLinkRef]);

  return [isfetchingExcel, fetchExcelData];
};
