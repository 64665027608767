import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css";
// import "./assets/fonts/stylesheet.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import store from "./store";

import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { AuthProvider } from "./hooks/useAuth";
// import { ReactQueryDevtools } from "react-query/devtools";
import "react-datetime/css/react-datetime.css";
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
      //staleTime: twentyFourHoursInMs,
    },
  },
  queryCache: new QueryCache({
    onError: async (error, query) => {
      console.log("", error);
      if (String(error).includes("not logged in")) {
        localStorage.clear();
        window.location.href = "/login";
      }
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
