import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, {
  useEffectOnce,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import currency from "currency.js";
import Datetime from "react-datetime";
import { format, startOfMonth, parse } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { DeleteIcon, EditIcon } from "../Icons";
import { toast } from "react-toastify";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import CurrencyInput from "react-currency-input-field";
import BatchQuantityDialog from "../BatchQuantityDialog";
import { isEmpty } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import ConvertQuantity from "../utils/ConvertQuantity";
import { Link } from "react-router-dom";

export default function ItemConversionHistoryModal({
  selectedItem = null,
  setSelectedItem,
  setShowConversionHistoryModal,
}) {
  const initialFilterParams = {
    RegDate: "",
    ConvertedBy: "",
    RawMaterialBarcode: selectedItem.RawMaterialBarcode,
    FinishedItemBarcode: selectedItem.FinishedItemBarcode,
  };
  const queryClient = useQueryClient();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getConversionHistory = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/items/conversion-history?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.staff = [
      {
        label: "All Staff",
        value: "",
      },
      ...data.staff.map((el) => ({ label: el.Name, value: el.Name })),
    ];
    return data;
  };

  const { data = { history: [], staff: [] }, refetch } = useQuery(
    ["ITEM_CONVERSION_HISTORY", debouncedqueryParams],
    () => getConversionHistory(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  return (
    <Modal
      show={true}
      onHide={() => {
        setSelectedItem(null);
        setShowConversionHistoryModal(false);
      }}
      dialogClassName="requisition-details-modal approve-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Conversion History </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 d-flex flex-column pt-0">
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
          <h2>
            {selectedItem.RawMaterialItemName} to{" "}
            {selectedItem.FinishedItemName}
          </h2>
        </div>

        <div className="col-6 d-flex gap-3 align-items-center pe-3 mb-3">
          {/* <div className="global-search-area w-75">
            <MagnifyIcon />
            <input
              className="form-control search-input"
              name="InvoiceNo"
              value={queryParams.InvoiceNo}
              onChange={(e) =>
                setQueryParams({
                  ...queryParams,
                  page: 1,
                  [e.target.name]: e.target.value,
                })
              }
              placeholder="Invoice number"
              autoFocus
              autoComplete="off"
              type={"text"}
            />
          </div> */}

          <Select
            className="col-6"
            placeholder="Converted By"
            options={data.staff}
            value={data.staff.find(
              (el) => el.value === queryParams?.ConvertedBy
            )}
            onChange={({ value }) =>
              setQueryParams({
                ...queryParams,
                ConvertedBy: value,
              })
            }
          />

          <Datetime
            dateFormat="MMM DD, YYYY"
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside={true}
            inputProps={{
              className: `date-input form-control`,
              placeholder: "Select Date",
              readOnly: true,
            }}
            value={queryParams.RegDate}
            onChange={(date) => {
              setQueryParams({
                ...queryParams,
                RegDate: date,
              });
            }}
          />
        </div>

        <div className="content px-1 pb-4 pe-2 flex-grow-1">
          <Table borderless responsive striped className="product-table">
            <thead className="sticky border-bottom">
              <tr>
                <th>Date</th>

                <th>Raw Material Barcode</th>
                <th>Raw Material Item Name</th>
                <th>Raw Material Quantity</th>
                <th>Raw Material Size</th>

                <th>Finished Item Barcode</th>
                <th>Finished Item Name</th>
                <th>Finished Item Quantity</th>
                <th>Finished Item Size</th>

                <th>Converted By</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data?.history &&
                data.history.map((el, index) => (
                  <tr key={index} className={`p-cursor`}>
                    <td className="text-nowrap">
                      {el?.Date
                        ? format(new Date(el?.Date), "dd MMM, yyyy")
                        : ""}
                    </td>
                    <td>{el?.RawMaterialBarcode}</td>
                    <td>{el?.RawMaterialItemName}</td>
                    <td>
                      <ConvertQuantity
                        quantity={el?.RawMaterialQuantity}
                        desc={selectedItem?.rawMaterialItemData?.Item_Desc}
                      />
                    </td>
                    <td>{el?.RawMaterialSize}</td>
                    <td>{el?.FinishedItemBarcode}</td>
                    <td>{el?.FinishedItemName}</td>
                    <td>
                      <ConvertQuantity
                        quantity={el?.FinishedItemQuantity}
                        desc={selectedItem?.finishedItemData?.Item_Desc}
                      />
                    </td>
                    <td>{el?.FinishedItemSize}</td>
                    <td className="text-nowrap">{el.ConvertedBy}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          {/*  <p className="fw-6">
            <ConvertQuantity
              quantity={data?.totalQty}
              desc={data?.item?.Item_Desc}
            />
          </p>

          <p className="fw-6">
            {currency(data?.valueInStock, { symbol: "" }).format()}
          </p> */}

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              scrollToTop();
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
