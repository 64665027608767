import { format } from "date-fns";
import { forwardRef } from "react";
import { CalendarIcon } from "../Icons";
import "./../../assets/scss/scoped/datepicker.scoped.scss";

const DatePickerCustomInput = forwardRef(
  ({ value, onClick, className, name }, ref) => (
    <button
      onClick={onClick}
      ref={ref}
      id={name}
      className={`btn date-picker-btn d-flex gap-2 align-items-center bg-white ${className}`}
      type="button"
      value={value}
    >
      <span>{value}</span>

      <CalendarIcon />
    </button>
  )
);

export default DatePickerCustomInput;
