import { Table } from "react-bootstrap";
import {
  CheckCircleIcon,
  UserIconTwo,
  MoneyDollarIcon,
  PageHeaderListIcon
} from "../../../components/Icons";
import "./../../../assets/scss/reports/cashbook.scss";
import { useRef } from "react";
import { Outlet } from "react-router-dom";

export default function SalesRepReports({ Nav }) {
  const ref = useRef(null);

  return (
    <main className="cash-book">
      <div className="content">
        <section className="salesRepStats pb-4">
          <div className="stat">
            <div className="icon">
              <UserIconTwo />
            </div>
            <div className="details">
              <p>1223</p>
              <span>Customers</span>
            </div>
          </div>

          <div className="stat">
            <div className="icon icon-1">
              <PageHeaderListIcon />
            </div>
            <div className="details">
              <p>$1,611,599,760.57</p>
              <span>Today Sales</span>
            </div>
          </div>

          <div className="stat">
            <div className="icon icon-2">
            <MoneyDollarIcon />
            </div>
            <div className="details">
              <p>0.0</p>
              <span>Payments</span>
            </div>
          </div>

          <div className="stat">
            <div className="icon icon-3">
              <CheckCircleIcon />
            </div>
            <div className="details">
              <p>0.00</p>
              <span>Receivable</span>
            </div>
          </div>      
        </section>

        <div className="position-relative">
          <div className="title-actions-holder" />
          {Nav}
          <Outlet />
        </div>
      </div>
    </main>
  );
}
