import { useRef } from "react";
import { Popover, Overlay } from "react-bootstrap";
import { maxTopPopperConfig } from "../../utils/helpers";
import CustomerSelector from "./CustomerSelector";
import queryString from "query-string";

export default function CustomerSelectorPopover({
  showCustomerSelector,
  setShowCustomerSelector,
  ...props
}) {
  const ref = useRef();
  return (
    <div ref={ref}>
      <Overlay
        show={showCustomerSelector}
        onHide={() => setShowCustomerSelector(false)}
        target={ref.current}
        container={document.body}
        containerPadding={0}
        rootClose={true}
        popperConfig={{
          strategy: "fixed",
          modifiers: [maxTopPopperConfig({ maxTop: -85 })],
        }}
        placement="right-end"
      >
        <Popover
          style={{
            maxWidth: "unset",
            height: "36.25rem",
            overflow: "hidden",
            boxShadow: "0px 0px 60px 0px #0000000D",
          }}
        >
          <CustomerSelector
            {...props}
            showCustomerSelector={showCustomerSelector}
            setShowCustomerSelector={setShowCustomerSelector}
          />
        </Popover>
      </Overlay>
    </div>
  );
}
