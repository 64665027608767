import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  copyText,
  customerFullName,
  getDataForEdit,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  scrollToTop,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  MoneyDollarIcon,
  LinkVariantIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { cloneDeep, isEmpty, lowerCase, mapValues, keyBy } from "lodash";
import lodash from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import {
  useEffectOnce,
  useIsAdmin,
  useIsCashier,
  useUpdateEffect,
} from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import TransactionsTable from "../utils/TransactionsTable";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./../utils/ModalLoader";
import moment from "moment";
import AllTransactionsTable from "../utils/AllTransactionsTable";
import { useLocation, useNavigate } from "react-router-dom";

export default function DeletePayment() {
  useEffectOnce(() => scrollToTop());
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showPermitModal, setShowPermitModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [paymentMeduims, setPaymentMeduims] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);

  const [selectedSale, setSelectedSale] = useState({});

  const deleteTransaction = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/delete-payment`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteTransactionMutation = useMutation(
    (payload) => deleteTransaction(payload),
    {
      onSuccess: ({ data, message }) => {
        toast.success(message);
        discard();
        refetch();
        // setTransactionID(null);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const [TransactionID, setTransactionID] = useState("");
  useEffectOnce(() => {
    if (location.search) {
      const queryParams = queryString.parse(location.search);
      setTransactionID(queryParams.TransactionID);
      // navigate(location.pathname, { replace: true });
    }
  });
  const formik = useFormik({
    initialValues: {
      date: moment(),
      Remark: "",
      deletePayment: true,
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values) => {
      const paymentMeduimsToDelete = paymentMeduims.filter((el) => el.checked);

      const paymentMeduimsSummary = {};
      for (const [index, value] of paymentMeduimsToDelete.entries()) {
        const str = [...Array(index)].map((el) => `_`).join("");
        const bank = value?.BankName ? `- ${value?.BankName}` : "";
        const theKey = `${value.PaymentType} ${bank}`;

        if (paymentMeduimsSummary[theKey]) {
          paymentMeduimsSummary[`${theKey}${str}`] = value.Credit;
        } else {
          paymentMeduimsSummary[theKey] = value.Credit;
        }
      }
      //----

      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
          ...(values.deletePayment
            ? {
                summaryTitle: "Payment to delete",
                /*  summary: mapValues(
                  keyBy(paymentMeduimsToDelete, "PaymentType"),
                  "Credit"
                ), */
                summary: paymentMeduimsSummary,
                removeUnderscore: true,
              }
            : {}),
        })
      ) {
        deleteTransactionMutation.mutate({
          TransactionID,
          ...values,
          customer: data.customer,
          paymentMeduimsToDelete,
        });
      }
    },
    onReset: () => {},
  });

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(null);
  };

  const handleSelectedTransaction = (transaction) => {
    setTransactionID(transaction?.TransactionID || transaction?.Trans_ID);
    setShowTransactionPopover(false);

    // fetch sales data
  };

  const getPayments = async ({ TransactionID }) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/transaction/payments/${TransactionID}?withTransaction=true&withLedgerRecords=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    /*  if (!isEmpty(selectedSale) && !isEmpty(data?.sales) && TransactionID) {
      handleSelectedSale(
        data.sales.find((sale) =>
          sale?.ID
            ? selectedSale.ID === sale?.ID
            : selectedSale.Bar_Code === sale.Bar_Code &&
              sale.TransactionID === TransactionID
        )
      );
    } */
    if (data?.ledgerRecords) {
      const queryParams = queryString.parse(location.search);

      setPaymentMeduims(
        data.ledgerRecords.map((el) => ({
          ...el,
          checked:
            el.TrackID !== "Deleted" &&
            (!isEmpty(queryParams)
              ? el.PaymentType === queryParams.PaymentType &&
                Number(el.Balance) === Number(queryParams.Balance)
              : true),
        }))
      );

      if (!isEmpty(queryParams)) {
        navigate(location.pathname, { replace: true });
      }
    }
    return data;
  };

  const transaction = useMemo(() => cloneDeep({ TransactionID }), [
    TransactionID,
  ]);

  const { data = { sales: [] }, refetch } = useQuery(
    ["GET_PAYMENTS", transaction],
    () => getPayments(transaction),
    {
      keepPreviousData: false,
    }
  );

  const handlePaymentMediumCheckBox = (e, ledger) => {
    setPaymentMeduims((oldData) =>
      oldData.map((el) =>
        el.PaymentType === ledger.PaymentType &&
        Number(el.Balance) === Number(ledger.Balance)
          ? { ...el, checked: e.target.checked }
          : { ...el }
      )
    );
  };

  return (
    <main className="create-invoice">
      <PageHeader
        name="Delete Payment"
        description=""
        icon={<MoneyDollarIcon />}
      />
      <div className="p-3 content">
        <div className="d-md-flex content-holder rounded">
          <section
            /*  style={
              formik.values.pendingTransaction
                ? {
                    pointerEvents: "none",
                  }
                : {}
            } */
            className="item-details"
          >
            <div>
              <header className="h-auto d-flex justify-content-between">
                <Form.Group className="mt-4 mb-3 col-md-6">
                  <InputGroup>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Transaction ID"
                      name="TransactionID"
                      value={TransactionID}
                      onChange={(e) => setTransactionID(e.target.value)}
                    />

                    <Popover
                      isOpen={showTransactionPopover}
                      onClickOutside={() => setShowTransactionPopover(false)}
                      content={() => (
                        <AllTransactionsTable
                          handleSelectedTransaction={handleSelectedTransaction}
                        />
                      )}
                      position="bottom"
                    >
                      <InputGroup.Text
                        onClick={() =>
                          setShowTransactionPopover(!showTransactionPopover)
                        }
                      >
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </Popover>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Bar_Code}
                  </Form.Control.Feedback>
                </Form.Group>

                {TransactionID && (
                  <Button
                    variant="light-blue"
                    onClick={() =>
                      copyText(
                        `${
                          services.frontendUrl
                        }/sales-and-invoicing/delete-payment?TransactionID=${TransactionID}`
                      )
                    }
                  >
                    <LinkVariantIcon /> Copy Link
                  </Button>
                )}
              </header>

              <div className="selected-data-area">
                <div className="table-holder">
                  <Table
                    responsive
                    borderless
                    hover
                    striped
                    className="product-table  text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th>Payment type</th>
                        <th>Amount</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentMeduims.map((el, index) => (
                        <tr
                          key={index}
                          style={
                            el?.TrackID === "Deleted"
                              ? {
                                  pointerEvents: "none",
                                  opacity: 0.5,
                                }
                              : {}
                          }
                        >
                          <td>
                            {el.PaymentType}{" "}
                            {el?.BankName ? `- ${el?.BankName}` : ""}
                          </td>
                          <td>
                            {currency(el.Credit, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {el?.TrackID === "Deleted" ? (
                              "Reversed"
                            ) : (
                              <Form.Check
                                inline
                                label=""
                                name="deletePayment"
                                type={"checkbox"}
                                checked={el.checked}
                                onChange={(e) =>
                                  handlePaymentMediumCheckBox(e, el)
                                }
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                {/*  */}

                {/*  No item  */}
                {isEmpty(paymentMeduims) ? (
                  <div className="no-item my-4">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">No Payments Found</h2>
                      <p>...</p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {/*    <div className="d-flex justify-content-end total-info d-none">
              <table className="table table-borderless balance">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td>
                      {currency(subTotal, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Discount</td>
                    <td>
                     

                      {currency(discount, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Amount Due</td>
                    <td>
                      {currency(amountDue, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Balance</td>
                    <td>
                      {currency(balance, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Total</td>
                    <td>
                      {currency(grandTotal, {
                        symbol: "₦",
                      }).format()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </section>
          <section className="customer">
            <h2>Customer</h2>

            <div className="d-flex justify-content-between">
              <div className="avatar">
                <UserSolidIcon />
              </div>
              {data?.customer ? (
                <div className="customer-actions d-flex justify-content-between flex-grow-1">
                  <div>
                    <h3>{data?.customer?.LastName}</h3>
                    <p>{data?.customer?.Cust_ID}</p>
                  </div>
                </div>
              ) : (
                <div className="customer-actions flex-grow-1">
                  <h3>No customer selected</h3>
                  <p>...</p>
                </div>
              )}
            </div>

            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <section>
                <h2 className="px-2">Payment Status</h2>
                <Table borderless className="mb-2">
                  <tbody>
                    <tr>
                      <td className="fw-bold">Amount Due:</td>
                      <td>
                        {currency(data?.transaction?.Amount_Due, {
                          symbol: "",
                        }).format()}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold">Amount Paid:</td>
                      <td>
                        {currency(data?.transaction?.Amount_Paid, {
                          symbol: "",
                        }).format()}
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold">Balance:</td>
                      <td>
                        {currency(data?.transaction?.Balance, {
                          symbol: "",
                        }).format()}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <hr className="my-2" />

                <div className="px-2">
                  <Form.Group className="mb-3">
                    <Form.Label>Transaction Remark</Form.Label>

                    <Form.Control
                      maxLength={250}
                      className=""
                      as="textarea"
                      placeholder="Enter Remark"
                      name="Remark"
                      value={formik.values.Remark}
                      onChange={formik.handleChange}
                      rows={5}
                    />
                  </Form.Group>
                </div>
              </section>

              <section className="buttons">
                {isAdmin || (appSettings.isGigc && isCashier) ? (
                  <>
                    <Button
                      type="button"
                      variant="outline-primary"
                      // className="border-0"
                      onClick={() => discard()}
                    >
                      Refresh
                    </Button>
                    <Button type="submit" variant="primary">
                      Delete Transaction
                    </Button>
                  </>
                ) : null}
              </section>
            </Form>
          </section>
        </div>
      </div>

      <ModalLoader show={deleteTransactionMutation.isLoading} />
    </main>
  );
}
