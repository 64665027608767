import { Table, InputGroup, Button, Dropdown, Form } from "react-bootstrap";
//import ChevronDownIcon from "mdi-react/ChevronDownIcon";
// import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import CachedIcon from "mdi-react/CachedIcon";
import {
  // CalendarIcon,
  // CubeIcon,
  DeleteIcon,
  // ExportIcon,
  FIleUploadLineIcon,
  FilterCollapseIcon,
  FilterTwoIcon,
  // PrintIcon,
} from "../../Icons";
import "../../../assets/scss/reports/cashbook.scss";
import { useEffect, useState } from "react";
// import DateRangePicker from "../../utils/DateRangePicker";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { isEmpty } from "lodash";

import { queryActions } from "../../../utils/reactQueryActions";
import useDebounce, {
  useIsAdmin,
  useIsStore,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../../utils/hooks";
// import { services } from "../../../config";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  defaultSelectValue,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
} from "../../../utils/helpers";
import RsDateRangePicker from "../../utils/RsDateRangePicker";
import { useAuth } from "../../../hooks/useAuth";
// import NewItemModal from "../../NewItemModal";
// import PurchaseDialog from "../../PurchaseDialog";
// import NewItemServiceModal from "../../NewItemServiceModal";
// import NewVendorModal from "../../NewVendorModal";
import BatchesModal from "./BatchesModal";
import LoginDialog from "../../LoginDialog";
import ConfirmDialog from "../../ConfirmDialogue";
import AddNewStock from "./AddNewStock";
import UpdateStockModal from "./UpdateStock";
import NoTableItem from "../../utils/NoTableItem";
import { fetchActionsUtil } from "../../../utils/helpers";
import AddNewAssets from "./AddAssets";
import WarehouseItemTable from "../WarehouseItemTable";

export default function WarehouseInventoryListEntry() {
  const queryClient = useQueryClient();
  const { backendUrl, isIronRod, isCement } = useAuth();
  const isAdmin = useIsAdmin();
  const isStore = useIsStore();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [selectedItem, setSelectedItem] = useState(null);
  useScrollTop();

  const [showUpdateInventoryModal, setShowUpdateInventoryModal] = useState(
    false
  );

  const [
    showUpdateInventoryAssetModal,
    setShowUpdateInventoryAssetModal,
  ] = useState(false);
  const [showBatchesModal, setShowBatchesModal] = useState(false);

  const [
    showCreateNewInventoryModal,
    setShowCreateNewInventoryModal,
  ] = useState(false);

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    product: "",
    category: "",
    branch: "",
    withProduct: true,
    withCategory: true,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const fetchStockItems = async (queryParams, ware) => {
    // await waitFor(5000);
    const data = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/items/${ware}?&${queryString.stringify(
        queryParams
      )}`,
      "GET"
    );
    return data?.data;
  };

  const fetchAssets = async (queryParams, ware) => {
    // await waitFor(5000);
    const data = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/assets/${ware}?&${queryString.stringify(
        queryParams
      )}`,
      "GET"
    );
    return data?.data;
  };

  // console.log(wareshouseLocal);
  const {
    data = { count: 0, warehouses: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [queryActions.ITEMS, { queryParams, wareshouseLocal }],
    () =>
      wareshouseLocal?.cater === "Assets"
        ? fetchAssets(queryParams, wareshouseLocal?.whID)
        : fetchStockItems(queryParams, wareshouseLocal?.whID),
    {
      keepPreviousData: true,
    }
  );

  console.log(data);
  const handleShowBatches = (item) => {
    setSelectedItem(item);
    setShowBatchesModal(true);
  };

  const deleteStock = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/stock-delete`, {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteAssets = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/assets-delete`, {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteMutation = useMutation(
    (payload) =>
      wareshouseLocal?.cater === "Assets"
        ? deleteAssets(payload)
        : deleteStock(payload),
    {
      onSuccess: ({ message, data: { item } }) => {
        toast.success(message);

        queryClient.setQueryData([queryActions.ITEMS, queryParams], (data) => {
          data.items = data.items.filter((el) => el.Bar_Code !== item.Bar_Code);
          return data;
        });
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const handleDeleteItem = async (item) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Item",
          description:
            "Are you sure you want to delete this Item from your inventory",
        })
      ) {
        deleteMutation.mutate(item);
      }
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Barcode</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          placeholder="Enter barcode"
                          value={filterParams?.barcode}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <WarehouseItemTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Product</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        name="product"
                        isSearchable={true}
                        key={data?.product}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            product: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.product,
                          data?.product,
                          { value: "", label: "Select" }
                        )}
                        options={data?.product || []}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Category</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        name="category"
                        isSearchable={true}
                        key={data?.category}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )}
                        options={data?.category || []}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Branch</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        name="branch"
                        isSearchable={true}
                        key={data?.branch}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            branch: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.branch,
                          data?.branch,
                          { value: "", label: "All" }
                        )}
                        options={data?.branch || []}
                      />
                    </Form.Group>
                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Inventory
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                    title="Refresh"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  {/*  <DateRangePicker
              apply={(date) => filterByDateRange(date)}
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
            /> */}
                  {/*  <RsDateRangePicker
              placement="bottomEnd"
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            /> */}

                  {isAdmin || isStore ? (
                    <>
                      {isIronRod && (
                        <>
                          {wareshouseLocal?.cater === "Assets" ? (
                            <button
                              className="btn bg-primary text-white"
                              onClick={() =>
                                setShowUpdateInventoryAssetModal(true)
                              }
                            >
                              + Create New
                            </button>
                          ) : (
                            <button
                              className="btn bg-primary text-white"
                              onClick={() =>
                                setShowCreateNewInventoryModal(true)
                              }
                            >
                              + Create New
                            </button>
                          )}
                          {/* <button
                            onClick={() => setShowUpdateInventoryModal(true)}
                            className="btn sendSms"
                          >
                            Update Inventory <FIleUploadLineIcon /> 
                          </button> */}
                        </>
                      )}

                      {isCement && (
                        <>
                          <Link className="btn btn-primary px-3" to="/load-atc">
                            Load ATC
                          </Link>
                        </>
                      )}
                    </>
                  ) : null}

                  {/*  <button className="btn print">
                Print <PrintIcon />
              </button>
              <button className="btn print">
                Print <ExportIcon />
              </button> */}
                </div>
              </header>

              <div className="px-md-4">
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th />
                      <th>S/N</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Item Desc</th>
                      <th>QTY</th>
                      <th>Unit Cost</th>
                      <th>Unit Price</th>
                      <th>Post time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.warehouses?.map((el, index) => (
                      <tr key={index}>
                        <td>
                          {" "}
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              {isIronRod &&
                                wareshouseLocal?.cater !== "Assets" && (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => handleShowBatches(el)}
                                  >
                                    <CubeOutline className="text-light" />
                                    Batches
                                  </Dropdown.Item>
                                )}
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleDeleteItem(el)}
                              >
                                <DeleteIcon />
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>{index + 1}</td>
                        <td>{el?.Bar_Code}</td>
                        <td>{el?.Item_Name}</td>
                        <td>{el?.Item_Desc}</td>

                        <td
                          className="p-cursor"
                          onClick={() => handleShowBatches(el)}
                          title={el.Quantity}
                        >
                          {isIronRod
                            ? qtyFormatToString(
                                qtyFormat(
                                  el.Quantity,
                                  el.Item_Desc,
                                  itemMeasurements
                                )
                              )
                            : el.Quantity}
                        </td>
                        <td>
                          {currency(el?.UnitCost, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el?.UnitPrice, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {format(new Date(el?.Date_Log), "dd MMM, yyyy")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {!isFetching && isSuccess && isEmpty(data?.warehouses) ? (
                <div>
                  <NoTableItem queryParams={queryParams} />
                  <p className="text-center">{queryParams?.customerName}</p>
                </div>
              ) : (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {showUpdateInventoryModal && (
          <UpdateStockModal
            showUpdateInventoryModal={showUpdateInventoryModal}
            setShowUpdateInventoryModal={setShowUpdateInventoryModal}
            refetch={refetch}
          />
        )}

        {showCreateNewInventoryModal && (
          <AddNewStock
            refetch={refetch}
            showCreateNewInventoryModal={showCreateNewInventoryModal}
            setShowCreateNewInventoryModal={setShowCreateNewInventoryModal}
            setItemType={wareshouseLocal}
          />
        )}

        {showUpdateInventoryAssetModal && (
          <AddNewAssets
            refetch={refetch}
            showCreateNewInventoryModal={showUpdateInventoryAssetModal}
            setShowCreateNewInventoryModal={setShowUpdateInventoryAssetModal}
            setItemType={wareshouseLocal}
          />
        )}

        {showBatchesModal && selectedItem && (
          <BatchesModal
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            showBatchesModal={showBatchesModal}
            setShowBatchesModal={setShowBatchesModal}
            wareshouseLocal={wareshouseLocal}
          />
        )}
      </main>
    </section>
  );
}
