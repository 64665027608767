import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  //Utils,
} from "chart.js";
import { services } from "../../config";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { styleHelper } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function getGradient(ctx, chartArea, color) {
  //background: linear-gradient(180deg, #E2E7F8 0%, rgba(255, 255, 255, 0) 100%);

  let gradient = ctx.createLinearGradient(255, 255, 255, 1);
  gradient.addColorStop(0, "#ffffff");
  gradient.addColorStop(1, color);

  return gradient;
}

export default function LineChart() {
  const { backendUrl } = useAuth();
  const getSalesOverview = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/dashboard/sales-overview?by=month`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        //  credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();

    if (styleHelper.isMobile) {
      data = data.slice(4, 10);
      console.log(data);
    }
    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["GET_SALES_OVERVIEW"],
    () => getSalesOverview(),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);
  return (
    <Line
      options={{
        //  responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              fontColor: "#94A3B8",
              fontSize: 12,
              boxHeight: 8,
              boxWidth: 8,
              pointStyle: "circle",
              usePointStyle: true,
              textAlign: "center",
            },
          },
          title: {
            display: false,
          },
          tooltip: {
            backgroundColor: "#00000080",
            boxHeight: 8,
            boxWidth: 8,
            pointStyle: "circle",
            usePointStyle: true,
            boxPadding: 8,
          },
        },

        scales: {
          y: {
            grid: {
              color: "#F4F7FA",
            },
            ticks: {
              color: "#94A3B8",
              stepSize: 100000000,
              fontSize: 12,
            },
          },
          x: {
            grid: {
              color: "#fff",
              hoverColor: "#0F172A",
            },
            ticks: {
              color: "#94A3B8",
              fontSize: 12,
            },
          },
        },
      }}
      data={{
        labels: [...(isSuccess ? data.map((el) => el.date) : [])],
        datasets: [
          {
            label: "Sales",
            data: [...(isSuccess ? data.map((el) => el.trans) : [])],
            backgroundColor: "#21D589",
            borderColor: "#21D589",
            borderRadius: 100,
            barThickness: 16,
            tension: 0.4,
            fill: false,
            // pointRadius: 0,
          },
          {
            label: "Revenue",
            data: [...(isSuccess ? data.map((el) => el.revenue) : [])],
            backgroundColor: function(context) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return null;
              }
              return getGradient(ctx, chartArea, "#E2E7F8");
            },
            pointBackgroundColor: "#2463AE",
            borderColor: "#2463AE",
            borderRadius: 100,
            barThickness: 16,
            tension: 0.4,
            fill: "origin",
            // pointRadius: 0,
          },
        ],
      }}
    />
  );
}
