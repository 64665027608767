import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";

export default function SaleItemsTable({
  handleSelectedItem,
  hideItemsNotInStock = false,
  model = "Items",
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
    model,
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);
  // const [searchString, setSearchString] = useState("");
  // const debouncedSearchString = useDebounce(searchString, 500);

  const getItems = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/sales?${queryString.stringify(debouncedqueryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { sales: [] } } = useQuery(
    [queryActions.GET_SALE_ITEMS, debouncedqueryParams],
    () => getItems(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  return (
    <div className="items-table-area rounded">
      <div className="search-area m-3">
        <MagnifyIcon />
        <input
          className="form-control search-input"
          name="q"
          value={queryParams.q}
          onChange={(e) =>
            setQueryParams({
              ...queryParams,
              page: 1,
              [e.target.name]: e.target.value,
            })
          }
          placeholder="Search sold items..."
          autoFocus
          autoComplete="off"
          type={"text"}
        />
      </div>

      <div className="content">
        <Table borderless hover striped className="product-table">
          <thead className="sticky text-nowrap">
            <tr>
              <th scope="col">BarCode</th>
              <th scope="col">Item Name</th>
            </tr>
          </thead>
          <tbody>
            {data.sales &&
              data.sales.map((el, index) => (
                <tr
                  className="p-cursor"
                  key={index}
                  onClick={() => handleSelectedItem && handleSelectedItem(el)}
                >
                  <td>{el.Bar_Code}</td>
                  <td>{el.Item_Name}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
