import { useFormik } from "formik";
import CachedIcon from "mdi-react/CachedIcon";
import React from "react";
import { Modal, Form, Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useBackendUrl } from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { DeleteIcon } from "../Icons";

export default function CreateSubCategory({
  showCreateSubCategoryModal,
  setShowCreateSubCategoryModal,
}) {
  const backendUrl = useBackendUrl();
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/incentive/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.categorySelect = data.categories.map((el) => ({
      label: el.Cat_Name,
      value: el.Cat_Name,
    }));
    return data;
  };

  const createSubCategory = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/create-category`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createCategoryMutation = useMutation(
    (payload) => createSubCategory(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const {
    data = {
      subcategories: [],
      categorySelect: [],
    },
    refetch,
  } = useQuery(["INCENTIVE_SETUP", "SUB_CATEGORY"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });
  const formik = useFormik({
    initialValues: {
      Cat_Name: "",
      Sub_Cat: "",
    },
    validationSchema: yup.object().shape({
      Cat_Name: yup.string().required(),
      Sub_Cat: yup.string().required(),
    }),
    onSubmit: (values) => {
      createCategoryMutation.mutate(values);
    },
  });

  const deleteCategory = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/delete-sub-category`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteCategoryMutation = useMutation(
    (payload) => deleteCategory(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const handleDeleteCategory = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete Sub Category",
        description: `Are you sure, delete ${el.Sub_Cat}`,
      })
    ) {
      deleteCategoryMutation.mutate(el);
    }
  };

  return (
    <Modal
      show={showCreateSubCategoryModal}
      onHide={() => setShowCreateSubCategoryModal(false)}
      size="md"
      centered
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">
          Create Sub Category{" "}
          <Button variant="" className="text-primary" onClick={() => refetch()}>
            {" "}
            <CachedIcon />{" "}
          </Button>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Category Name </Form.Label>
            <Select
              classNamePrefix={"form-select"}
              options={data.categorySelect}
              className={
                formik.touched.Cat_Name && !!formik.errors.Cat_Name
                  ? "is-invalid"
                  : ""
              }
              isSearchable={false}
              value={
                (data?.categorySelect || []).find(
                  (el) => el.value === formik.values.Cat_Name
                ) || ""
              }
              onChange={(selected) => {
                formik.setFieldValue("Cat_Name", selected.value);
              }}
              onBlur={() => formik.setFieldTouched("Cat_Name", true)}
            />
            {formik.touched.Cat_Name && !!formik.errors.Cat_Name ? (
              <span className="text-danger mt-2">{formik.errors.Cat_Name}</span>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Sub Cat</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Sub Cat"
              name="Sub_Cat"
              value={formik.values.Sub_Cat}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Sub_Cat && !!formik.errors.Sub_Cat}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Sub_Cat}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex gap-3 justify-content-end">
            <Button type="submit">Create</Button>
            <Button type="button" variant="outline-primary">
              Refresh
            </Button>
          </div>
        </Form>

        <Table
          responsive
          borderless
          striped
          className="product-table mt-4 border"
        >
          <thead>
            <tr>
              <th />
              <th>Sub_Cat</th>
            </tr>
          </thead>
          <tbody>
            {data?.subCategories &&
              data?.subCategories.map((el, index) => (
                <tr key={index}>
                  <td style={{ whiteSpace: "nowrap", width: "1%" }}>
                    <Button variant="" onClick={() => handleDeleteCategory(el)}>
                      <DeleteIcon />
                    </Button>
                  </td>
                  <td>{el.Sub_Cat}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
