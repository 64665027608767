import currency from "currency.js";
import { calculateAccounts, returnAmountTotal } from "./helpers";

export const calculateBalanceSheet = (data) => {
  const types = [...new Set(data.journal.map((d) => d.AccountID))];

  let allAccouts = {};
  types.forEach((el) => {
    allAccouts[el] = data.journal.filter((d) => d.AccountID === el);

    allAccouts[el] = allAccouts[el].map((e) => ({
      Credit: e.Credit,
      Debit: e.Debit,
    }));

    allAccouts[el]["Credit"] = allAccouts[el].reduce((a, c) => a + c.Credit, 0);

    allAccouts[el]["Debit"] = allAccouts[el].reduce((a, c) => a + c.Debit, 0);
  });

  allAccouts = Object.entries(allAccouts);

  allAccouts = allAccouts.map((a) => ({
    key: a[0],
    totalAmount: calculateAccounts(a[0], a[1]["Credit"], a[1]["Debit"]),
  }));

  console.log(allAccouts);
  //   Current Assets
  const CURRENTASSET = currency(
    returnAmountTotal(allAccouts, "CURRENT ASSET"),
    {
      symbol: "",
    }
  ).format();
  const CASHANDCASHEQUIVALENT = currency(
    returnAmountTotal(allAccouts, "CASH AND CASH EQUIVALENT"),
    {
      symbol: "",
    }
  ).format();

  const ACCOUNTSRECEIVABLE = currency(
    returnAmountTotal(allAccouts, "ACCOUNTS RECEIVABLE"),
    {
      symbol: "",
    }
  ).format();
  const TotalCurrentAssets = currency(CURRENTASSET, {
    symbol: "",
  })
    .add(CASHANDCASHEQUIVALENT)
    .add(ACCOUNTSRECEIVABLE)
    .format();

  // FIXED ACCETS
  const FIXEDASSET = currency(returnAmountTotal(allAccouts, "FIXED ASSET"), {
    symbol: "",
  }).format();

  const NONCURRENTASSETS = currency(
    returnAmountTotal(allAccouts, "NON-CURRENT ASSETS"),
    {
      symbol: "",
    }
  ).format();

  const TotalFixedAssets = currency(FIXEDASSET, {
    symbol: "",
  })
    .add(NONCURRENTASSETS)
    .format();

  // OTHER ASSETS
  const ACCUMULATEDDEPRECIATION = currency(
    returnAmountTotal(allAccouts, "ACCUMULATED DEPRECIATION"),
    {
      symbol: "",
    }
  ).format();

  const TotalAssets = currency(TotalCurrentAssets, {
    symbol: "",
  })
    .add(TotalFixedAssets)
    .add(ACCUMULATEDDEPRECIATION)
    .format();

  //   Liabilities and Owner's Equity
  const CURRENTLIABILITIES = currency(
    returnAmountTotal(allAccouts, "CURRENT LIABILITIES"),
    {
      symbol: "",
    }
  ).format();

  const ACCOUNTSPAYABLE = currency(
    returnAmountTotal(allAccouts, "ACCOUNTS PAYABLE"),
    {
      symbol: "",
    }
  ).format();

  const NONCURRENTLIABILITY = currency(
    returnAmountTotal(allAccouts, "NON-CURRENT LIABILITY"),
    {
      symbol: "",
    }
  ).format();

  const LONGTERMLIABILITIES = currency(
    returnAmountTotal(allAccouts, "LONG-TERM LIABILITIES"),
    {
      symbol: "",
    }
  ).format();

  const TotalCurrentLiabilities = currency(CURRENTLIABILITIES, {
    symbol: "",
  })
    .add(ACCOUNTSPAYABLE)
    .add(NONCURRENTLIABILITY)
    .add(LONGTERMLIABILITIES)
    .format();

  // Owner's Equity
  const OWNEREQUITY = currency(
    returnAmountTotal(allAccouts, "OWNER'S EQUITY"),
    {
      symbol: "",
    }
  ).format();

  const TotalLiabilitiesandOwnerEquity = currency(TotalCurrentLiabilities, {
    symbol: "",
  })
    .add(OWNEREQUITY)
    .format();

  return {
    CURRENTASSET,
    CASHANDCASHEQUIVALENT,
    ACCOUNTSRECEIVABLE,
    TotalCurrentAssets,
    FIXEDASSET,
    NONCURRENTASSETS,
    TotalFixedAssets,
    ACCUMULATEDDEPRECIATION,
    TotalAssets,
    CURRENTLIABILITIES,
    ACCOUNTSPAYABLE,
    NONCURRENTLIABILITY,
    LONGTERMLIABILITIES,
    TotalCurrentLiabilities,
    OWNEREQUITY,
    TotalLiabilitiesandOwnerEquity,
  };
};
